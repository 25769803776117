import React from "react";
import { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { GetUserDetails, GetUserDetailsMS } from "../services/AuthService";

export function ProtectedRoute (props) 
{
  const user = GetUserDetails();
  const userAPI = GetUserDetailsMS();

  return(
    <Route  {...props}  render={(propsrender)=>{
      if(user || userAPI) return <Component {...propsrender} />
      else
      return <Redirect to="/login" />
    }} />
  )
  
}

export default function CheckIfAuthencicated() {
  const getUserDetails = () => {
    const user = GetUserDetails();
    if (!user) return <Redirect to="/login" />;
  };
  return <div></div>;
}
