import React,{useState} from 'react';
import { ArrowUpRight } from 'react-bootstrap-icons';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import HttpService from '../../../services/HttpService';
import GenericFormElement from '../../Ulties/GenericFormElement';
import GeneridModal from '../../Ulties/GeneridModal';
import moment from 'moment';
import QRCode from 'qrcode.react'
export default function ProvisionOption({deviceId,name}) {

    const [claimingData, setclaimingData] = useState({
        secretKey:"",
        expirationTime:1640995200000
    });
    const [done, setdone] = useState(false);

    const onReclaiming = ()=>{

        HttpService.delete(`api/customer/device/${name}/claim`)
        .then(response=>
        {
         //   NotificationManager.success("Device successfully ready to provision");
         setdone(true);
        })
        .catch(error =>{

            NotificationManager.errror("Failed to prepare the device for provisioning");
        })
    };

    const onCreateActivation = ()=>{

        if(claimingData.secretKey.toString().length < 6)
        {
            NotificationManager.warning("Please make sure that the activation key is greater then 5 characters");
            return;
        }

        onReclaiming();

        HttpService.post(`/api/plugins/telemetry/DEVICE/${deviceId}/SERVER_SCOPE`,{
            "claimingData":claimingData
        })
        .then(response=>
        {
            NotificationManager.success("Device is ready to be provision");
        
        })
        .catch(error =>{

            NotificationManager.errror("Failed to provision device");
        })
    };

    const loadProvisionDetails = ()=>{


        
        HttpService.get(`api/plugins/telemetry/DEVICE/${deviceId}/values/attributes/SERVER_SCOPE?keys=claimingData`)
        .then(response=>
        {
           const {data} = response;
           if(data.length > 0)
           setclaimingData({...claimingData,...data[0].value})
        
        })
        .catch(error =>{

            //NotificationManager.errror("Failed to provision device");
        })
    }

  return (
    <>
      <GeneridModal modalClosed={()=> setdone(false)} title="Provision Device" size="sm" icon={<ArrowUpRight />} buttonTitle={"Save"} HandleModalAction= {()=> onCreateActivation()} modalOpened={()=> loadProvisionDetails()} >
          <div>
              <GenericFormElement value={claimingData.secretKey} name={"secretKey"} title={"Activation Key"} handleChange={(e)=> setclaimingData({...claimingData, [e.target.name]:e.target.value})} />
           { done && <div className="text-center">
            <QRCode
    
    value={`${name},${claimingData.secretKey}`}
   size={200}
    level={"H"}
    includeMargin={true}
  />
            </div>}
          </div>
     </GeneridModal>
    </>
  );
}
