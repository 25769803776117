import { Button, Form, Modal } from "react-bootstrap";
import React, { useState } from "react";
import { Plus } from "react-bootstrap-icons";
import { addUser, getActivationLink } from "../../services/CustomerService";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";

export default function CreateUser({ customerId, userCreated }) {
  const [show, setShow] = useState(false);

  const [ErrorMessage, setErrorMessage] = useState(null);

  const [userActivationLink, setuserActivationLink] = useState(null);

  const [userDetails, setuserDetails] = useState({
    email: "",
    name: "",
    firstName: "",
    type:"Choose..."
  });

  const createNewUser = async () => {
    try {

    if(userDetails.type === "Choose...")
    {
      
      NotificationManager.error(
       'Please choose User type.'
      );
      return 
    }
      var userCreated = await addUser(userDetails.type,
        customerId,
        userDetails.email,
        userDetails.name,
        userDetails.firstName
        
      );     

      NotificationManager.success(
        `User ${userDetails.email} created successfully!`
      );

      var activationLink = await getActivationLink(userCreated.data.id.id);

      var token = activationLink.data.split("=");

      if (token) setuserActivationLink(<Link to={`/app/activateAccount/${token[1]}`}>Activation Link</Link>);
     
      setErrorMessage(null);

      userCreated();
      // handleClose();
    } catch (error) {
     
      if (error) {
        if (error.data) setErrorMessage(error.data.message);
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setErrorMessage("");
    setuserActivationLink("");
  };
  const handleShow = () => setShow(true);

  const setValue = (e) =>
    setuserDetails({ ...userDetails, [e.target.name]: e.target.value });

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        <Plus />
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <h5 style={{ color: "green" }}>
              {userActivationLink && userActivationLink}
            </h5>
          </p>
          <p>
            <h5 style={{ color: "red" }}>{ErrorMessage && ErrorMessage}</h5>
          </p>

          <Form.Group controlId="form-email-address">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              onChange={setValue}
              placeholder="Enter email"
            />
          </Form.Group>
          <Form.Group controlId="form-user-name">
            <Form.Label>Name</Form.Label>
            <Form.Control name="name" onChange={setValue} placeholder=" Name" />
          </Form.Group>
          <Form.Group controlId="form-user-name">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              onChange={setValue}
              name="firstName"
              placeholder="First Name"
            />
          </Form.Group>
          
          <Form.Group controlId="form-user-name">
            <Form.Label>Type</Form.Label>
            <Form.Control  name="type" onChange={setValue} as="select">
              <option>Choose...</option>
              <option>Admin</option>
              <option>Viewer</option>
              <option>Technician</option>              
            </Form.Control>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={createNewUser}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
