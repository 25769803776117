import React from 'react';
import { Form,Button, InputGroup, FormControl } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import DatePicker from 'react-datepicker';
import { DateTime } from 'react-datetime-bootstrap';
import "bootstrap/dist/css/bootstrap.css";

export default function GenericFormElement(props) {
  return (
    <div>
       <Form>
          <Form.Group controlId={`inputds${props.name}`}>
            <Form.Label>{props.title}</Form.Label>
            <Form.Control as={props.as} value={props.value} type={props.type} placeholder={props.title} disabled={props.disabled}  name={props.name} onChange={props.handleChange} />
          </Form.Group>
        </Form>
    </div>
  );
}



export function GenericDateTimePickser  (props)
{
return (<div>
  <Form>
     <Form.Group controlId={`inputds${props.name}`}>
       <Form.Label>{props.title}</Form.Label>

      
       <DateTimePicker
    onChange={props.handleChange}
    value={props.value}
    className="form-control"
  />
     </Form.Group>
   </Form>
  </div>)
}

export  function ListFormElement({title,name,handleChange,values,selectedValue}) {
  return (
    <div>
       <Form>
          <Form.Group controlId={`inputds${name}`}>
            <Form.Label>{title}</Form.Label>
            <Form.Control name={name} value={selectedValue} as="select" onChange={handleChange} >
             <option>Choose...</option>
              {values && values.map(a=> <option  label={a.name}>{a.id}</option>)}           
              </Form.Control>   </Form.Group>
        </Form>
    </div>
  );
}


export function FromWithUpdateButton(props)
{
  return(
    <>
    <InputGroup  className="mb-3">
    <InputGroup.Prepend>
    <InputGroup.Text style={{width:200}}>{props.title || props.name }</InputGroup.Text>
  </InputGroup.Prepend>
    <FormControl
     value={props.value} type={props.type} placeholder={props.title} readOnly={props.readonly} name={props.name} onChange={props.handleChange}       
    />
    {!props.readonly && <InputGroup.Append>
      <Button  onClick={props.handleButtonClick}>{props.buttonTitle || "Update"}</Button>
    </InputGroup.Append>}
    
  </InputGroup>
    </>
    
  )
}
export function FromWithUpdateButtonV2(props)
{
  return(
    <>
    <InputGroup  className="mb-3">
    <InputGroup.Prepend>
    <InputGroup.Text style={{width:200}}>{props.title || props.name }</InputGroup.Text>
  </InputGroup.Prepend>
    <FormControl
     value={props.value} type={props.type} placeholder={props.title} readOnly={props.readonly} name={props.name} onChange={props.handleChange}       
    />
    
  </InputGroup>
    </>
    
  )
}