import React,{useState} from 'react';


import GeneridModal from '../../Ulties/GeneridModal';
import { Camera, DisplayFill, Image} from 'react-bootstrap-icons';




export default function PictureDisplay({data}) { 


  return (
    <>
      <GeneridModal modalClosed={()=> {}} title="Picture Display" size="lg" icon={<Image />} HandleModalAction={()=>{}}   buttonTitle={""}  modalOpened={()=> {}} >
      <div className="container">
      <img style={{width:'100%'}} src={`data:image/jpeg;base64,${data}`} />
      </div>
         
     </GeneridModal>

    
    </>
  );
}
