import React, { useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import DateTimeSelector from '../DateTimeSelector';
import GenericGauge, {GenericDisplay} from '../GenericGauge';
import moment from 'moment';
import { GetTelemetries, UpdateSettings } from '../../../services/DeviceService';
import ChartHistory from './ChartHistory';
import SettingsComponents, { AdvanceSettingComponents, PH1Configuration } from '../../SettingsComponents';
import MqttSettingsGeneratorComponent from './MqttSettingsGeneratorComponent';
import { AlarmsList } from '../AlarmsComponents';
import { PH1BATTERYMON } from '../SimplifiedView/PH1BATTERYMON';
import { NotificationManager } from 'react-notifications';
import DisplayOnUserType from '../../Ulties/DisplayOnUserType';

const TelemetriesUsed =
"DPM_V_1,CDPM_I_1,DPM_P_1,DPM_NRG_1,STAT_AC,GSM_SIG,ALRT_LEV,INT_BAT_V";

const settingslabel = {
  latitude: "",
  longitude: ""
};




const iPoPPh1SettingsLabel = {
  STATUSCODE  : {value:"",label:""},
  GREENPOST    : {value:"",label:"Green Posting"},
  YELLOWPOST    :  {value:"",label:"Yellow Posting"},
  REDPOST        : {value:"",label:"Red Posting"},
  MQTTURL        : {value:"",label:"Mqtt Url",type:"text"},
  MQTTCLIENTID   : {value:"",label:"Mqtt Client",type:"text"},
  MQTTUSERNAME    : {value:"",label:"Mqtt Username",type:"text"},
  MQTTPASSWORD     : {value:"",label:"Mqtt Password",type:"text"},
  MQTTSUBTOPIC      : {value:"",label:"Mqtt SUB TOPIC",type:"text"},
  MQTTPOSTTOPIC     : {value:"",label:"Mqtt POST TOPIC",type:"text"},
  TEMPLOWLIM         : {value:"",label:"Temperature Low Limit"},
  TEMPHIGHLIM         : {value:"",label:"Temperature High Limit"},
  TEMPLOWWARN         : {value:"",label:"Temperature Low Warn"},
  TEMPHIGHWARN         : {value:"",label:"Temperature High Warn"},
  TEMPHYST             : {value:"",label:"Temperature Hyst"},
  HUMLOWLIM         : {value:"",label:"Humidity Low Limit"},
  HUMHIGHLIM         : {value:"",label:"Humidity High Limit"},
  HUMLOWWARN         : {value:"",label:"Humidity Low Warn"},
  HUMHIGHWARN         : {value:"",label:"Humidity High Warn"},
  HUMHYST             : {value:"",label:"Humidity Hyst"},
  BATLOWLIM         : {value:"",label:"Battery Low Limit"},
  BATLOWWARN         : {value:"",label:"Battery Low Warn"},
  BATHYST             : {value:"",label:"Battery Hyst"},
  USESSL             : {value:"",label:"Use SSL for mqtt (1) Yes (0) No"},
  USEDOORCONTACT     : {value:"",label:"Door Contact (1) Yes (0) No"}, 
  DPM_V_RED_H_1   : {value:"",label:"DC Voltage Red High"}, 
  DPM_V_RED_L_1   : {value:"",label:"DC Voltage Red Low"}, 
  DPM_V_YEL_H_1   : {value:"",label:"DC Voltage Yellow High"}, 
  DPM_V_YEL_L_1   : {value:"",label:"DC Voltage Yellow Low"}, 
  DPM_I_RED_H_1   : {value:"",label:"DC Current Red High"}, 
  DPM_I_RED_L_1   : {value:"",label:"DC Current Red Low"}, 
  DPM_I_YEL_H_1   : {value:"",label:"DC Current Yellow High"}, 
  DPM_I_YEL_L_1    : {value:"",label:"DC Current Yellow Low"}, 
};


export default function PowerMonitorBatteryMonitor({telemetries,settings, deviceId,handleSettingsUpdate,handlonClickIcon}) {
  
    const [key,setKey] = useState("home");
    const [histories,sethistories] = useState(null);
    const getTelemetries =async  (from , to)=>{
        const {data} = await GetTelemetries(deviceId,TelemetriesUsed,moment(from).format("x") , moment(to).format("x"));
        
        Object.keys(data).map((a) => (data[a] = data[a].reverse()));
        sethistories(data);
        return data;
    }

    const upateDeviceSettings = async (settings) =>{    
      try{
        const {data} = await UpdateSettings(deviceId,settings);
       
        NotificationManager.success('Device Settings Updated Successfully');
       }
       catch(error)
       {
       // NotificationManager.success('You are not allowed to updated device settings');
       }
    };
   
    const clickicon = (e) => {      
       
        
        }
  return (
    <div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="home" title="i-PoP Status">
          <div style={{ paddingTop: 10 }}>
            <PH1BATTERYMON sensors={telemetries} deviceId={deviceId} />
          </div>
        </Tab>
        <Tab eventKey="profile" title="i-PoP History">
          <div>
            <DateTimeSelector handlerDateSelection={getTelemetries} />
            {histories && (
              <Row>
                <Col style={{ paddingTop: 20 }}>
                  <ChartHistory
                    name="VOLTAGE DC POWER METER"
                    syncidentification={"sync1"}
                    data={histories.DPM_V_1}
                  />
                </Col>
                <Col style={{ paddingTop: 20 }}>
                  <ChartHistory
                    name="CURRENT DC POWER METER"
                    syncidentification={"sync1"}
                    data={histories.CDPM_I_1}
                  />
                </Col>
                <Col style={{ paddingTop: 20 }}>
                  <ChartHistory
                    name="POWER DC POWER METER"
                    syncidentification={"sync1"}
                    data={histories["DPM_P_1"]}
                  />
                </Col>
                <Col style={{ paddingTop: 20 }}>
                  <ChartHistory
                    name="GSM SIGNAL STRENGHT"
                    syncidentification={"sync1"}
                    data={histories.GSM_SIG}
                  />
                </Col>
                <Col style={{ paddingTop: 20 }}>
                  <ChartHistory
                    name="ENERGY DC POWER METER"
                    syncidentification={"sync1"}
                    data={histories["DPM_NRG_1"]}
                  />
                </Col>
                <Col style={{ paddingTop: 20 }}>
                  <ChartHistory
                    name="INTERNAL BATTERY"
                    syncidentification={"sync1"}
                    data={histories["INT_BAT_V"]}
                  />
                </Col>
              </Row>
            )}
          </div>
        </Tab>
        <Tab eventKey="Alarms" title="i-PoP Alarms">
          <AlarmsList DeviceUniqueId={deviceId} />
        </Tab>
        <Tab eventKey="contact" title="i-PoP Configuration">
          <AdvanceSettingComponents
            deviceid={deviceId}
            handleSettingUpdates={upateDeviceSettings}
            attributes={settingslabel}
          />
        </Tab>
        <Tab eventKey="internal-conf" title="i-PoP Internal Configuration">
          <DisplayOnUserType typeToCheck="TENANT_ADMIN">
            <PH1Configuration
              deviceid={deviceId}
              handleSettingUpdates={upateDeviceSettings}
              attributes={iPoPPh1SettingsLabel}
            />
          </DisplayOnUserType>
        </Tab>
      </Tabs>
    </div>
  );
}
