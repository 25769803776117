import React, { PureComponent, useState,useEffect } from "react";
import { Badge, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import DateTimeSelector from "../DateTimeSelector";
import GenericGauge, { GenericDisplay } from "../GenericGauge";
import moment from "moment";
import { GetTelemetries, UpdateSettings } from "../../../services/DeviceService";
import ChartHistory from "./ChartHistory";
import SettingsComponents, { AdvanceSettingComponents } from "../../SettingsComponents";
import MqttSettingsGeneratorComponent from "./MqttSettingsGeneratorComponent";
import MapGL, { Marker } from "react-map-gl";
import { FullscreenControl } from "mapbox-gl";
import { List } from "react-bootstrap-icons";
import NotificationManager from "react-notifications/lib/NotificationManager";
import Cabinet from "../SimplifiedView/Cabinet";
import { AlarmsList } from "../AlarmsComponents";
import DisplayOnUserType from "../../Ulties/DisplayOnUserType";

const TelemetriesUsed = "battery,speed,currentLocation";

const settingslabel = {
  latitude: "",
  longitude: "",
  "Temperature 1 High Limit": "",
  "Temperature 1 Low Limit": "",
  "Temperature 2 High Limit": "",
  "Temperature 2 Low Limit": "",
  "Temperature 3 High Limit": "",
  "Temperature 3 Low Limit": "",
  "Temperature 4 High Limit": "",
  "Temperature 4 Low Limit": "",
  "Humidity 1 High Limit": "",
  "Humidity 1 Low Limit": "",
  "UPS Voltage High Limit": "",
  "UPS Voltage Low Limit": "",
  "UPS Battery High Limit": "",
  "UPS Battery Low Limit": "",
  "UPS Load Level High Limit": "",
  "UPS Load Level Low Limit": "",
  "Mains Voltage High Limit": "",
  "Mains Voltage Low Limit": "",
  "Mains Current High Limit": "",
  "Mains Current Low Limit": "",
  "Active Power High Limit": "",
  "Active Power Low Limit": "",
};


const historiesListWithLabels =   [
  {name:"Temperature", label:"Top Temperature"},
  {name:"Temperature2", label:"Bottom Temperature"},
  {name:"Humidity", label:"Relative Humidity"},
  {name:"UPS_BATTERY_LEVEL", label:"UPS Battery Level"},
  {name:"UPS_LAOD_LEVEL", label:"UPS Battery Load Level"},
  {name:"MAINS_VOLTAGE", label:"Mains Voltage"},
  {name:"MAINS_CURRENT", label:"Mains Current"},
  {name:"mains_Actice_Power", label:"Main Active Power"}
  ]
  
const fullscreenControlStyle = {
  position: "absolute",
  top: 36,
  left: 0,
  padding: "10px",
};

const ToolTip = ({theinfo})=>{
  return <>
  {Object.keys(theinfo).map(a=> (<>{a}:{theinfo[a]}</>))}
  </>
}

class ControlPanel extends PureComponent {
  render() {
    return (
      <div
        style={{
          backgroundColor: "white",
          position: "absolute",
          top: 36,
          left: 0,
          padding: "10px",
        }}
      >
        <h3>Marker, Popup, NavigationControl and FullscreenControl </h3>
        <p>
          Map showing top 20 most populated cities of the United States. Click
          on a marker to learn more.
        </p>
        <p>
          Data source:{" "}
          <a href="https://en.wikipedia.org/wiki/List_of_United_States_cities_by_population">
            Wikipedia
          </a>
        </p>
        <div className="source-link">
          <a
            href="https://github.com/visgl/react-map-gl/tree/5.2-release/examples/controls"
            target="_new"
          >
            View Code ↗
          </a>
        </div>
      </div>
    );
  }
}

const TrackerMap = (props) => {


  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: props.latitude,
    longitude: props.longitude,
    zoom: 15,
  });

  const [showTelemetries, setshowTelemetries] = useState(false)

  const [data, setdata] = useState({});

  useEffect(() => {
  
    var mydata = props.data;


    var buildTelemetries =[];

    var dataused =   Object.keys(mydata).forEach(a=> {    
      buildTelemetries[a] =  mydata[a][0].value;     
      if(a ==="latitude" || a ==="longitude")
      buildTelemetries[a] =  Number(mydata[a][0].value);   
    })

   
   
    setdata({...buildTelemetries});

    setViewport({
      width: "100%",
      height: "100%",
      latitude: buildTelemetries.latitude,
      longitude: buildTelemetries.longitude,
      zoom: viewport.zoom,
    })
    return () => {
  
    };
  }, [props])
  //setCurrentLocation();
  return (
    
    <Card
      style={{
        height: "750px",
        width: "100%",
        margin: 20,
        borderWidth: 2,
        borderColor: "#394867",
      }}
    >
      
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/dark-v9"
        onViewportChange={(viewport) => {
          console.log('Testing on view box ...')
          setViewport(viewport);
        }}
        mapboxApiAccessToken={
          "pk.eyJ1IjoiamFkZW1vdGUiLCJhIjoiY2tpdTE1aDBuMGlyZjMzbXVvYXcya242bCJ9.HQNJONu_BW3EAFYZF7tdHw"
        }
      >
         <List style={{marginLeft:10}} size={30} color="green" onClick={()=> setshowTelemetries(!showTelemetries)}/>
         { showTelemetries &&    <Row style={{ padding: 10 }} onClick={()=>{
          setViewport({
            width: "100%",
            height: "100%",
            latitude: -26.22,
            longitude: 27,
            zoom: 3,
          })
        }}> 
       
      
    
     
        </Row>

     }
         <Marker  latitude={data.latitude} longitude={data.longitude}>
         <img style={{ height: 10,
              width: 10
}} src="/images/bus_green.png" />
           <div>
           <Badge style={{margin:5}} variant="info">BAT :{data.battery } %</Badge>            
             <Badge style={{margin:5}} variant="info">SPEED :{data.speed} KMH</Badge>
             <Badge style={{margin:5}} variant="info">DIR :{data.direction} °</Badge>
           </div>

        </Marker>

 
      </MapGL>
    </Card>
  );
};

const TrackerMapHistoty = (props) => {


  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: props.latitude,
    longitude: props.longitude,
    zoom: 15,
  });

  const [showTelemetries, setshowTelemetries] = useState(false)

  const [data, setdata] = useState({});

  useEffect(() => {
  
    var mydata = props.data;


    var buildTelemetries =[];

    var dataused =   Object.keys(mydata).forEach(a=> {    
      buildTelemetries[a] =  mydata[a][0].value;     
      if(a ==="latitude" || a ==="longitude")
      buildTelemetries[a] =  Number(mydata[a][0].value);   
    })

   
   
    setdata({...buildTelemetries});

    setViewport({
      width: "100%",
      height: "100%",
      latitude: buildTelemetries.latitude,
      longitude: buildTelemetries.longitude,
      zoom: viewport.zoom,
    })
    return () => {
  
    };
  }, [props])
  //setCurrentLocation();
  return (
    
    <Card
      style={{
        height: "750px",
        width: "100%",
        margin: 20,
        borderWidth: 2,
        borderColor: "#394867",
      }}
    >
      
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/dark-v9"
        onViewportChange={(viewport) => {
          console.log('Testing on view box ...')
          setViewport(viewport);
        }}
        mapboxApiAccessToken={
          "pk.eyJ1IjoiamFkZW1vdGUiLCJhIjoiY2tpdTE1aDBuMGlyZjMzbXVvYXcya242bCJ9.HQNJONu_BW3EAFYZF7tdHw"
        }
      >

        {props.locations.currentLocation && props.locations.currentLocation.map(a=> (
            <Marker  latitude={a.value.latitude} longitude={a.value.longitude}>
            <div title={moment(moment(a.ts)).toString('YYYY-MM-DD hh:mm:ss')} style={{ height: 5,
              width: 5 , background:"red"}}>
                
              </div>
            
        </Marker>
        ))}
     
   

      </MapGL>
    </Card>
  );
};

export default function  SmartCabinet({
  telemetrie,
  settings,
  deviceid,
  Alarms,
  selectedtab,
  displayDashboard
}) {
  const mysettings = settings;
  const [key, setKey] = useState(selectedtab);
  const [histories, sethistories] = useState(null);

  const [locationHistories, setlocationHistories] = useState([])

  const upateDeviceSettings = async (settings) => {
    try {
      const { data } = await UpdateSettings(deviceid, settings);

      NotificationManager.success("Device Settings Updated Successfully");
    } catch (error) {
      //NotificationManager.error('You are not allowed to updated device settings');
    }
  };
  const getTelemetries = async (from, to) => {
    const { data } = await GetTelemetries(
      deviceid,
      TelemetriesUsed,
      moment(from).format("x"),
      moment(to).format("x")
    );
    Object.keys(data).map((a) => {
      data[a].map(b=> {
        var temp = b;
        temp.value = Number(temp.value);

        return temp;
      }) ;
      
      data[a] = data[a].reverse();
    });
    sethistories(data);
    return data;
  };

  const getTelemetriesLocations = async (from, to) => {
    const { data } = await GetTelemetries(
      deviceid,
      TelemetriesUsed,
      moment(from).format("x"),
      moment(to).format("x")
    );
    Object.keys(data).map((a) => {
      data[a].map(b=> {
        var temp = b;
        if(a==="currentLocation")
        temp.value = JSON.parse(temp.value);
        else
        temp.value = Number(temp.value);

        return temp;
      }) ;
      
      data[a] = data[a].reverse();
    });
    setlocationHistories(data);
    return data;
  };


  return (
    <div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="home" title="i-PoP Status">
        <Cabinet sensors={telemetrie} deviceId={deviceid} displayDashboard={displayDashboard}  />
          {/* <CabinetDeviceTelemetries telemetries= {telemetrie} settings={mysettings} deviceId={deviceid} /> */}

         {telemetrie && <TrackerMap data={telemetrie} />} 
        </Tab>

        <Tab eventKey="location-history" title="Location History">
        <DateTimeSelector handlerDateSelection={getTelemetriesLocations} />
        
         <TrackerMapHistoty data={telemetrie}  locations = {locationHistories} />
        </Tab>
       
        <Tab eventKey="profile" title="i-PoP History">
          <div>
            <DateTimeSelector handlerDateSelection={getTelemetries} />

            {histories && (
              <Row>
                 {historiesListWithLabels.map(a=>(<Col style={{margin:10}}><ChartHistory name={a.label} syncidentification={"sync1"} data={histories[a.name] }  /></Col>  ))}
               
   
              </Row>
            )}
          </div>
        </Tab>
        <Tab eventKey="Alarms" title="i-PoP Alarms">
          <AlarmsList DeviceUniqueId={deviceid} />
        </Tab>
        <Tab eventKey="contact" title="i-PoP Configuration">
        <DisplayOnUserType typeToCheck="TENANT_ADMIN" checkAlso="Technician">
        <AdvanceSettingComponents
            deviceid={deviceid}
            handleSettingUpdates={upateDeviceSettings}
            attributes={settingslabel}
          />
          </DisplayOnUserType>
       
        </Tab>
      </Tabs>
    </div>
  );
}
