import React, { useState } from "react";
import { Card, Container, Form, Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useHistory, useParams } from "react-router-dom";
import { activateUser } from "../../services/CustomerService";
import { setAuthorizationHeader } from "../../services/HttpService";

export default function ActivateAccount() {
  const history = useHistory();

  var { activateToken } = useParams();

  const generateLink = ()=>{
      return `http://localhost:3000/`
  }

  const activateUserAccount = (e) => {
    e.preventDefault();

    if (passwords.password.length < 6) {
      seterrorMessages("Passwords lenght is less than 6 characters");
      return;
    }

    if (passwords.password != passwords.confirmPassowrd) {
      seterrorMessages("Passwords do not match!");
      return;
    }

    activateUser(activateToken, passwords.password)
      .then((response) => {
        const { data } = response;
        NotificationManager.success(`Account activated`);

        setAuthorizationHeader(data.token);

        NotificationManager.success("Login Successfull!");

        history.push("/devicesmap");
      })
      .catch((errors) => {
        console.log(errors);
        if (errors) seterrorMessages(errors.data.message);
      });
  };

  const [passwords, setpasswords] = useState({
    password: "",
    confirmPassowrd: "",
  });

  const [errorMessages, seterrorMessages] = useState(null);

  const setValue = (e) =>
    setpasswords({ ...passwords, [e.target.name]: e.target.value });

  return (
    <div style={{ paddingTop: 100 }}>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingTop: 50,
            borderWidth: 2,
            borderColor: "#394867",
          }}
        >
          <Card style={{ borderWidth: 2, borderColor: "#394867", padding: 60 }}>
            <h4>Active user account : @ {activateToken}</h4>
            <h5 style={{ color: "red" }}>{errorMessages && errorMessages}</h5>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  onChange={setValue}
                  name="password"
                  placeholder="Password"
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  onChange={setValue}
                  name="confirmPassowrd"
                  placeholder="Confirm Password"
                />
              </Form.Group>

              <Button
                variant="primary"
                onClick={activateUserAccount}
                type="submit"
              >
                Create Password
              </Button>
            </Form>
          </Card>
        </div>
      </Container>
    </div>
  );
}
