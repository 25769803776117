import React,{useState} from 'react';
import { Badge, Button, Card, Col } from 'react-bootstrap';
import moment from 'moment';
import DataTable,{} from 'react-data-table-component';
import DateTimeSelector from './DateTimeSelector';
import { GetActiveAlarmsFromRange } from '../../services/DeviceService';
import 'react-data-table-component-extensions/dist/index.css';
import DataTableExtensions from 'react-data-table-component-extensions';
import { CheckCircle, Download } from 'react-bootstrap-icons';
import HttpService from '../../services/HttpService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import DisplayOnUserType from '../Ulties/DisplayOnUserType';
import { generateColorOnSeveriry } from '../../services/Utilities';
import PictureDisplay from './Refactored/PictureDisplay';

export default function AlarmsComponents(props) {

  const [disableAlarm, setdisableAlarm] = useState(false);


  const color = generateColorOnSeveriry(props.severity);

  const clearAlarm = (id)=>{
    HttpService.post(`/api/alarm/${id}/clear`)
    .then(response=>{
      NotificationManager.success('Alarm cleared');

      if(props.reloadAlarm)
      {
        props.reloadAlarm();
        setdisableAlarm(true);
      }
    })
    .catch(error=> {
      if(error)
        NotificationManager.warning('Failed to clear alarm')
    })
  }

  return (
    <div style={{padding:10, margin:2}}>
      <Col  >
        <Card style={{ borderWidth: 2,borderColor:color, background:color }}>
          <Card.Body >
           
           {/* <div style={{textAlign:"center"}}>
           {props.details?.type ==="picture" && <img style={{height:150, width:150}} src={`data:image/jpeg;base64,${props.details.data}`} />}
           </div> */}
           
           
          <Card.Title className="mb-2 " style={{textAlign:"center"}}>{props.name.toUpperCase()}</Card.Title>
          <Card.Title className="mb-2 " style={{textAlign:"center", fontSize:18}} title={moment(props.createdTime).format('yyyy/MM/DD HH:mm:ss')} > <div style={{fontStyle:"italic"}}>{moment(props.createdTime).fromNow()}</div> </Card.Title>
          <DisplayOnUserType typeToCheck="TENANT_ADMIN">
          <div style={{textAlign:"center"}}>
          {props.details?.type ==="picture" &&  <PictureDisplay data={props.details.data}/>}
         
            {props.details?.type ==="picture" &&  <a target="_blank" href={`data:image/jpeg;base64,${props.details.data}`} className="btn btn-warning btn-sm " style={{marginRight:5}}> <Download /> </a>}
           
         <Button disabled={disableAlarm} onClick={()=> {clearAlarm(props.id.id)}} title={"Clear alarm"} size="sm" variant="success"> <CheckCircle /> </Button>
         </div>
        </DisplayOnUserType>
        
        
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}

const AlarmsColums = [{
  name: 'Created',
  selector: 'createdTime',
  sortable: true,
  cell: row=> <div>{moment(row.createdTime).format("DD/MM/yyyy HH:mm")} </div>

},{
  name: 'Name',
  selector: 'name',
  sortable: true,
  cell: row=> <div title={row.details}>{row.name.toUpperCase()}</div>
},
{
  name: 'Status',
  selector: 'type',
  sortable: true,
  cell: row=> <div > {row.clearTs === 0 ? (
    <Badge variant="danger">Active</Badge>
  ) : (
    <Badge variant="success">Closed</Badge>
  )}</div>
},
{
  name: 'Time Cleared',
  selector: 'clearTs',
  sortable: true  ,
  cell: row=> <div>{row.clearTs === 0    ? "N/A": moment(row.clearTs).format("DD/MM/yyyy HH:mm")}</div>
}
]

export function AlarmsList({DeviceUniqueId})
{
const [alarms, setalarms] = useState(null)
  const getAlarmsFromDateRande = async (from , to ) => {
    const { data } = await GetActiveAlarmsFromRange(
      DeviceUniqueId,
      moment(from).format("x"),
      moment(to).format("x")
    );
    console.log("Alarms listed " ,data.data);
    setalarms(data.data);
    return data;
  }
  return (
    <div>
      <DateTimeSelector handlerDateSelection={getAlarmsFromDateRande} />   

      {
     alarms && <DataTableExtensions
     columns={AlarmsColums}
    data={alarms}
    title="Alarms"
    >
      <DataTable
        title="Alarms"
        noHeader
        defaultSortField="Last Activity"
        defaultSortAsc={false}
        pagination
        highlightOnHover
      />
    </DataTableExtensions>
    }

      

{!alarms && <h4>NO ALARMS FOUND</h4>  }
      
    </div>
  )
}

export function AlarmsListComponents({alarms, reloadAlarms}) {

    return(
        <div style={{ borderWidth: 2 , borderColor:"#394867"}}>
            <h4 className="mb-2 " style={{textAlign:"center", color:"#394867"}}>Alarms</h4>
            {!alarms && <h3 style={{textAlign:"center"}}>NO ALARMS</h3> }
            {alarms && alarms.map(a=> <div key={a.id.id}><AlarmsComponents reloadAlarm={reloadAlarms}  {...a} /> </div>)}
        </div>
    )
}