import React, { useState, useEffect } from "react";
import httpService, {
  setAuthorizationHeader,
} from "../../../services/HttpService";
import moment from "moment";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Badge, Card, Col, Container, Row } from "react-bootstrap";


import Header from "../../Header";
import { Link, NavLink } from "react-router-dom";
import { Gear } from "react-bootstrap-icons";
import { NotificationManager } from "react-notifications";


var backgoundcolourStyle = "#14274e";


export default function Registration() {

  const userRegistrationCollums = [
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => (
        <h6>
          {row.status==='Activated' &&   <Badge variant="success">{row.status}</Badge>   }
          {row.status!='Activated' &&   <Badge variant="warning">{row.status}</Badge>   }
        </h6>
      ),
    },
    {
      name: "Email",
      selector: "customerEmailName",
      sortable: true,
    },
    {
      name: "Name",
      selector: "customerEmailName",
      sortable: true,
    },
    {
      name: "Organization",
      selector: "customerorganization",
      sortable: true,
    },
    {
      name: "",
      selector: "",

      cell: (row) => (
       
          <Gear />
       
      ),
    },
  ];
const [registrationUsers, setregistrationUsers] = useState([])
  
useEffect(() => {
 
  httpService.get("/registration")
  .then(response=>{
    const {data} = response;
    if(data.message)
    setregistrationUsers([...data.message]);
  }).catch(error=>{
    NotificationManager.success('Failead to load new users');
  })
 
},[] )

  return (
    <>    
    <Header  />
      <Row>


      <div style={{width:'100%'}}>
        
          <Container >
          <h3>  User Registration</h3>
            {registrationUsers && (
              <DataTableExtensions columns={userRegistrationCollums} data={registrationUsers}>
                <DataTable
                  noHeader
                  defaultSortField="Status"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            )}
          </Container>
        </div>
      </Row>

    
    </>
  );
}
