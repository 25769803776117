
import React,{useState} from 'react';
import { Component } from 'react';
import { Badge, Button, Modal, NavDropdown} from "react-bootstrap";
import { NavLink } from 'react-router-dom';

export function ModalQuestion (props) {

}

export  function HeaderModal(props) {
  const [show, setShow] = useState(false);

const handleClose = () => {
  if(props.modalClosed)
   props.modalClosed();
   setShow(false);
};
const handleShow = () => {
  setShow(true);
  if(props.modalOpened)
   props.modalOpened()
  }
return (
  <>
 <NavDropdown.Item onClick={handleShow} >{props.badgeTitle}</NavDropdown.Item>
 
  <Modal
    size={props.size || "lg"}
    show={show}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
    centered={false || props.center}
  >
    <Modal.Header style={{backgroundColor:"#14274e", color:"white"}} closeButton>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {props.children}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      {props.buttonTitle && <Button variant="primary" onClick={props.HandleModalAction}>{props.buttonTitle || "Ok"}</Button>}
      
    </Modal.Footer>
  </Modal>
</>
);
}

export  function SimpleModal(props) {
  const [show, setShow] = useState(false);

const handleClose = () => {
  if(props.modalClosed)
   props.modalClosed();
   setShow(false);
};
const handleShow = () => {
  setShow(true);
  if(props.modalOpened)
   props.modalOpened()
  }
return (
  <>
 
 <NavLink to="#" className="" onClick={handleShow}>{props.badgeTitle}</NavLink>
  <Modal
    size={props.size || "lg"}
    show={show}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
    centered={false || props.center}
  >
    <Modal.Header style={{backgroundColor:"#14274e", color:"white"}} closeButton>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {props.children}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      {props.buttonTitle && <Button variant="primary" onClick={props.HandleModalAction}>{props.buttonTitle || "Ok"}</Button>}
      
    </Modal.Footer>
  </Modal>
</>
);
}



export default function GeneridModal(props) {
    const [show, setShow] = useState(false);

  const handleClose = () => {
    if(props.modalClosed)
     props.modalClosed();
     setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    if(props.modalOpened)
     props.modalOpened()
    }
  return (
    <>
    <Button title={props.title} size="sm" variant="primary" style={{marginRight:10}}  onClick={handleShow}>
  {props.icon}
    </Button>

    <Modal
      size={props.size || "lg"}
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={false || props.center}
    >
      <Modal.Header style={{backgroundColor:"#14274e", color:"white"}} closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {props.children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {props.buttonTitle && <Button variant="primary" onClick={props.HandleModalAction}>{props.buttonTitle || "Ok"}</Button>}
        
      </Modal.Footer>
    </Modal>
  </>
  );
}
