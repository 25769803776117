import httpsevice from './HttpService';
import {
    GetUserDetails
} from './AuthService';

export function getCustomerInfo() {
    var customeringfo = GetUserDetails();
    var customerid = customeringfo.customerId;
    return httpsevice.get(`api/customer/${customerid}`);

};

export function updateCustomerInfo(customerid, customer) {

    return httpsevice.post(`api/customer/`, customer);

};

export function getCustomerAttriutes(customerid, keys) {
    return httpsevice.get(`/api/plugins/telemetry/CUSTOMER/${customerid}/values/attributes/SERVER_SCOPE?keys=${keys}`);
};

export function getCustomerAssets() {
    var customeringfo = GetUserDetails();
    var customerid = customeringfo.customerId;
    return httpsevice.get(`/api/customer/${customerid}/assets?pageSize=1000&page=0`);
}


export function getCustomerAssetDevices(assetsid) {
    return httpsevice.get(`/api/relations/info?fromId=${assetsid}&fromType=ASSET`);


}

export function GetCustomerAlarm() {
    var getUserDettails = GetUserDetails();
    return httpsevice.get(`api/alarm/CUSTOMER/${getUserDettails.customerId}?pageSize=10000&page=0&sortProperty=createdTime&sortOrder=DESC&1405181500506&endTime=1905267900506&searchStatus=ACTIVE&fetchOriginator=true`);

}

export function GetCustomers() {
    return httpsevice.get(`/api/customers?pageSize=1000&page=0&sortProperty=createdTime&sortOrder=DESC`);

}

export function getActivationLink(userid) {
    return httpsevice.get(`/api/user/${userid}/activationLink`)
}

export function activateUser(token, password) {
    return httpsevice.post(`/api/noauth/activate?sendActivationMail=false`, {
        "activateToken": token,
        "password": password
    });
}

export function addUser(ty, cid, em, n, fn, ln) {
    var getUserDettails = GetUserDetails();
    console.log("User Log", getUserDettails);
    return httpsevice.post(`/user`, {
        type: ty,
        email: em,
        firstName: fn,
        lastName: ln,
        additionalInfo: {
            description: "",
            defaultDashboardId: null,
            defaultDashboardFullscreen: false
        },
        authority: "CUSTOMER_USER",
        tenantId: {
            entityType: "TENANT",
            id: "6679d430-37c4-11eb-afcd-857a81b6933a"
        },
        customerId: {
            entityType: "CUSTOMER",
            id: cid
        }
    });

}

export function createCustomer(customer) {
    return httpsevice.post('/api/customer', {
        "title": customer.name,
        "additionalInfo": {
            "description": customer.description
        },
        "country": null,
        "city": null,
        "state": null,
        "zip": null,
        "address": customer.address,
        "address2": null,
        "phone": customer.phone,
        "email": customer.email
    })
}

export function assignDeviceToCustomer(customerid, deviceid) {
    return httpsevice.post(`/api/customer/${customerid}/device/${deviceid}`);
}

export function unassigneDevice(devicid) {
    return httpsevice.delete(`/api/customer/device/${devicid}`);
}
export function getCustomer(customerId) {
    return httpsevice.get(`/api/customer/${customerId}`);
}
export function UpdateCustomer(customerId, customer) {
    return httpsevice.post(`/api/customer/${customerId}`, customer);
}

export function updateGenericAttributes(customerid, attributes)
{
    return httpsevice.post(`/api/plugins/telemetry/CUSTOMER/${customerid}/SERVER_SCOPE`,attributes);
}

export function getGenericAttributes(customerid,keys)
{
    return httpsevice.get(`/api/plugins/telemetry/CUSTOMER/${customerid}/values/attributes/SERVER_SCOPE`);
}