import React,{useState} from 'react';
import { Plus } from 'react-bootstrap-icons';
import { NotificationManager } from 'react-notifications';
import HttpService from '../../../services/HttpService';
import GenericFormElement, { ListFormElement } from '../../Ulties/GenericFormElement';
import GeneridModal from '../../Ulties/GeneridModal';


const settingslabel = {
    "latitude": "-26.7174643",
    "longitude": "27.0771158",
    "Temperature 1 High Limit": "35",
    "Temperature 1 Low Limit": "0",
    "Temperature 2 High Limit": "35",
    "Temperature 2 Low Limit": "0",
    "Temperature 3 High Limit": "35",
    "Temperature 3 Low Limit": "-1",
    "Temperature 4 High Limit": "35",
    "Temperature 4 Low Limit": "-1",
    "Humidity 1 High Limit": "80",
    "Humidity 1 Low Limit": "30",
    "UPS Voltage High Limit": "255",
    "UPS Voltage Low Limit": "180",
    "UPS Battery High Limit": "110",
    "UPS Battery Low Limit": "40",
    "UPS Load Level High Limit": "80",
    "UPS Load Level Low Limit": "-1",
    "Mains Voltage High Limit": "255",
    "Mains Voltage Low Limit": "180",
    "Mains Current High Limit": "28",
    "Mains Current Low Limit": "1.5",
    "Active Power High Limit": "5500",
    "Active Power Low Limit": "200"
  };
  

export default function CreateNewDevice() {
    const [deviceInfo, setdeviceInfo] = useState({
        name:"",
        devicetype:"",
        desription:"",
        latitude:0,
        longitude:0
    });

    const loadDeviceType = ()=>{
        HttpService.get('/api/deviceProfileInfos?pageSize=10&page=0')
        .then(response=>{
            const {data} = response;        

            var list = data.data.map(a=> {
                return {"name":a.name, "id":a.id.id};
            });
          
            setdeviceTypes([...list]);

        }).catch(error=>{
            NotificationManager.error('Failed to load device types');
        });
    };

    const createDevice = ()=>{
        HttpService.post('/api/device',{
            name:deviceInfo.name,
            additionalInfo:{gateway: false, description: deviceInfo.desription},
            deviceProfileId: {entityType: "DEVICE_PROFILE", id: deviceInfo.devicetype},
            customerId: null,
            label:deviceInfo.desription
        })
        .then(response=>{
            const {data} = response;
            NotificationManager.success('Device Created Successfully');

            HttpService.post(`api/plugins/telemetry/DEVICE/${data.id.id}/SHARED_SCOPE`,{settings:{...settingslabel,
                "latitude":deviceInfo.latitude,
                "longitude":deviceInfo.longitude
            }}).then(response=> {})
            .catch(error => NotificationManager.error('Error when Initialising device settings.'));

        })
        .catch(error=>{
            if(error.data.status === 400)
            NotificationManager.warning(error.data.message );
        })
    }

    const [deviceTypes, setdeviceTypes] = useState([]);
    const updateValue=(e)=> {        
        setdeviceInfo({...deviceInfo,[e.target.name]:e.target.value});
    }
  return (
    <div>
       <GeneridModal size={"md"} modalOpened={()=> loadDeviceType()} HandleModalAction={()=> createDevice()}   buttonTitle={"Create"}   icon={<Plus  />} title={"Create device"}>     
       <GenericFormElement handleChange={updateValue} title={"Name"} name={"name"} type="string" /> 
       <GenericFormElement handleChange={updateValue} title={"Description"} name={"desription"} type="string"/>
     
        <ListFormElement  handleChange={updateValue} name={"devicetype"} values={deviceTypes} title="Type" />
        <GenericFormElement value={deviceInfo.latitude} handleChange={updateValue} title={"Latitude"} name={"latitude"} type="decimal" /> 
       <GenericFormElement value={deviceInfo.longitude} handleChange={updateValue} title={"Longitude"} name={"longitude"} type="decimal"/> 
     </GeneridModal>
    </div>
  );
}
