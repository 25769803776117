import React, { useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import DateTimeSelector from '../DateTimeSelector';
import GenericGauge, {GenericDisplay} from '../GenericGauge';
import moment from 'moment';
import { GetTelemetries } from '../../../services/DeviceService';
import ChartHistory from './ChartHistory';
import SettingsComponents, { AdvanceSettingComponents } from '../../SettingsComponents';
import MqttSettingsGeneratorComponent from './MqttSettingsGeneratorComponent';
import { AlarmsList } from '../AlarmsComponents';
import { PH1 } from '../SimplifiedView/PH1';


const TelemetriesUsed =
"Door,Temperature,Humidity,InternalBattery,PowerStatus,Flooding,PowerStatus";

const settingslabel = {
  latitude: "",
  longitude: "",
  "Temperature High Limit": "",
  "Temperature Low Limit": "",
  "Humidity High Limit": "",
  "Humidity Low Limit": "", 
};

export default function PowerMonitor({telemetries,settings, deviceId,handleSettingsUpdate,handlonClickIcon, selectedtab, displayDashboard}) {
    const [key,setKey] = useState(selectedtab);
    const [histories,sethistories] = useState(null);
    const getTelemetries =async  (from , to)=>{
        const {data} = await GetTelemetries(deviceId,TelemetriesUsed,moment(from).format("x") , moment(to).format("x"));
        
        Object.keys(data).map((a) => (data[a] = data[a].reverse()));
        sethistories(data);
        return data;
    }
   
    const clickicon = (e) => {      
       
        
        }
  return (
    <div>
      <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
    >
    
      <Tab eventKey="home" title="i-PoP Full Status">
      
     
     <div style={{paddingTop: 10}}>
       <PH1 sensors={telemetries} sensorClicked={handlonClickIcon} deviceId={deviceId}  dashboard ={displayDashboard.deviceDashboard} />
     
     </div>
        
      </Tab>
      <Tab eventKey="profile" title="i-PoP History">
      <div>
        <DateTimeSelector handlerDateSelection={getTelemetries} />   
        {histories && <Row>
                <Col style={{paddingTop:20}} ><ChartHistory name="TEMPERATURE" syncidentification={"sync1"} data={histories.Temperature }  /></Col>             
                <Col style={{paddingTop:20}} ><ChartHistory name="HUMIDITY" syncidentification={"sync1"} data={histories.Humidity }  /></Col>   
                <Col style={{paddingTop:20}} ><ChartHistory name="BATTERY VOLTAGE" syncidentification={"sync1"} data={histories['InternalBattery'] }  /></Col>   
                <Col style={{paddingTop:20}} ><ChartHistory name="POWER STATUS" syncidentification={"sync1"} data={histories['PowerStatus'] }  /></Col>   
              </Row>
                  }       
          
      </div>
      </Tab>
      <Tab eventKey="Alarms" title="i-PoP Alarms" >
      <AlarmsList  DeviceUniqueId = {deviceId} />
      </Tab>
      <Tab eventKey="contact" title="i-PoP Configuraton" >      

      <AdvanceSettingComponents deviceid={deviceId} handleSettingUpdates={handleSettingsUpdate} attributes={{latitude:"", longitude:"","Temperature High Limit":"", "Temperature Low Limit":"","Humidity Low Limit":"","Humidity High Limit":"","Internal Battery High Limit":"","Internal Battery Low Limit":""}} />
        </Tab>
      
    </Tabs>
    </div>
  );
}
