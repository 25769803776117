import React, { useState } from "react";
import { Badge, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { CardHeading } from "react-bootstrap-icons";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Link } from "react-router-dom";
import { GetUserDetails } from "../../services/AuthService";
import HttpService from "../../services/HttpService";
import GenericFormElement from "../Ulties/GenericFormElement";
import {SimpleModal} from "../Ulties/GeneridModal"

function CustomerProfile(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [userDetails, setuserDetails] = useState(GetUserDetails());

  const [passwordInfo, setpasswordInfo] = useState({
    currentPassword:"",
    newPassword:"",
    newPasswordConfirm:""
  })

  const changePassword =()=>{
    if(passwordInfo.newPassword != passwordInfo.newPasswordConfirm)
    {
      NotificationManager.warning('Entered passwords must be the same!');
      return ;
    }

    HttpService.post('/api/auth/changePassword',{
      currentPassword: passwordInfo.currentPassword,
      newPassword: passwordInfo.newPassword
    })
    .then(response=>{
      NotificationManager.success(`Password change successfully!`)
    }).catch(a=>{
      const {data} =a;
      if(data)
      NotificationManager.warning(`Failed to change password : ${data.message}`);
    })
  }

  return (
    <div>
    
      Signed in as:{" "}
      <a ><SimpleModal title={"Profile"} modalClosed={()=>{
        setpasswordInfo({ currentPassword:"",
        newPassword:"",
        newPasswordConfirm:""})
      }}  badgeTitle={userDetails && userDetails.firstName}>
        <div>
          <Card>
            <Card.Header>Change Password</Card.Header>
            <Card.Body>
        <GenericFormElement value={passwordInfo.currentPassword} title={"Current password"} name={"currentPassword"}  type="password" handleChange={(e)=> setpasswordInfo({...passwordInfo,[e.target.name]:e.target.value})}  /> 
        <GenericFormElement value={passwordInfo.newPassword} title={"New password"} name={"newPassword"} type="password" handleChange={(e)=> setpasswordInfo({...passwordInfo,[e.target.name]:e.target.value})}  /> 
        <GenericFormElement value={passwordInfo.newPasswordConfirm} title={"New password again"} name={"newPasswordConfirm"} type="password" handleChange={(e)=> setpasswordInfo({...passwordInfo,[e.target.name]:e.target.value})}  /> 
        <Button onClick={changePassword}>Change password</Button>
            </Card.Body>
            
          </Card>
       
        </div>
        </SimpleModal> </a> ,{" "}
      <Link to="/app/Logout">Logout!</Link>
      
    </div>
  );
}

export default CustomerProfile;
