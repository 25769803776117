import React,{useState} from 'react';
import { Button, FormControl } from 'react-bootstrap';
import DataTableExtensions from "react-data-table-component-extensions";

import DataTable from 'react-data-table-component';
import { NotificationManager } from 'react-notifications';
import HttpService from '../../../services/HttpService';

import GeneridModal from '../../Ulties/GeneridModal';
import { List } from 'react-bootstrap-icons';
import moment from 'moment';

import ReactQuill from "react-quill";
import Interweave from 'interweave';


import 'react-quill/dist/quill.snow.css';

const devicesCollums = [
  {
    grow:1,
    name: "Date",
    selector: "date",
    sortable: true,
    cell: (row) => (
      <div style={{fontWeight: "bold"}}>
        {moment(Number(row.date)).format("DD/MM/yyyy HH:mm")}{" "}
      </div>
    ),
  },
  {
    grow:2,
    name: "Note",
    selector: "note",
    cell: (row) => (
      <div>
      <Interweave content= {row.note}/>
       
      </div>
    )
  },
  {
    grow:1,
    name: "Added By",
    selector: "addedBy",
    sortable: true,
    cell: row=> <div style={{fontWeight: "bold"}}>{row.addedBy}</div>
  },
 
];



export default function DeviceNotes({Id}) {


  const [notes, setnotes] = useState([]);
  const [newNotes, setnewNotes] = useState("");

  const loadNotes = ()=>{
    HttpService.get(`/note/device/${Id}`).then(res=>{
      const {data} = res;
      setnotes([...data])
    }).catch(error=>{
      NotificationManager.error(`Failed to load notes (${error})`);
    })
  }

  const addNewNote=()=>{

    const r = window.confirm("Are you sure you wnat to add this note ?"); if(r == false){ 
      return;}

   
    HttpService.post("/note",{
      note:newNotes,
      deviceId:Id
    }).then(res=>{
      loadNotes();
    }).catch(error=>{
      NotificationManager.error(`Failed to add notes (${error})`);
    })
  }


  return (
    <>
      <GeneridModal modalClosed={()=> {setnewNotes("")}} title="Device Notes" size="xl" icon={<List />} HandleModalAction={()=>{ addNewNote();setnewNotes("")}}   buttonTitle={"Add Note"}  modalOpened={()=> {loadNotes();setnewNotes("")}} >
         
         <div style={{overflowY:"scroll", height:400, padding:10}}>
         <DataTableExtensions columns={devicesCollums} data={notes}>
                <DataTable
                  noHeader
                  defaultSortField="date"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
         </div>
          <div style={{padding:10,height: 200}}>      

          <ReactQuill
        theme='snow'
      value={newNotes} 
       onChange={setnewNotes}
        style={{height: '100px'}}
      />   
           
         </div>
     
     </GeneridModal>

    
    </>
  );
}
