import React, { useState, useEffect } from "react";
import httpService, {
  setAuthorizationHeader,
} from "../../../services/HttpService";
import moment from "moment";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Badge, Card, Col, Row } from "react-bootstrap";
import ReactMapGL, { Marker as MarkerGl } from "react-map-gl";
import {
  ArrowDown,
  Bell,
  BellFill,
  List,
  Reception4,
  X,
  XCircleFill,
} from "react-bootstrap-icons";
import Header from "../../Header";
import { Link } from "react-router-dom";
import CheckIfAuthencicated from "../../CheckIfAuthencicated";
import { Button } from "bootstrap";
import { generateColorOnSeveriry } from "../../../services/Utilities";

var backgoundcolourStyle = "#bd0404";
backgoundcolourStyle = "#bd0404";
var countBackground = 0.7;
//backgoundcolourStyle="black";

function AlarmsCount({ alarms }) {
  return (
    <div>
      <h6>{alarms > 0 && <Badge variant="danger">{alarms}</Badge>}</h6>
      <h6>{alarms < 1 && <Badge variant="success">{alarms}</Badge>}</h6>
    </div>
  );
}


const DisplayContent = ({ title, alarms, name, hide, time, id }) => {
  return (
    <Card
      className="text-center"
      style={{
        margin: 20,

        opacity: countBackground,
        backgroundColor: backgoundcolourStyle,
      }}
    >
      <Card.Header style={{ backgroundColor: "grey" }}>
        <Card.Title style={{ color: "white" }}>
          <XCircleFill onClick={hide} />{" "}
          <Link style={{ color: "white" }} to={`/app/GenericDevice/${id}/home`}>
            {" "}
            {name}
          </Link>{" "}
          - {title} ({alarms && alarms.length}){" "}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text style={{ color: "white" }}>
          <Row>
            <Col></Col>
            <Col>
              <h1></h1>
            </Col>
          </Row>
          {alarms &&
            alarms.map((a) => (
              <p style={{ fontWeight: "bold", color: "" }}>
                {" "}
                {moment(Number(a.created_time)).format(
                  "yyyy/MM/DD HH:mm:ss"
                )} : {a.type.toUpperCase()}
              </p>
            ))}
          {}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const AlarmDisplau = ({ alarmInfo }) => {



  return (
    <Card
      style={{
        margin: 5,
        borderWidth: 2,
        opacity: countBackground,
        backgroundColor: generateColorOnSeveriry(alarmInfo.severity),
      }}
    >
      <Card.Body className="text-center">
        
        <Bell />{" "}
        <Link
          style={{ color: "white", fontWeight: "bold" }}
          to={`/app/GenericDevice/${alarmInfo.device_id}/home`}
        >
          {" "}
         
          
          {  alarmInfo.devicepersonalname || alarmInfo.name} 
         
        </Link>
        <p style={{ fontWeight: "bold", color: "white" }}>{alarmInfo.type.toUpperCase()}
        <div style={{ fontWeight: "", color: "white" }}> {moment(Number(alarmInfo.created_time)).toNow(true)}</div>
        </p>
       
      </Card.Body>
    </Card>
  );
};

const DisplayMessage = ({ title, value, ICON }) => {
  return (
    <Card
      style={{
        margin: 10,
        borderWidth: 2,
        opacity: countBackground,
        backgroundColor: backgoundcolourStyle,
      }}
    >
      <Card.Body className="text-center" style={{ color: "white" }}>
        <Card.Title>{title}</Card.Title>

        <Card.Text>
          <Row>
            <Col>
              <h1>{ICON}</h1>
            </Col>
            
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default function DevicesStatus() {

  const [devices, setdevices] = useState([]);


  var intervaalid;

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: -25.7577,
    longitude: 28.4376,
    zoom: 10.3,
  });

  const getColour = (alarms, status) => {
    var colour = "green";
    if (alarms === 0) colour = "green";
    if (alarms > 0) colour = "red";
    if (status === false) colour = "orange";

    return colour;
  };

  const [alarms, setalarms] = useState(null);

  const [showAlarms, setshowAlarms] = useState(false);

  const [deviceSelected, setdeviceSelected] = useState("");

  const [activeDevices, setactiveDevices] = useState(0);

  const [alarmsCount, setalarmsCount] = useState(0);
  const [deviceSelectedId, setdeviceSelectedId] = useState("");
  const [Fullalarms, setFullalarms] = useState([]);

const [DisplayAlarms, setDisplayAlarms] = useState(false)


  const loadAllDevicesAlarms = () => {
    httpService
      .get("/repport/mydevices/alarms")
      .then((response) => {
        const { data } = response;
        // alert(JSON.stringify(data));
        setFullalarms([...data]);
      })
      .catch((error) => {
      ///  alert(JSON.stringify(error));
      });
  };

  const getDeviceAlarms = (deviceId, devicename) => {
    httpService
      .get("/repport/deviceallarmsfull/" + deviceId)
      .then((response) => {
        setdeviceSelectedId(deviceId);
        //console.log("alarms - > ", response.data);
        setalarms([...response.data]);
        setdeviceSelected(devicename);
        setshowAlarms(true);
      })
      .catch((error) => {
       // console.log("Error", error);
      });
  };

  const loadDevices = () => {
    httpService
      .get("/repport/deviceStatus")
      .then((response) => {
       // console.log("Devices - > ", response.data);
        setdevices([...response.data.devices]);
        setactiveDevices(response.data.inactive);
        setalarmsCount(response.data.alarms);

        var geSpacials = response.data.devices.map((a) => ({
          type: "Feature",
          properties: {
            cluster: false,
            crimeId: a.id,
            category: a.alarms,
          },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(a.settings.longitude),
              parseFloat(a.settings.latitude),
            ],
          },
        }));

        ///alert(JSON.stringify(geSpacials));
     //  setdeviceGeoSpacials([...geSpacials]);

      

      })
      .catch((error) => {
        //console.log("Error", error);
      });
  };
  


  useEffect(() => {
    loadDevices();
    loadAllDevicesAlarms();
    intervaalid = setInterval(() => {
      loadDevices();
      loadAllDevicesAlarms();
    }, 5000);
    return () => {
      clearInterval(intervaalid);
    };
  }, []);

  //  Cluster Setups

 


  // get Clusters



 

  //Map Display
  return (
    <>

      <Header color="black" activeHeaderSatus={true} activeDevice={devices.length} InactiveDevice={activeDevices} ActiveAlarms={alarmsCount} />
      <div style={{ height: "100vh" }}>
        <ReactMapGL
       
          {...viewport}
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/mapbox/dark-v10"
          onViewportChange={(viewport) => setViewport(viewport)}
          mapboxApiAccessToken={
            "pk.eyJ1IjoiamFkZW1vdGUiLCJhIjoiY2tpdTE1aDBuMGlyZjMzbXVvYXcya242bCJ9.HQNJONu_BW3EAFYZF7tdHw"
          }
        >
          <Row>
            <Col md={2} sm={2}>
              <div className="text-center">
              <BellFill style={{marginTop:10}}  onClick={()=> setDisplayAlarms(!DisplayAlarms)} color={"#bd0404"} size={30}></BellFill>
              </div>
            
              <div
                style={{
                  overflowY: "scroll",
                  height: "50%",
                  scrollbarColor: "red",
                  scrollbarWidth: 1,
                }}
              >
                {DisplayAlarms &&  Fullalarms &&
                  Fullalarms.map((a) => <AlarmDisplau alarmInfo={a} />)}

                {Fullalarms.length===0 &&
                 <DisplayMessage title="No Alarms" ICON={<Bell color="green" />} />}                  
               
              </div>
            </Col>
            <Col>
              {/* <Display
                title={"Active Device"}
                value={devices.length}
                ICON={<Reception4 color="BLACK" size={50} />}
              /> */}
            </Col>
            <Col>
              {/* <Display
                title={"Inactive Device"}
                value={activeDevices}
                ICON={<Reception4 color="ORANGE" size={50} />}
              /> */}
            </Col>
            <Col>
              {/* <Display
                title={"Active Alarms"}
                value={alarmsCount}
                ICON={<Bell color="red" size={50} />}
              /> */}
              {showAlarms && (
                <>
              
                <DisplayContent
                  title="Alarms"
                  alarms={alarms}
                  name={deviceSelected}
                  id={deviceSelectedId}
                  hide={() => setshowAlarms(false)}
                />
                </>
                
              )}
            </Col>
          </Row>

          {devices.map((a) => (
            <MarkerGl
              latitude={a.settings ? Number(a.settings.latitude) : 0}
              key={a.id}
              longitude={a.settings ? Number(a.settings.longitude) : 0}
            >
              <div
                onDoubleClick={() => getDeviceAlarms(a.id, a.name)}
                title={`${a.name} Alarms:(${a.alarms})`}
                style={{
                  width: "15px",
                  backgroundColor: getColour(a.alarms, a.active),
                  height: "15px",
                }}
              >
                
              </div>
            </MarkerGl>
          ))}
        </ReactMapGL>
      </div>
    </>
  );
}
