import axios from 'axios';
import NotificationManager from 'react-notifications/lib/NotificationManager';

const envSettings = window ;


var baseurl = 'https://secure.app.nsiotcloud.com:81';
baseurl="http://102.37.97.161:4001"
baseurl="https://app-iot-dev.nsiotcloud.com:401";
//baseurl="http://192.168.1.245:4001";
//baseurl="http://localhost:4001"
//baseurl = process.env.REACT_APP_TB_SERVER;
/////
 ///baseurl = 'http://192.168.1.249:8080/';
 baseurl = 'https://app-iot-dev.nsiotcloud.com:401/';

 baseurl = 'https://dev.app.netshieldsa.com/';
  baseurl=envSettings.API_URL
//baseurl="http://localhost:40009";
//baseurl="http://localhost:40009";

axios.defaults.baseURL= baseurl;

export const setAuthorizationHeader = (token,tokenmc) => {
    axios.defaults.headers.common['X-Authorization'] = token;
    axios.defaults.headers.common['X-Authorization-mc'] = tokenmc;

    localStorage.setItem("token",token);   
    localStorage.setItem("tokencs",tokenmc);    
}



axios.interceptors.request.use(function(config) {
    const token = localStorage.getItem("token");    
    const tokencs = localStorage.getItem("tokencs");    
    //console.log("Token", token);
  
    if ( token != null ||  tokencs!=null) {      
        config.headers.authorization = 'secret token'; 
        config.headers.common['X-Authorization'] = `Bearer ${token}`;
        config.headers.common['X-Authorization-mc'] = tokencs
      //  axios.defaults.headers.common['X-Authorization-mc'] = tokencs;
    }
  
    return config;
  }, function(err) {
    return Promise.reject(err);
  });

axios.interceptors.response.use(null, error => {
    const {
        status
    } = error.response;
    if (status > 399 && status < 600) {

        if(status ===403)
        NotificationManager.warning('You dont have authorization to perform this action.');   
        if(status ===401)  
        window.location = '/app/login';

        console.log("Expect error",error);
        return Promise.reject(error.response);

    } else {
        console.log("Errror for something haooend");
        return Promise.reject(error);
    }
})

export default ({
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete
});