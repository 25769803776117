import React, { useEffect, useState } from "react";
import MultiSelect from "react-multi-select-component";
import {
  Card,
  Col,
  Row,
  Tab,
  Tabs,
  Image,
  Form,
  Button,
  Modal,
  Badge,
} from "react-bootstrap";
import GenericGauge, { GaugeCard, GenericDisplay } from "../GenericGauge";
import ChartHistory, { ChartHistoryBig } from "./ChartHistory";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import moment from "moment";
import {
  GetTelemetries,
  UpdateSettings,
} from "../../../services/DeviceService";
import DateTimeSelector from "../DateTimeSelector";
import SensorHistoryModal from "../SensorHistoryModal";
import { AlarmsList } from "../AlarmsComponents";
import SettingsComponents, {
  AdvanceSettingComponents,
} from "../../SettingsComponents";
import NotificationManager from "react-notifications/lib/NotificationManager";
import Cabinet from "../SimplifiedView/Cabinet";
import DisplayOnUserType from "../../Ulties/DisplayOnUserType";



const TelemetriesUsed =
  "Door,Temperature,Temperature2,Humidity,VBAT,SSI,UPS_BATTERY_LEVEL,UPS_LAOD_LEVEL,UPS_VOLTAGE_LEVEL,MAINS_VOLTAGE,MAINS_CURRENT,MAINS_FREQUENCY,mains_Actice_Power";

  const historiesList =
  "Temperature,Temperature2,Humidity,UPS_BATTERY_LEVEL,UPS_LAOD_LEVEL,UPS_VOLTAGE_LEVEL,MAINS_VOLTAGE,MAINS_CURRENT";


  // const historiesListWithLabels =   [
  //  {name:"Temperature", label:"Top Temperature"},
  //  {name:"Temperature2", label:"Bottom Temperature"},
  //  {name:"Humidity", label:"Relative Humidity"},
  //  {name:"UPS_BATTERY_LEVEL", label:"UPS Battery Level"},
  //  {name:"UPS_LAOD_LEVEL", label:"UPS Battery Load Level"},
  //  {name:"MAINS_VOLTAGE", label:"Mains Voltage"},
  //  {name:"MAINS_CURRENT", label:"Mains Current"},
  //  {name:"mains_Actice_Power", label:"Main Active Power"}
  //  ]


const settingslabel = {
  latitude: "",
  longitude: "",
  "Temperature 1 High Limit": "",
  "Temperature 1 Low Limit": "",
  "Temperature 2 High Limit": "",
  "Temperature 2 Low Limit": "",
  "Temperature 3 High Limit": "",
  "Temperature 3 Low Limit": "",
  "Temperature 4 High Limit": "",
  "Temperature 4 Low Limit": "",
  "Humidity 1 High Limit": "",
  "Humidity 1 Low Limit": "",
  "UPS Voltage High Limit": "",
  "UPS Voltage Low Limit": "",
  "UPS Battery High Limit": "",
  "UPS Battery Low Limit": "",
  "UPS Load Level High Limit": "",
  "UPS Load Level Low Limit": "",
  "Mains Voltage High Limit": "",
  "Mains Voltage Low Limit": "",
  "Mains Current High Limit": "",
  "Mains Current Low Limit": "",
  "Active Power High Limit": "",
  "Active Power Low Limit": "",
};

const IconFeedback = (props)=>{

  return (<>
  <div  style={{position:"relative",left:props.left, top:props.top}} >
    <div>   <Badge variant="">{props.title}</Badge></div> 
  <img title={props.details}  style={{width:30, height:30}} src={props.img} />
  </div>
  </>)
}

export default function SmartCabinet({
  telemetrie,
  settings,
  deviceid,
  Alarms,
  selectedtab,
  displayDashboard,
  historiesListWithLabels
}) {
  const mysettings = settings;
  const [key, setKey] = useState(selectedtab);
  const [histories, sethistories] = useState(null);

  const [itemonMap, setitemonMap] = useState({
    left:-8, top:-90, img:'/images/officeplan.PNG'  , title:'255 %'
  })

  const [listOfTelemetries, setlistOfTelemetries] = useState("")

  const [iconsOnDevice, seticonsOnDevice] = useState([
    
    { details:'Temperature 2',left:-300, top:-735, img:'/images/humidity_good.png'  , title:'22 %'},
    { details:'Temperature 3',left:-400, top:-650, img:'/images/humidity_good.png'  , title:'22 %'},
    { details:'Temperature 4',left:-350, top:-700, img:'/images/temperature_good.png'  , title:'19 C'},
   { details:'Temperature 5',left:-350, top:-700, img:'/images/temperature_good.png'  , title:'20 C'},
   { details:'Temperature 6',left:-350, top:-700, img:'/images/temperature_bad.png'  , title:'45 C'},
   { details:'Temperature 7',left:-350, top:-700, img:'/images/temperature_bad.png'  , title:'65 C'}

  
  ])

  const upateDeviceSettings = async (settings) => {
    try {
      const { data } = await UpdateSettings(deviceid, settings);

      NotificationManager.success("Device Settings Updated Successfully");
    } catch (error) {
      //NotificationManager.error('You are not allowed to updated device settings');
    }
  };
  const getTelemetries = async (from, to) => {    

    var TelemetriesUsed = "";

    try {
      var historiesSettingsObejcet = JSON.parse(historiesListWithLabels);

      var info =[]

      historiesSettingsObejcet.forEach(label => {
        TelemetriesUsed = TelemetriesUsed + "," + label.name
      });

      TelemetriesUsed = TelemetriesUsed.substring(1);
    } catch (error) {
      
    }


    const { data } = await GetTelemetries(
      deviceid,
      TelemetriesUsed,
      moment(from).format("x"),
      moment(to).format("x")
    );
    Object.keys(data).map((a) => {
      data[a].map(b=> {
        var temp = b;
        temp.value = Number(temp.value);

        return temp;
      }) ;
      
      data[a] = data[a].reverse();
    });
    sethistories(data);
    return data;
  };

  return (
    <div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="home" title="i-PoP Status">
          <Cabinet
            sensors={telemetrie}
            deviceId={deviceid}
            displayDashboard={displayDashboard}
          />
          {/* <CabinetDeviceTelemetries telemetries= {telemetrie} settings={mysettings} deviceId={deviceid} /> */}
        </Tab>

        <Tab eventKey="profile" title="i-PoP History">
          <div>
            <DateTimeSelector handlerDateSelection={getTelemetries} />

            {histories && (
              <Row>
                {historiesListWithLabels &&
                  JSON.parse(historiesListWithLabels).map((a) => (
                    <Col style={{ margin: 10 }}>
                      <ChartHistory
                        name={a.label}
                        syncidentification={"sync1"}
                        data={histories[a.name]}
                      />
                    </Col>
                  ))}
              </Row>
            )}
          </div>
        </Tab>
        <Tab eventKey="Alarms" title="i-PoP Alarms">
          <AlarmsList DeviceUniqueId={deviceid} />
        </Tab>
        <Tab eventKey="contact" title="i-PoP Configuration">
          <DisplayOnUserType typeToCheck="TENANT_ADMIN" checkAlso="Technician">
            <AdvanceSettingComponents
              deviceid={deviceid}
              handleSettingUpdates={upateDeviceSettings}
              attributes={settingslabel}
            />
          </DisplayOnUserType>
        </Tab>
 
      </Tabs>
    </div>
  );
}
