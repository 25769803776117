
import React,{useState} from 'react'
import NotificationManager from 'react-notifications/lib/NotificationManager';
import HttpService from '../../services/HttpService';
import GenericFormElement from '../Ulties/GenericFormElement';
import { HeaderModal } from '../Ulties/GeneridModal'

var QRCode = require('qrcode.react');
export default function WifiPairingGenerator() {    
    const [wifiInfo, setwifiInfo] = useState({
        wifi:"NETSHIELD SCC ",
        password:"",
        ip:"192.168.4.1",
        port:80
    })

    const [qrcodeValue, setqrcodeValue] = useState(false)

    const updateComponent = (e)=>{       
        setwifiInfo({...wifiInfo,[e.target.name]:e.target.value});
    }

    const generateQrCode = ()=>{
        HttpService.post('/utilities/generateWificonnector',wifiInfo).then(response=>{
            const {message,plain} = response.data;
            console.log('Plain', plain);
            setqrcodeValue(message);
        }).catch(error=>{
            if(error)
            {
                console.log(error);
                NotificationManager.warning(`Failed to generate qrcode ${error.data.message}`);
            }
               
        })
    }

    const init=()=>{
        setwifiInfo({
            wifi:"NETSHIELD SCC ",
            password:"",
            ip:"192.168.4.1",
            port:80
        })

        setqrcodeValue(false);
    }
    return (
        <>
            <HeaderModal modalClosed={()=>init()} HandleModalAction={()=> generateQrCode()} size="md" modalOpened={()=> {}} buttonTitle="Generate" title="Wifi Pairing Generator" badgeTitle={"Wifi Pairing"}>
                <div style={{textAlign:"center"}}>
              {qrcodeValue && <QRCode size={180} value={qrcodeValue} />   }              
                </div>
                <GenericFormElement name="wifi" value={wifiInfo.wifi}  handleChange={updateComponent} title="Wifi SSID"/>
                <GenericFormElement type="password" name="password" handleChange={updateComponent}  value={wifiInfo.password} title="Wifi Password"/>
                <GenericFormElement name="ip" handleChange={updateComponent}  value={wifiInfo.ip} title="Device IP"/>
                <GenericFormElement type="number" name="port" handleChange={updateComponent}  value={wifiInfo.port} title="Device Port"/>
            </HeaderModal>
        </>
    )
}
