import  React, {useEffect, useState}   from 'react';
import { Card, Col, Row, Tab, Tabs, Image, Form, Button, Modal } from 'react-bootstrap';
import GenericGauge,{GenericDisplay} from '../GenericGauge';
import ChartHistory,{ChartHistoryBig} from './ChartHistory';
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import moment from 'moment';
import { GetTelemetries, UpdateSettings } from '../../../services/DeviceService';
import DateTimeSelector from '../DateTimeSelector';
import SensorHistoryModal, {} from '../SensorHistoryModal';
import { AlarmsList } from '../AlarmsComponents';
import SettingsComponents, { AdvanceSettingComponents } from '../../SettingsComponents';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import SRU from '../SimplifiedView/SRU';
const TelemetriesUsed =
"Door,Temperature,Temperature2,Temperature3,Temperature4,Humidity,GEN_Fuel_Level,VBAT,SSI,UPS_BATTERY_LEVEL,UPS_LAOD_LEVEL,UPS_VOLTAGE_LEVEL,FrontDoor,BackDoor,MAINS_VOLTAGE,MAINS_CURRENT,MAINS_FREQUENCY,aircon,waterAircon,Fuel_Level";

const sensorsHistoris =
"Temperature,Temperature2,Humidity,GEN_Fuel_Level,MAINS_CURRENT,MAINS_VOLTAGE";


const settingslabel = {
  latitude: "",
  longitude: "",
  "Temperature 1 High Limit": "",
  "Temperature 1 Low Limit": "",
  "Temperature 2 High Limit": "",
  "Temperature 2 Low Limit": "",
  "Temperature 3 High Limit": "",
  "Temperature 3 Low Limit": "",
  "Temperature 4 High Limit": "",
  "Temperature 4 Low Limit": "",
  "Humidity 1 High Limit": "",
  "Humidity 1 Low Limit": "",
  "UPS Voltage High Limit": "",
  "UPS Voltage Low Limit": "",
  "UPS Battery High Limit": "",
  "UPS Battery Low Limit": "",
  "UPS Load Level High Limit": "",
  "UPS Load Level Low Limit": "",
  "Mains Voltage High Limit": "",
  "Mains Voltage Low Limit": "",
  "Mains Current High Limit": "",
  "Mains Current Low Limit": "",
  "Active Power High Limit": "",
  "Active Power High Limit": "",
  "Active Power High Limit": "",
  "Active Power Low Limit": "",
  "Fuel Level High Limit": "50",
  "Fuel Level Low Limit": "20",
};



const CabinetDeviceTelemetries = ({telemetries,settings, deviceId}) => {
    const [showSensorTelemetries, setshowSensorTelemetries] = useState(false);
    const [sensorInfo, setsensorInfo] = useState("");
    const clickicon = (e) => {
      setshowSensorTelemetries(true)
       ; setsensorInfo(e);
     
      
      }
    return (
      <div style={{paddingTop:10}}>       
      <SensorHistoryModal sensordetails={sensorInfo}  deviceid={deviceId} show={showSensorTelemetries} onHide={()=> setshowSensorTelemetries(false)}  /> 
          <Row >
              <Col>
              <Row>
         <GenericGauge name="TEMPERATURE" sensorName={"Temperature"}  onClickIcon={clickicon}  val={telemetries.Temperature[0].value} MaxValue={100} MinValue={0} Symbole={"C"} MinValueLimit={10} MaxValueLimit={60} col={3} />
         <GenericGauge name="BOTTOM TEMPERATURE" sensorName={"Temperature2"}  onClickIcon={clickicon} val={telemetries.Temperature2[0].value} MaxValue={100} MinValue={0} Symbole={"C"}  MinValueLimit={10} MaxValueLimit={60} col={3} />
         <GenericGauge name="AMBIENT TEMPERATURE" val={telemetries.Temperature[0].value} MaxValue={100} MinValue={0} Symbole={"C"}  MinValueLimit={10} MaxValueLimit={60} col={3}/>
         <GenericGauge name="HUMIDITY"  sensorName={"Humidity"}  onClickIcon={clickicon} val={telemetries.Humidity[0].value} MaxValue={100} MinValue={0} Symbole={"%"} MinValueLimit={10} MaxValueLimit={65} col={3} />
         
        </Row>
        <Row style={{ marginTop: 0 }}>
        <GenericGauge name="MAINS VOLTAGE" sensorName={"MAINS_VOLTAGE"}  onClickIcon={clickicon} val={telemetries.MAINS_VOLTAGE[0].value} MaxValue={300} MinValue={0} Symbole={"V"} MinValueLimit={200} MaxValueLimit={280} col={3} />
         <GenericGauge name="MAINS CURRENT" sensorName={"MAINS_CURRENT"}  onClickIcon={clickicon} val={telemetries.MAINS_CURRENT[0].value} MaxValue={50} MinValue={0} Symbole={"A"} MinValueLimit={0} MaxValueLimit={25} col={3} />
       
           <GenericDisplay name={"AIRCON 1"} sensorName={"Aircon1_On"}  onClickIcon={clickicon} value={telemetries.Aircon1_On[0].value} valueList={[{name:"OFF",value:"0",image:"airconOff.png"},{name:"ON",value:"1",image:"airconOnpng.png"}]} col={3}  /> 
          <GenericDisplay name={"AIRCON 2"} sensorName={"Aircon2_On"}  onClickIcon={clickicon} value={telemetries.Aircon2_On[0].value} valueList={[{name:"OFF",value:"0",image:"airconOff.png"},{name:"ON",value:"1",image:"airconOnpng.png"}]} col={3} /> 
  
      </Row>
      <Row style={{ marginTop: 0 }}>
      <GenericDisplay name={"DOOR"} value={telemetries.FrontDoor[0].value} valueList={[{name:"CLOSED",value:"0",image:"doorOpened.png"},{name:"OPENED",value:"1",image:"doorClosed.png"}]} col={3} /> 
          <GenericDisplay name={"POWER"}  value={"1"} valueList={[{name:"OFF",value:"0",image:"powerOff.png"},{name:"ON",value:"1",image:"powerOnpng.png"}]} /> 
        
          <GenericDisplay name={"ZONE 1"} value={telemetries.Zone_1[0].value} valueList={[{name:"SMOKE DETECTED",value:"1",image:"noFire.png"},{name:"NO SMOKE",value:"0",image:"fire.png"},{name:"ERROR",value:"2",image:"noFire.png"}]} col={3} /> 
         <GenericDisplay name={"ZONE 2"} value={telemetries.Zone_2[0].value} valueList={[{name:"SMOKE DETECTED",value:"1",image:"noFire.png"},{name:"NO SMOKE",value:"0",image:"fire.png"},{name:"ERROR",value:"2",image:"noFire.png"}]} col={3} /> 
       
          </Row>
        <Row style={{ marginTop: 0 }}>             
         <GenericDisplay name={"GENERATOR STATUS"} value={telemetries.Engine_On[0].value} valueList={[{name:"OFF",value:"0",image:"engineoff.png"},{name:"ON",value:"1",image:"engineon.png"}]} col={3} /> 
         {/* <GenericDisplay name={"DIESIEL LEAK"} value={telemetries?.dieselleak[0]?.value} valueList={[{name:"NO LEAK",value:"0",image:"NoWater.png"},{name:"LEAK DETECTED",value:"1",image:"Water.png"}]} col={3} /> 
         <GenericDisplay name={"FUEL CAP"} value={telemetries.Fuel_Cap_Off[0]?.value} valueList={[{name:"CLOSED",value:"0",image:"fuelcapclosed.png"},{name:"OPENED",value:"1",image:"fuelcapcopened.png"}]} col={3} />  */}
         <GenericGauge name="FUEL LEVEL" sensorName={"Fuel_Level"}  onClickIcon={clickicon}  val={telemetries.Fuel_Level[0].value} MaxValue={100} MinValue={0} Symbole={"%"} MinValueLimit={20} MaxValueLimit={100} col={3} />
        
        </Row>
              </Col>
          </Row>        
      </div>
    );
  };



export default function SmartCabinet({telemetrie,settings, deviceid , Alarms , selectedtab,displayDashboard}) {
    const [key, setKey] = useState(selectedtab);   
    const [histories,sethistories] = useState(null);
 

    const upateDeviceSettings = async (settings) =>{    
      try{
        const {data} = await UpdateSettings(deviceid,settings);
       
        NotificationManager.success('Device Settings Updated Successfully');
       }
       catch(error)
       {
       // NotificationManager.success('You are not allowed to updated device settings');
       }
    };
    const getTelemetries =async  (from , to)=>{
      

        try {
          const {data} = await GetTelemetries(deviceid,TelemetriesUsed,moment(from).format("x") , moment(to).format("x"));        
          Object.keys(data).map((a) => (data[a] = data[a].reverse()));
          sethistories(data);
          return data;
        } catch (error) {
          console.log('Error loading histories',error)
        }
    }
   
  return (
    <div>
     <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
    >
      <Tab eventKey="home" title="i-PoP Status">
     <SRU sensors= {telemetrie} deviceId={deviceid} displayDashboard={displayDashboard} />
      </Tab>
      <Tab eventKey="profile" title="i-PoP History">
      <div>
        <DateTimeSelector handlerDateSelection={getTelemetries} />     
          
              {histories && <Row>

                {sensorsHistoris.split(',').map(a=>(<Col style={{paddingTop:20}}><ChartHistory name={a} syncidentification={"sync1"} data={histories[a] }  /></Col>  ))}
                            
                {/* <Col style={{paddingTop:20}}><ChartHistory name="TEMPERATURE 2" syncidentification={"sync1"} data={histories.Temperature2 }  /></Col>     
                <Col style={{paddingTop:20}}><ChartHistory name="TEMPERATURE 3" syncidentification={"sync1"} data={histories.Temperature3 }  /></Col>              
                <Col style={{paddingTop:20}}><ChartHistory name="TEMPERATURE 4" syncidentification={"sync1"} data={histories.Temperature4 }  /></Col>     
               
                <Col style={{paddingTop:20}}><ChartHistory name="HUMIDITY" syncidentification={"sync1"} data={histories.Humidity }  /></Col>    
                <Col style={{paddingTop:20}}><ChartHistory name="MAINS VOLTAGE" syncidentification={"sync1"} data={histories.MAINS_VOLTAGE }  /></Col>    
                <Col style={{paddingTop:20}}><ChartHistory name="MAINS CURRENT" syncidentification={"sync1"} data={histories.MAINS_CURRENT }  /></Col>    
                <Col style={{paddingTop:20}}><ChartHistory name="Fuel Level" syncidentification={"sync1"} data={histories.GEN_Fuel_Level }  /></Col>   */}
              </Row>
                  }            
        
          
      </div>
      </Tab>
      <Tab eventKey="Alarms" title="i-PoP Alarms" >
      <AlarmsList  DeviceUniqueId = {deviceid} />
      </Tab>
      <Tab eventKey="contact" title="i-PoP Configuration" >
      <AdvanceSettingComponents
            deviceid={deviceid}
            handleSettingUpdates={upateDeviceSettings}
            attributes={settingslabel}
          />
      
      </Tab>
    </Tabs>
    </div>
  );
}
