import { Tab } from 'bootstrap';
import React,{useState} from 'react';
import { Tabs } from 'react-bootstrap';
import { Info } from 'react-bootstrap-icons';
import { SimpleDeviceList } from '../Devices/GenericDevice/DeviceTableList';
import GeneridModal from '../Ulties/GeneridModal';
import { UserTableList } from '../Users/ListUsers';
import { EdicustomerOnly } from './EditCustomer';
import SubscriptionInfo from './SubscriptionInfo';

export default function CustomerGeneralInfo({customerId,customerName, reloadCustomer}) {
    const [load, setload] = useState(false)
  return (
    <>
       <GeneridModal size="xl" modalOpened={()=> setload(true)} modalClosed={reloadCustomer} icon={<Info />} title={customerName} HandleModalAction={()=> alert("Update")}>
       <Tabs defaultActiveKey="home" transition={false} id="customer-information">
        <Tab eventKey="home" title="Info">
            {load && <EdicustomerOnly customerId={customerId} />}        
        </Tab>
        <Tab eventKey="profile" title="Users">
            {load && <UserTableList customerid={customerId} />}
        </Tab>
        <Tab eventKey="contact" title="Devices" >
        {load && <SimpleDeviceList customerId={customerId} />}
        </Tab>
        <Tab eventKey="subscrition" title="Subsription Info" >
        {load && <SubscriptionInfo customerId={customerId} />}
        </Tab>
</Tabs>
       </GeneridModal>
    </>
  );
}
