
import React,{useEffect, useState} from 'react';
import { ArrowUpRight, Gear } from 'react-bootstrap-icons';
import { NotificationManager } from 'react-notifications';
import HttpService from '../../../services/HttpService';
import { FromWithUpdateButton } from '../../Ulties/GenericFormElement';

import GeneridModal from '../../Ulties/GeneridModal';


export default function DeviceAdvanceSettings({deviceId,name}) {


const [settingsValues, setsettingsValues] = useState(
  {inactivityTimeout:{value:60000, title:"Inactitivy Time", isString:false,scope:"SERVER_SCOPE"},
  upsActicated:{value:"no", title:"Enable UPS", isString:true , scope:"SERVER_SCOPE"},
  allowRPC:{value:"true", title:"Allow Remote Command", isString:true , scope:"SERVER_SCOPE"},
  targetFirmPNMDS_App:{value:"7.4.0", title:"PNDMS Target Firmware", isString:true , scope:"SHARED_SCOPE"},
  targetFirm:{value:"4.4.8", title:"Target Firmware", isString:true , scope:"SHARED_SCOPE"},  
  firmwareVersion :{readonly:true,value:"0", title:"firmwareVersion", isString:true , scope:""},
  historyGraphs:{value:"", title:"Histoty Graphs", isString:true , scope:"SERVER_SCOPE"},
  settingsTresholdsDefinition:{value:"", title:"Settings Tresholds",isString:true, type:"json" , scope:"SERVER_SCOPE"},
  insettingssettingsDefinition:{value:"", title:"iSettings Definition",isString:true, type:"json" , scope:"SERVER_SCOPE"},
  insettings:{value:"", title:"CSV Settings",isString:true, type:"string" , scope:"SHARED_SCOPE"},
  rcpTimeout:{value:"", title:"RPC Timeout",isString:false, type:"json" , scope:"SHARED_SCOPE"},
});

const loadAttributes = ()=>{
  HttpService.get(`/api/plugins/telemetry/DEVICE/${deviceId}/values/attributes?keys=inactivityTimeout,allowRPC,targetFirm,targetFirmPNMDS_App,firmwareVersion,historyGraphs,limits,mains_voltage_low,settingsTresholdsDefinition,insettings,rcpTimeout,insettingssettingsDefinition`)
  .then(res=>{
    const {data} = res;  

    var mySettings = {};
    data.map(a=> {

      var content = a.value;

      if(settingsValues[a.key].type==="json")
      {
        try {
          content = JSON.stringify(content);
        } catch (error) {          
          NotificationManager.error(`Failed to parse ${a.key}`);
        }
      }

      mySettings[a.key] = { ...settingsValues[a.key], value:content , title:a.key}
    })
    
    setsettingsValues(set=>({
      ...set,
      ...mySettings
    }));

  }).catch(err=>{
    NotificationManager.error('Failed to load device advance settings');
  })
}
const updateSetting = (key, value,scope, type)=>{

  var valuetoSend = value;

  if(type)
  {
    if(type==="json")
    {
      try {
        valuetoSend = JSON.parse(value);
      } catch (error) {
        NotificationManager.success(`Failed to parse the JSON data`);
      }

    }
  }


    HttpService.post(`/api/plugins/telemetry/DEVICE/${deviceId}/${scope}`,{
      [key]:valuetoSend
    }).then(a=>{
      NotificationManager.success(`The value ${key} was successfully udpated`)
    }).catch(err=>{
      NotificationManager.error(`failed to update the value of ${key}`)
    })
}
const createValue = (value,isString) =>{

  if(isString)return value;
  else
  return Number(value)
}

const handleValueChange = (a,e)=>{
  setsettingsValues({...settingsValues,[e.target.name]:{...settingsValues[a],['value']:createValue(e.target.value,settingsValues[a].isString)}})
}


useEffect(() => {
 
}, [])

  return (
    <>
      <GeneridModal modalClosed={()=> {}} title="Advance Settings" size="lg" icon={<Gear />} buttonTitle={""} HandleModalAction= {()=> {}} modalOpened={()=> { loadAttributes(); }} >
         <div>
           
         {settingsValues && Object.keys(settingsValues).map(a=>(
                       <FromWithUpdateButton name={a} value={settingsValues[a].value} readonly={settingsValues[a].readonly}   type="string" title={settingsValues[a].title} handleChange={(e)=>handleValueChange(a,e)} handleButtonClick={()=> {
                        updateSetting(a,settingsValues[a].value,settingsValues[a].scope,settingsValues[a].type)
                       }}  />
                  ))}    
                 
         </div>
     </GeneridModal>
    </>
  );
}
