import {
    GetActiveAlarms,
    GetDevice,
    GetDeviceAttributes,
    GetTelemetries,
    GetUserDevices,
    LastGetTelemetries
} from './DeviceService';

const genericAttributes = ["active", "lastDisconnectTime", "lastConnectTime", "lastActivityTime","type"];
const setdetails =  async (deviceproperties, deviceid) =>{
    var {
        data
    } = await GetDeviceAttributes(deviceid);

    console.log(data);
    genericAttributes.map(a => {
        
        var findattribute = data.find(attribute=> attribute.key === a);
       
        if (findattribute) deviceproperties.push({
            [a]: `${findattribute.value}`
        });

    });
}
export  function GetDeviceDetails(deviceid) {
    var deviceproperties = [];
    setdetails(deviceproperties,deviceid);
    return deviceproperties;

}

export function CreateDevice(name, model)
{
    
}

export async function GetDeviceFullInformation(deviceid)
{
    const deviceinfo = await GetDevice(deviceid);
    const deviceattributes = await GetDeviceAttributes(deviceid);
    const devicetelemetries = await LastGetTelemetries(deviceid);
    const alarms = await GetActiveAlarms(deviceid);
    var objectotsend ={};
    deviceattributes.data.map(a=> {
        objectotsend[a.key] =a.value;
    })
    return {
        device:deviceinfo.data ,
        attributes:objectotsend,
        telemetries:devicetelemetries.data,
        deviceAlarms : alarms.data.data
    }
}

export async function GetDeviceFullInformation2(deviceid)
{
    const deviceinfo = await GetDevice(deviceid);
    const deviceattributes = await GetDeviceAttributes(deviceid);
    const devicetelemetries = await LastGetTelemetries(deviceid);
    const alarms = await GetActiveAlarms(deviceid);
    var objectotsend ={};
    deviceattributes.data.map(a=> {
        objectotsend[a.key] =a.value;
    })
    return {
        device:deviceinfo.data ,
        attributes:objectotsend,
        telemetries:devicetelemetries.data,
        deviceAlarms : alarms.data.data
    }
}

export async function GetDeviceFullInformationWithObjext(deviceid)
{
    const deviceinfo = deviceid;
    const deviceattributes = await GetDeviceAttributes(deviceinfo.id.id);
    const devicetelemetries = await LastGetTelemetries(deviceinfo.id.id);
    const alarms = await GetActiveAlarms(deviceinfo.id.id);
    var objectotsend ={};
    deviceattributes.data.map(a=> {
        objectotsend[a.key] =a.value;
    })
    return {
        device:deviceinfo ,
        attributes:objectotsend,
        telemetries:devicetelemetries.data,
        deviceAlarms : alarms.data.data
    }
}

export function GetDeviceDetailsAndSome(deviceid)
{

}

export function GetAllAlarmsSecond()
{
    GetUserDevices().then((resp)=>{
        const {data} =resp;
        return data;
    }).catch((error)=>{
        if(error)
        return [];
    })
}

export function GetAllAlarmsThird()
{
    return GetUserDevices();
}


export function ExecuteRPC(command)
{

}

export  function getModel (model){
    if(	model ==='ENVIROMENT1')
        return 'cabinet'
    else
        return 'power'
}