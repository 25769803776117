import React,{useEffect,useState} from 'react';
import { Container } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { GetCustomers } from '../../services/CustomerService';
import CheckIfAuthencicated from '../CheckIfAuthencicated';
import Header from '../Header';
import DataTableExtensions from 'react-data-table-component-extensions';
import { People,Pencil, Display } from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';
import NewCustomer from './NewCustomer';
import EditCustomer from './EditCustomer';
import CustomerGeneralInfo from './CustomerGeneralInfo';

  

export default function CustomerList() {

  const CustomerColums = [
    
    {
      name: 'Created Time',
      selector: 'createdTime',
      sortable: true,
      cell: row=> <div>{JSON.stringify(row.createdTime)} </div>
    },
    {
    name: 'Name',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true
  },
  {
    name: 'Phone Number',
    selector: 'phone',
    sortable: true
  },
  {
    name: '',
    selector: '',
    sortable: true,
    cell: row=>
      <div >

          <CustomerGeneralInfo customerId={row.id.id} customerName={row.name} reloadCustomer={loadCustomer} />       
         {/* <NavLink title="Customer Users" className="btn btn-primary" style={{marginRight:10}}  to={`/users/${row.id.id}/${row.name}`}> <People /></NavLink>      
         <NavLink title="Customer Devices" className="btn btn-primary" style={{marginRight:10}}  to={`/devices/${row.id.id}/${row.name}`}> <Display /></NavLink>      
        */}
    </div>
  }
]

    const [customers, setcustomers] = useState();

    const loadCustomer = ()=>{
      GetCustomers().then((resp)=>{
        const {data} = resp;

        setcustomers(...[data.data]);
    }).catch(errpr=>{

    });
    }
  

    useEffect(() => {
        
        GetCustomers().then((resp)=>{
            const {data} = resp;

            setcustomers(...[data.data]);
        }).catch(errpr=>{

        });
    }, [])

  return (
    <div>
       <CheckIfAuthencicated />
      <Header />
     
   
   <Container > 
   <h3>  i-PoP Customers</h3>
   <NewCustomer />
  
   {
     customers && <DataTableExtensions
     columns={CustomerColums}
     data={customers}
    >
      <DataTable
        noHeader
        defaultSortField="name"
        defaultSortAsc={false}
        pagination
        highlightOnHover
      />
    </DataTableExtensions>
    }
   </Container>
    </div>
  );
}
