import { Badge, Card, Col, Row } from "react-bootstrap";

export const SimpleDisplay = (props) => {
    return (
      <Row>
        <Col>{props.name}</Col>
        <Col>{props.value}</Col>
      </Row>
    );
  };
  
  export const Displays = (props) => {
    const checkRange = () => {
      if (Number(props.value )> Number(props.max) || Number(props.value) < Number(props.min))
        return (
          <Col>
            <Badge title={`${props.max} <  > ${props.min}`} variant="danger">
              {props.value} {props.symbole}
            </Badge>
          </Col>
        );
      else if (Number(props.value )> Number(props.max )* 0.9 || Number(props.value) < Number(props.min) * 0.9)
        return (
          <Col>
            <Badge title={`${props.max} <  > ${props.min}`} variant="warning">
              {props.value} {props.symbole}
            </Badge>
          </Col>
        );
        
      else
        return (
          <Col>
            <Badge title={`${props.max} <  > ${props.min}`} variant="success">
              {props.value} {props.symbole}
            </Badge>{" "}
          </Col>
        );
    };

    const getFullValue = ()=>{
      if(!props.fullValues) return (<Col><Badge variant="success">{props.value} {props.symbole} </Badge></Col>);
      
      var fullvalue = props.fullValues.find(a=> Number(a.short) === Number(props.value));
      if(fullvalue) return (<Col><Badge variant={fullvalue.colour}>{fullvalue.full.toUpperCase() } {props.symbole} </Badge></Col>);
      else return  (<Col><Badge variant="info">{props.value} {props.symbole} </Badge></Col>);;
    }
   
    return (
      <Row>
        <Col onDoubleClick={()=> props.HandleSensorClick(props.name)}>{props.name}</Col>
        {props.isRange && checkRange()}
        {!props.isRange && getFullValue()}
      </Row>
    );
  };
  
  export const InformationBox = (props) => {
    return (
      <>
        <Col  md={props.size} lg={props.size}>
          <Card          
            style={{ margin: 10, borderColor: "#14274e", height: props.height }}
          >
            <Card.Body>
              <Card.Title>{props.title}</Card.Title>
              {props.children}
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  };
  
  