import React,{useState} from 'react';
import { Plus } from 'react-bootstrap-icons';
import QrReader from "react-qr-reader";
import GeneridModal from '../../Ulties/GeneridModal';
import GenericFormElement from '../../Ulties/GenericFormElement'
import { Col, Row } from 'react-bootstrap';
import { ClaimDevice } from '../../../services/DeviceService';
import { NotificationManager } from 'react-notifications';



export default function AddNewDevice() {
    const [calimingDetails, setcalimingDetails] = useState({devicename:"", devicekeys:""})
    
    const [showcamera, setshowcamera] = useState(true);

    const handleModalClosed = ()=>{
        setcalimingDetails({
            devicekeys:"",
            devicename:""
        });
        setshowcamera(false); 
         
        
    }
    const handleScanning = (data) =>{
        
        if(!data)return;

        var details = data.split(',');
        if(details[0] && details[1]);
        {
            setcalimingDetails({
                devicekeys:details[1],
                devicename:details[0]
            })   ;

            setshowcamera(false);
        }
    }

    const HandleclaimDevice = ()=>{
        ClaimDevice(calimingDetails.devicename, calimingDetails.devicekeys).then(response=>{
            NotificationManager.success("Device Add Successfully!");
        }).catch(error=>{
            if(error.data.status ===404)
            NotificationManager.warning("Device does not exist !" );
            if(error.data.status ===400)
            NotificationManager.warning("Failead to add device , please contact your admin" + JSON.stringify(error),{
                
            });
            if(!error.data.status)
            NotificationManager.warning("Could not add the device please contact your admin." + + JSON.stringify(error));
        })
    }
  return (
    <>
       <GeneridModal  modalOpened={()=> setshowcamera(true)}  HandleModalAction={HandleclaimDevice} buttonTitle={"Add"}  modalClosed={()=> handleModalClosed()}  icon={<Plus  />} title={"Add device"}>     
      
       <Row>   
            
            {showcamera &&  <QrReader
              delay={300}
              onScan={handleScanning}
              style={{ width: "60%"}}
            />}  
           
            <Col >
            <GenericFormElement value={calimingDetails.devicename} title={"Device Name"} name={"devicename"} type="string" handleChange={(e)=> setcalimingDetails({...calimingDetails,devicename:e.target.value})} /> 
            <GenericFormElement value={calimingDetails.devicekeys} title={"Activation Code"} name={"devicekeys"} type="string" handleChange={(e)=> setcalimingDetails({...calimingDetails,devicekeys:e.target.value})}/> 
            </Col>
      </Row>
      
         
       </GeneridModal>
    </>
  );
}
