import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { People, Info, Lock } from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import { Link, NavLink, useParams } from "react-router-dom";
import { getUser, GetUsers, updateUser } from "../../services/AuthService";
import CheckIfAuthencicated from "../CheckIfAuthencicated";
import DataTableExtensions from "react-data-table-component-extensions";
import Header from "../Header";
import CreateUser from "./CreateUser";
import GeneridModal from "../Ulties/GeneridModal";
import { getActivationLink } from "../../services/CustomerService";
import { NotificationManager } from "react-notifications";

const UserMenu = (props) => {
  const [activationLink, setactivationLink] = useState(null);
  const [typeselected, settypeselected] = useState("choose...");

  const getLink = (userid) => {

    getUser(props.user)
      .then((response) => settypeselected(response.data.type))
      .catch((error) => settypeselected("choose..."));

    getActivationLink(userid)
      .then((res) => {
        var token = res.data.split("=");

        if (token)
          setactivationLink(
            <Link
              className="btn btn-primary"
              to={`/activateAccount/${token[1]}`}
            >
              Activate
            </Link>
          );
      })
      .catch((error) => {
        setactivationLink(
          <Button variant="danger" disabled>
            Activated
          </Button>
        );
      });
  };

  const onModalAction =()=>{
    if(typeselected ==="choose...")
    alert("Please select a type");  
    else{
      updateUser({
        email:props.user,
        type:typeselected
      }).then(response=> NotificationManager.success(response.data.message))
      .catch(error=> NotificationManager.error(error.message))
    }
  }

  return (
    <GeneridModal
      modalOpened={() => getLink(props.userid)}
      modalClosed={() => console.log("")}
      title={props.user}
      icon={<Info />}
      center={true}
      buttonTitle={"Update"} 
      HandleModalAction = {onModalAction}
    >
      
        <>
        <Row>
        <Col>{activationLink}</Col>
        </Row>
        <Row>          
          <Col>
          <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label> Type</Form.Label>
          <Form.Control as="select" value={typeselected} onChange={(e)=> settypeselected(e.target.value)}>
            <option value="choose...">choose...</option> 
            <option value="Viewer">Viewer</option>  
            <option value="Technician">Technician</option>            
          </Form.Control>
        </Form.Group>
          </Col>
        </Row>
       
       
        
        </>
        
      
    
    </GeneridModal>
  );
};

const UsersColums = [
  {
    name: "Created Time",
    selector: "createdTime",
    sortable: true,
    cell: (row) => <div>{JSON.stringify(row.createdTime)} </div>,
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
  },
  {
    name: "First Name",
    selector: "firstName",
    sortable: true,
  },


  {
    name: "",
    selector: "",
    sortable: true,
    cell: (row) => (
      <div>
        <div>
          <UserMenu user={row.name} userid={row.id.id} />
        </div>
      </div>
    ),
  },
];

export function UserTableList({ customerid }) {
  const [users, setusers] = useState([]);

  const ReloadUsers = () => {
    GetUsers(customerid)
      .then((resp) => {
        const { data } = resp;
        setusers(...[data.data]);
      })
      .catch((error) => {
        if (error) alert(JSON.stringify(error));
      });
  };

  useEffect(() => {
    ReloadUsers();
  }, []);

  return (
    <>
      <div style={{ margin: 10 }}>
        {" "}
        <CreateUser customerId={customerid} userCreated={ReloadUsers} />
      </div>

      {users && (
        <DataTableExtensions columns={UsersColums} data={users}>
          <DataTable
            noHeader
            defaultSortField="email"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      )}
    </>
  );
}

export default function ListUsers() {
  const [users, setusers] = useState([]);

  var { id, customer } = useParams();

  const ReloadUsers = () => {
    GetUsers(id)
      .then((resp) => {
        const { data } = resp;
        setusers(...[data.data]);
      })
      .catch((error) => {
        if (error) alert(JSON.stringify(error));
      });
  };

  useEffect(() => {
    GetUsers(id)
      .then((resp) => {
        const { data } = resp;
        setusers(...[data.data]);
      })
      .catch((error) => {
        if (error) alert(JSON.stringify(error));
      });
  }, []);

  return (
    <div>
      <CheckIfAuthencicated />
      <Header />

      <Container>
        <h3> {customer || ""} i-PoP Users</h3>

        <CreateUser customerId={id} userCreated={ReloadUsers} />
        {users && (
          <DataTableExtensions columns={UsersColums} data={users}>
            <DataTable
              noHeader
              defaultSortField="email"
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        )}
      </Container>
    </div>
  );
}
