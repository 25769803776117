
import React,{useEffect, useState} from 'react';
import { ArrowUpRight, Gear, List } from 'react-bootstrap-icons';
import { NotificationManager } from 'react-notifications';
import HttpService from '../../../services/HttpService';
import { FromWithUpdateButton, FromWithUpdateButtonV2 } from '../../Ulties/GenericFormElement';

import GeneridModal from '../../Ulties/GeneridModal';


export default function CSVSettingsSetup({deviceId,name}) {


const [settingsValues, setsettingsValues] = useState(
  {  
  LimitTimeOut:{value:60, title:"Limit Timout (s)",isString:false , scope:"SERVER_SCOPE"},
  TemperatureHighLimit:{value:10, title:"Top Temperature High",isString:false , scope:"SERVER_SCOPE"},
  TemperatureLowLimit:{value:10, title:"Top Temperature Low",isString:false , scope:"SERVER_SCOPE"},
  TemperatureBottomHighLimit:{value:10, title:"Bottom Temperature High",isString:false , scope:"SERVER_SCOPE"},
  TemperatureBottomLowLimit:{value:10, title:"Bottom Temperature Low",isString:false , scope:"SERVER_SCOPE"},
  HumidityHighLimit:{value:10, title:"Humidity High",isString:false , scope:"SERVER_SCOPE"},
  HumidityLowLimit:{value:10, title:"Humidity Low",isString:false , scope:"SERVER_SCOPE"},
  Humidity1HighLimit:{value:10, title:"Humidity High",isString:false , scope:"SERVER_SCOPE"},
  Humidity1LowLimit:{value:10, title:"Humidity Low",isString:false , scope:"SERVER_SCOPE"},
  UpsEnabled:{value:"yes", title:"Activated (yes/no) ?",isString:true , scope:"SERVER_SCOPE"},
  UpsVoltgeLowLimit:{value:180, title:"UPS Low Voltge Limit",isString:false , scope:"SERVER_SCOPE"},
  UpsBatteryLowLevel:{value:180, title:"UPS Low Battery Limit",isString:false , scope:"SERVER_SCOPE"},
  UpsHighLoadLevelLimit:{value:180, title:"UPS High Load Level Limit",isString:false , scope:"SERVER_SCOPE"},
  MainsVoltageHighLimit:{value:255, title:"Mains Voltage High Limit",isString:false , scope:"SERVER_SCOPE"},
  MainsVoltageLowLimit:{value:180, title:"Mains Voltage Low Limit",isString:false , scope:"SERVER_SCOPE"},
  MainsCurrentHighLimit:{value:28, title:"Mains Current High Limit",isString:false , scope:"SERVER_SCOPE"},
  MainsCurrentLowLimit:{value:1.5, title:"Mains Current Low Limit",isString:false , scope:"SERVER_SCOPE"},
  MainsActivePowerHighLimit:{value:28, title:"Mains Current High Limit",isString:false , scope:"SERVER_SCOPE"},
  MainsActivePowerLowLimit:{value:1.5, title:"Mains Current Low Limit",isString:false , scope:"SERVER_SCOPE"},
  
});

const [settingsValuesDemo, setsettingsValuesDemo] = useState(
  [
    
 
  ]
  
);

const loadAttributes = ()=>{

  var keysvalues = '';
  settingsValuesDemo.forEach(rows => {

    Object.keys(rows.rows).map(values=>  {
      keysvalues = keysvalues + ',' + values;
    })
    
  });
  
//alert(keysvalues.substr(1));
  

  HttpService.get(`/api/plugins/telemetry/DEVICE/${deviceId}/values/attributes?keys=${keysvalues.substr(1)}`)
  .then(res=>{
    const {data} = res;  

    var mySettings = {};
    data.map(a=> {

      var content = a.value;

      if(settingsValues[a.key]?.type==="json")
      {
        try {
          content = JSON.stringify(content);
        } catch (error) {          
          NotificationManager.error(`Failed to parse ${a.key}`);
        }
      }

      mySettings[a.key] = { ...settingsValues[a.key], value:content }
    })
    
    setsettingsValues(set=>({
      ...set,
      ...mySettings
    }));

  }).catch(err=>{
    NotificationManager.error('Failed to load device advance settings');
  })
}
const updateSetting = (key, value,scope, type)=>{

  var valuetoSend = value;

  if(type)
  {
    if(type==="json")
    {
      try {
        valuetoSend = JSON.parse(value);
      } catch (error) {
        NotificationManager.success(`Failed to parse the JSON data`);
      }

    }
  }


    HttpService.post(`/api/plugins/telemetry/DEVICE/${deviceId}/${scope}`,{
      [key]:valuetoSend
    }).then(a=>{
      NotificationManager.success(`The value ${key} was successfully udpated`)
    }).catch(err=>{
      NotificationManager.error(`failed to update the value of ${key}`)
    })
}
const createValue = (value,isString) =>{

  if(isString)return value;
  else
  return Number(value)
}

const handleValueChange = (a,e,index)=>{
  //console.log(`Update ${a}  ${index}`,e.target.value)
  var current = settingsValuesDemo;
  current[index].rows[a].value= e.target.value;
  console.log(current[index].rows[a].value);
  setsettingsValuesDemo([...current])
  //setsettingsValues({...settingsValues,[e.target.name]:{...settingsValues[a],['value']:createValue(e.target.value,settingsValues[a].isString)}})
}

const loadSettingsDefinition = ()=>{
  HttpService.get(`/api/plugins/telemetry/DEVICE/${deviceId}/values/attributes?keys=insettingssettingsDefinition`).then(response=>{

    const {data} = response;

    console.log('got settinfs',data[0].value);
    if(data[0])
    {
      setsettingsValuesDemo([...data[0].value]);
    }

  }).catch(err=>{

  })
}

const [insettingsCSV, setinsettingsCSV] = useState("");

const generateCSV = ()=>{
  var csvValue="";
  var myresult =  settingsValuesDemo.map(items=>{
   

   Object.getOwnPropertyNames(items.rows).map(rowMap=>{
      csvValue+=`${items.rows[rowMap].value},`;
      setinsettingsCSV(csvValue);
    })

    console.log(`Result`,csvValue)

    updateSettingsValues(settingsValuesDemo,csvValue)
  //  items.rows.map(rowMap=>{
  //   csvValue+=`${rowMap.value},`
  //  })
  })
}

const updateSettingsValues = (json,csv)=>{

  HttpService.post(`/api/plugins/telemetry/DEVICE/${deviceId}/SERVER_SCOPE`,{
    insettingssettingsDefinition:json,   
  }).then(a=>{
  
  }).catch(err=>{
    NotificationManager.error(`failed to update the value of settings`)
  })

  HttpService.post(`/api/plugins/telemetry/DEVICE/${deviceId}/SHARED_SCOPE`,{
   
    "insettings":csv
  }).then(a=>{
    NotificationManager.success(`The value settings was successfully udpated`)
  }).catch(err=>{
    NotificationManager.error(`failed to update the value of settings`)
  })

  

}

useEffect(() => {
  loadSettingsDefinition();
 
}, [])

  return (
    <>
      <GeneridModal modalClosed={()=> {}} title="EMB Settings" size="lg" icon={<List />} buttonTitle={"Save"} HandleModalAction= {()=> {generateCSV()}} modalOpened={()=> { loadAttributes();loadSettingsDefinition();}} >
         <div style={{overflow:"scroll", height:500 , overflowX:"hidden", padding:10}}>
          
           { settingsValuesDemo && settingsValuesDemo.map((main,index) => <>
           <h5>{main.title}</h5>
           {main.rows && Object.keys(main.rows).map((a)=>(
                    
                    <> {settingsValuesDemo[a]?.value}  <FromWithUpdateButtonV2 name={a} value={main.rows[a]?.value} readonly={settingsValues[a]?.readonly}   type={settingsValues[a]?.type || "string"}title={`${main.rows[a]?.title}`} handleChange={(e)=>handleValueChange(a,e,index)} /></>
                  ))}   
           </>)}
         
                 
         </div>
     </GeneridModal>
    </>
  );
}
