import httpsevice from './HttpService';
import jwt_decode  from 'jwt-decode';

const loginUrl = '/auth/login'

export const Login= (user,pass)=>{

    var result = httpsevice.post(loginUrl,{
        username:user,
        password:pass
    });
    return result;
}

export const Authenticate = (user, pass)=>{

    return httpsevice.post(loginUrl, {  
        username:user,
        password:pass
    })
}

export const GetUserDetails =() =>{
    var token = localStorage.getItem("token");
    try {
        return jwt_decode(token);
    } catch (error) {
        return null;
    }
   
}

export const GetUserDetailsMS =() =>{
    var token = localStorage.getItem("tokencs");
    try {
        return jwt_decode(token);
    } catch (error) {
        return null;
    }
   
}

export const Logout =() =>{
    var token = localStorage.removeItem("token");   
    localStorage.removeItem("tokencs");     

   ///   httpsevice.post(`/auth/logout`);
}

export const GetUsers =(id) =>{

   // var  details = GetUserDetails();
    
    return httpsevice.get(`/api/customer/${id}/users?pageSize=10000&page=0&sortProperty=createdTime&sortOrder=DESC`)
}

export const getUser = (username)=>{
    return httpsevice.get(`/user/${username}`);
}

export const updateUser = (user)=>{
    return httpsevice.put(`/user/${user.email}`,{type:user.type});
}