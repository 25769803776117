import React, { useState,useEffect } from 'react'
import { Badge, Container } from 'react-bootstrap'
import { Form,Button } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { NotificationManager } from 'react-notifications';
import HttpService from '../../services/HttpService';
import moment from 'moment';
import { InfoCircle,Trash } from 'react-bootstrap-icons';

const InputValue =(props) =>{

    return(<>
     <Form.Group className="mb-3">
    <Form.Label>{props.label}</Form.Label>
    <Form.Control defaultValue={props.value} readOnly={props.readOnly} placeholder={props.label} type={props.type} onChange={e=> props.valueChanged(props.name,e.target.value)}  />
  </Form.Group>
    </>)
}

export default function SubscriptionInfo(props) {
    const [subscriptionInfoData, setsubscriptionInfoData] = useState({      
       
        customerId:props.customerId
    });

    const valueChanged = (n,v)=>{

        console.log(`${n} ${v}`)
        setsubscriptionInfoData(old=>({...old,[n]:v}))
    }

    const createSubscription = ()=>{
      HttpService.post('/subscription',subscriptionInfoData).then(re=>{
        NotificationManager.success('Subscrition Created');
        loadSubscritions();
      }).catch(error=>{
          if(error?.response){
            NotificationManager.success(error?.response.data);
            
          }
          else
          {
           console.log(error)
          }
      })
    }

    const getDaysLeft = (from,endate)=>{

      var now = moment();
      var end = moment(endate); 
      var duration = moment.duration(now.diff(end));

      var daysLeft = duration.asDays().toFixed();

      if(daysLeft>0)
      {
        return <Badge variant='danger'>{daysLeft*-1} days</Badge>
      }
      else{
        return <Badge variant='success'>{daysLeft*-1}  days</Badge>
      }
    }


    const columns=[
      
      {
        sortable: true,
        name: "Name",
        selector: (row) => row?.name,
        cell:(row)=> <><p style={{textAlign:"center"}}>{row?.name}</p></>
      },
      {
        sortable: true,
        name: "Starting date",
        selector: (row) => row?.startingDate,
        cell:(row)=><>{moment(row?.startingDate).format("YYYY-MM-DD")}</>
      },
      {
        sortable: true,
        name: "Ending Date",
        selector: (row) => row?.endingDate,
        cell:(row)=><>{moment(row?.endingDate).format("YYYY-MM-DD")}</>
      },
      {
        sortable: true,
        name: "Days Remaining",
        cell:(row)=><>{getDaysLeft(row?.startingDate,row?.endingDate)} </>
      },
      {
        
        name: "",
        cell:(row)=><><Button style={{margin:5}} size='sm'><InfoCircle /></Button>  <Button onClick={e=>deleteSubscription(row._id)} style={{margin:5}} variant="danger" size='sm'><Trash /></Button>  </>
       
      },
    ]
    
    const loadSubscritions = ()=>{
      HttpService.get(`/subscription/${props.customerId}`).then(a=>{
        const {data} = a;
        setsubscriptionsList([...data]);
      }).catch(err=>{

      })
    }

    const deleteSubscription = (id)=>{
      HttpService.delete(`/subscription/${id}`).then(a=>{
        const {data} = a;
        NotificationManager.success('Subscrition deactivated');
        loadSubscritions();
      }).catch(err=>{

      })
    }

    const [subscriptionsList, setsubscriptionsList] = useState([]);

    useEffect(() => {
      loadSubscritions();
    
      return () => {
        
      }
    }, [])
    

  return (
    <>
    <Container>    
    <DataTable
    title="Subscritions"
          pagination={true}
          paginationPerPage={5}
          columns={columns}
          data={subscriptionsList}       
        />    
       
        <h4>New Subscrition</h4>
        <InputValue label="Customer Id" valueChanged={valueChanged} value={subscriptionInfoData["customerId"]}  name="customerId" readOnly={true}/>
        <InputValue label="Subscription Name" valueChanged={valueChanged} name="name" />
        <InputValue label="Starting Date" type="date" valueChanged={valueChanged} name="startingDate" />
        <InputValue label="Ending Date" type="date" valueChanged={valueChanged} name="endingDate" />
        <Button onClick={createSubscription}>Create</Button>
    </Container>
    </>
  )
}
