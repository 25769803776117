import React,{useState} from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import {
    Key
  } from "react-bootstrap-icons";
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { CreateCredentials, getDeviceCredentials } from '../../../services/DeviceService';
export default function DeviceCredentialsCompoments({deviceid}) {

   

    const handleTokenUpdate = ()=>{
      const r = window.confirm("Do you really want to update the device credentials ,modifiying thid value might cause the device to go go offline ?"); if(r == false){ 
        return;
      }
        if(token.token.length < 5 || token.token.length > 50)
        {
            NotificationManager.warning('The Token length should be more then 6 charactor and less then 50');
        }
        else{
            CreateCredentials(deviceid,`${token.token}`,token.id)
            .then((createdcredentials)=>{
              
              NotificationManager.success('Access Token updated!');
            }).catch((errorcredentials)=>{
              if(errorcredentials)
              {
                  console.log('error', errorcredentials);
                  NotificationManager.warning(errorcredentials.data.message);
              }
              
            })  
        }
        
    }

    const [show, setShow] = useState(false);
    const [token, setToken] = useState({
        id:"",
        token:""
    });
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getDeviceCredentials(deviceid).then((result)=>{
            
            setToken({token:result.data.credentialsId, id:result.data.id.id});
        }).catch((errorreadingtokne)=>{
            alert('failed to open toekn');
        })
    };
    
  return (
    <div>
         <Button variant="btn btn-sm btn-primary" onClick={handleShow}>
       <Key/>
      </Button>

      <Modal  show={show} onHide={handleClose}>
        <Modal.Header closeButton color="primary">
          <Modal.Title>Device Credentials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Col >
                <Form.Group controlId="exampleForm.ControlSelect13">
                  <Form.Label>Token</Form.Label>
                  <Form.Control name={"token"} value={token.token} onChange={(e)=> setToken({...token,[e.target.name]:e.target.value})} placeholder={"Token"} />
                  <Form.Text className="text-muted">
              
              </Form.Text>
                </Form.Group>
              </Col>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={handleTokenUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
  
    </div>
  );
}
