import React, {  useEffect,useState } from "react";
import { Button, Card, Col, Container, Row, Tooltip } from "react-bootstrap";
import ReactMapGL,{Marker as MarkerGl} from 'react-map-gl';
import {
  LayersControl,
  MapContainer,
  
  Popup,
  TileLayer,
} from "react-leaflet";
import Header from "./Header";

import L from "leaflet";
import green from "../icons/greei.png";
import red from "../icons/redi.png";
import MarkerClusterGroup from "react-leaflet-markercluster";
import {
  GetUserDevices,
  GetDeviceAttributes,
  GetActiveAlarms,
  GetAssetAlarm,
} from "../services/DeviceService";
import { Link } from "react-router-dom";
import { ArrowBarDown, ArrowRight, BarChart, Bell, BellFill, Info, List, PieChart, Reception4 } from "react-bootstrap-icons";
import { Pie } from "recharts";
import { GetCustomerAlarm, getCustomerAssetDevices, getCustomerAssets } from "../services/CustomerService";
import { json } from "server/reply";
import moment from "moment";

import MapGL, { Marker } from '@urbica/react-map-gl';
import Cluster from '@urbica/react-map-gl-cluster';
import 'mapbox-gl/dist/mapbox-gl.css';
import { GetAllAlarmsSecond, GetDeviceFullInformation } from "../services/DeviceServicesHelper";
import { useHistory } from 'react-router-dom';

var greenIcon = L.icon({
  iconUrl: green,
  iconSize: [50, 50], // size of the icon
  shadowSize: [50, 64], // size of the shadow
  iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
  shadowAnchor: [4, 62], // the same for the shadow
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});

var redIcon = L.icon({
  iconUrl: red,
  iconSize: [50, 50], // size of the icon
  shadowSize: [50, 64], // size of the shadow
  iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
  shadowAnchor: [4, 62], // the same for the shadow
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});



const Display = ({title, value, ICON})=>{
  return (
  <Card style={{ margin:20,borderWidth: 2, borderColor: "#394867" }}>
  <Card.Body>
    <Card.Title>{title}</Card.Title>
    
    <Card.Text>
      <Row>
        <Col>
        <h1>{value}</h1>
        </Col>
        <Col>
        {ICON}
        <h1></h1>
        </Col>
      </Row>
    
    </Card.Text>
 
  </Card.Body>
</Card>)
}

const Alarms = (prop)=>{
  return (    
    <div style={{padding:5}}>
    <Col style={{ paddingBottom: 10}} >
      <Card style={{ borderWidth: 2,borderColor:"red"}}>
        <Card.Body>
        <Card.Title className="mb-2 text-muted">{prop.name}</Card.Title>
        <Card.Title className="mb-2 text-muted" >{prop.originatorName} <Link style={{color:"black"}} to={`/app/GenericDevice/${prop.originator.id}/home`}><List/></Link></Card.Title>
        <small className="text-muted" title={moment(prop.createdTime).format('yyyy/MM/DD hh:mm:ss')}>Created {moment(prop.createdTime).fromNow()}</small> 
        </Card.Body>
      </Card>
    </Col>
  </div>
  )
}

const style = {
  width: '20px',
  height: '20px',
  color: '#fff',
  background: '#1978c8',
  borderRadius: '20px',
  textAlign: 'center'
};

const style2 = {
  width: '20px',
  height: '20px',
  color: '#fff',
  background: 'green',
  borderRadius: '0px',
  textAlign: 'center'
};


export default function DeviceMap() {
  const [fulldevicesingo, setfulldevicesingo] = useState([]);
  const [devices, setdevices] = useState([]);
  const [assets, setassets] = useState([]);
  const [alarms, setalarms] = useState([]);
  

  const getColourOnissues = (device)=>{
    if(device.attributes.active && device.deviceAlarms.length > 0)
    {
      return "red"
    }
    else if(device.attributes.active && device.deviceAlarms.length == 0)
    {
      return "green"
    }
    else if(!device.attributes.active)
    {
      return "orange"
    }
  }

  const getallAvailableAlarms = (devicelist)=>{
    var getdevices=null;
    
    var activealarms =[];


    for (const item of devicelist) {  
      for (const alamrs of item.deviceAlarms) {  
        activealarms.push(alamrs);
       // console.log('The alarms that are ready ', alamrs);
      }  
      
      ////console.log('Alarms found',alarms);
    }
    
    
    setalarms([...activealarms]);
  }

  const getOnlineDevices = () =>{
    var countactive =0;    
    for (const item of fulldevicesingo) {  
      
      if(!item.attributes.active)
      countactive = countactive +1;
    }  

    
      
return countactive;
  }
const getMydevices = async () => {
    const { data } = await GetUserDevices();
    var mydevicesinfos = [];
    setdevices([...data.data]);
    //setloading(false);
    console.log(data.data);

    [...data.data].map((a)=>(
      GetDeviceFullInformation(a.id.id).then(resp=>{
        mydevicesinfos.push(resp);
        setfulldevicesingo([...mydevicesinfos]);
        getallAvailableAlarms([...mydevicesinfos]);
      ///  console.log("here is the fukk data",resp );
      }).catch((error)=>{
       // console.log("There is an error");
      })
    ));}

    const history = useHistory();
    const handleClick = (id)=> {
      history.push('/app/GenericDevice/'+id + "/home");
    } 

  useEffect(() => {
    getMydevices();
    GetAllAlarmsSecond();
    var interval = setInterval(() => {
       
      getMydevices();
    GetAllAlarmsSecond();
  }, 10000);
 
  return () => {
      clearInterval(interval);
  };
    
  }, []);

  const [viewport, setViewport] = useState({
    width: '100%',
    height: '100%',
    latitude: -25.7577,
    longitude: 28.4376,
    zoom: 8
  });

  const ClusterMarker = ({ longitude, latitude, pointCount }) => (
    <Marker  longitude={longitude} latitude={latitude}>
      <div title={alarms.length} style={{ ...style, background: alarms.length> 0 ? "red" :"blue" }}>{pointCount}</div>
    </Marker>
  );
  

  return (
    <div>
      <Header />
      <h1></h1>
      <Container fluid>
      <Row>
        <Col>
        <Display title={"AVAILABLE DEVICES"} value={devices.length} ICON={<Reception4 color="BLACK"  size={50}/> } />
        </Col>
        <Col>
        <Display title={"DEVICES OFFLINE"} value={getOnlineDevices()} ICON={<Reception4 color="orange "  size={50}/> } />
        </Col>
        <Col>
        <Display title={"ACTIVE ALARMS"} value={alarms.length} ICON={<BellFill color="red"  size={50}/> } />
        </Col>       
      </Row>
      <Container fluid>
        <Row>         
          <Col md={9}>
          <Card style={{ height: "750px",  margin:20,borderWidth: 2, borderColor: "#394867" }}>
          <ReactMapGL
      {...viewport}
      width="100%"
      height="100%"
      mapStyle='mapbox://styles/mapbox/dark-v9'
      onViewportChange={(viewport) => setViewport(viewport)}
      mapboxApiAccessToken={"pk.eyJ1IjoiamFkZW1vdGUiLCJhIjoiY2tpdTE1aDBuMGlyZjMzbXVvYXcya242bCJ9.HQNJONu_BW3EAFYZF7tdHw"}
    >

{fulldevicesingo && fulldevicesingo.map((dev)=>(<MarkerGl alarmcount={10} key={dev.device.id.id}     latitude={Number(dev.attributes?.settings.latitude)} longitude={Number(dev.attributes?.settings.longitude)} > 
    
    <div  onDoubleClick={()=> history.push('/app/GenericDevice/'+ dev.device.id.id + '/home')}  title={`${dev.device.name} : (${dev.deviceAlarms.length }) Alarms` } style={{ ...style2, background: getColourOnissues(dev)}}> </div>
    </MarkerGl>))}
    </ReactMapGL>
          </Card>


          {/* <Card style={{ height: "750px",  margin:20,borderWidth: 2, borderColor: "#394867" }}>
           
          <MapGL
  style={{ width: '100%', height: '750px' }}
  mapStyle='mapbox://styles/mapbox/dark-v9'
  mapbox
  accessToken={'pk.eyJ1IjoiamFkZW1vdGUiLCJhIjoiY2tpdTE1aDBuMGlyZjMzbXVvYXcya242bCJ9.HQNJONu_BW3EAFYZF7tdHw'}
  onViewportChange={nextViewport => setViewport(nextViewport)}
  {...viewport}
>
  <Cluster radius={40} extent={512} nodeSize={64}  component={ClusterMarker}>
    
    {fulldevicesingo && fulldevicesingo.map((dev)=>(<Marker alarmcount={10} key={dev.device.id.id}    latitude={dev.attributes?.settings.latitude} longitude={dev.attributes?.settings.longitude} > 
    
      <div  onDoubleClick={()=> history.push('GenericDevice/'+ dev.device.id.id + '/home')}  title={`${dev.device.name} : (${dev.deviceAlarms.length }) Alarms` } style={{ ...style2, background: getColourOnissues(dev)}}> </div>
      </Marker>))}
      
     
  </Cluster>
</MapGL>
         </Card> */}
          </Col>
          <Col md={3}>
          <div style={{height:750, overflow:"scroll"}}>
         
           {alarms.map((s)=>(<Alarms {...s} />))}  
          {alarms.length === 0 && <h4><Bell size={40} /> NO ALARMS</h4>}
          </div>

          </Col>
        </Row>
        <div>
          
          
        </div>
      </Container>
                
      </Container>
</div>
  );
}

