import React, {useState,useEffect} from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import DateTimeSelector from './DateTimeSelector';
import moment from 'moment';
import { GetTelemetries } from '../../services/DeviceService';
import { ChartHistoryBig } from './GenericDevice/ChartHistory';
import 'react-data-table-component-extensions/dist/index.css';
import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';

const AlarmsColums = [{
  name: 'Date',
  selector: 'ts',
  sortable: true,
  cell: row=> <div>{moment(row.ts).format("DD/MM/yyyy HH:mm")} </div>

},{
  name: 'Value',
  selector: 'value',
  sortable: true
}]
  
export default function SensorHistoryModal(props) {
    const [history, sethistory] = useState(null);
    const ondateTimeSelected =async (from , to) =>{
      const {data} = await GetTelemetries(props.deviceid,props.sensordetails?.sensorName,moment(from).format("x") , moment(to).format("x"));
      console.log(data);
      Object.keys(data).map((a) => 
        {
         
          data[a].map(b=> {
            var temp = b;
            temp.value = Number(temp.value);
  
            return temp;
          }) ;
          
        
        }
      
       
        
        )
      

      

      console.log(data);
      sethistory(data);
     
      return data;
    }
    useEffect(() => {
      sethistory(null);
      return () => {
        sethistory(null);
      };
    }, [props.show])
  
      return (
          <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          
        >
          <Modal.Header style={{backgroundColor:"#14274e", color:"white"}} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">            
            {props.sensordetails?.name?.toUpperCase()} 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>         
            <DateTimeSelector handlerDateSelection={ondateTimeSelected}/>
            <Row>
              <Col>
              {history &&  <ChartHistoryBig name={props.sensordetails?.name}  data={history[props.sensordetails?.sensorName] }  />}
              </Col>
              <Col>
              {  history && <DataTableExtensions
          columns={AlarmsColums}
          data={history[props.sensordetails?.sensorName]}
          title="Telemetries"
          >
            <DataTable
              title="Telemetries"
              noHeader
              defaultSortField="Last Activity"
              defaultSortAsc={false}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5,10,15]}
              highlightOnHover
            />
    </DataTableExtensions>}
              </Col>
            </Row>
           
           
 
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      );
}
