import React,{useState,useEffect} from 'react';
import { Form } from 'react-bootstrap';
import { Plus,Arrow90degDown,Arrow90degLeft, ArrowBarLeft, ArrowBarRight } from 'react-bootstrap-icons';
import { NotificationManager } from 'react-notifications';
import { assignDeviceToCustomer, GetCustomers,unassigneDevice } from '../../../services/CustomerService';
import GeneridModal from '../../Ulties/GeneridModal';



export default function AssigneDevice(props) {
  const deviceid= props.deviceid;
  const [customers, setcustomers] = useState([]);
  const [customerselected, setcustomerselected] = useState("Choose ...");

  const unassigdevice = () =>{
    unassigneDevice(deviceid).then(response=>{
      NotificationManager.success("Device unassigned successfully");
      if(props.successunassign)
      props.success();
    })
    .catch((error)=>{
      NotificationManager.error(JSON.stringify(error));
    })
  }

  const addignedeice = ()=>{

    if(customerselected ==="Choose ...")
      {
        NotificationManager.warning('Please select a customer')
        return;
      }

    assignDeviceToCustomer(customerselected,deviceid)
    .then(response=>{
      NotificationManager.success(JSON.stringify('Device anassigned successfully'));
      if(props.successAssign)
      props.success();
    }).catch(error=>{
      NotificationManager.error(JSON.stringify(error));
    })
  };

  const loadCustomer =()=>{
    GetCustomers()
    .then((response)=>{
      const {data} = response;
      setcustomers([...data.data]);
    }).catch((error)=>{
      if(error)
      alert('Error loading customers');
    })
  }
  useEffect(() => {
   
   

  }, [])

  return (
    <>
    {
      props.customer &&  <GeneridModal  HandleModalAction={unassigdevice} modalClosed={()=> console.log("Modal Closed")} buttonTitle={"Unassign"}  buttonTitle={"Unassign"}   icon={<ArrowBarLeft  />} title={"Unassign Device from customer"}>         
      <p style={{fontSize:20}}>Do you really want to unassign this device from a {props.customer}?</p>
    </GeneridModal>
    }     

      <GeneridModal  modalOpened={loadCustomer}  HandleModalAction={addignedeice}  modalClosed={()=> console.log("Modal Closed")} buttonTitle={"Assign"}   icon={<ArrowBarRight  />} title={"Assign Device to customer"}>         
      <Form.Group controlId="exampleForm.ControlSelect198"  onChange={(e)=> setcustomerselected(e.target.value)} >
    <Form.Label>Please select the customer to assign the device</Form.Label>
    <Form.Control  as="select">
    <option value="Choose ...">Choose ...</option>
     {
     
       customers && customers.map(cs=> (
<option value={cs.id.id}>{cs.title}</option>       ))
     }
    </Form.Control>
  </Form.Group>
      </GeneridModal>
    </>
  );
}
