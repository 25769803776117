import React, { useEffect, useState } from 'react';


import GeneridModal from '../../Ulties/GeneridModal';
import { DisplayFill } from 'react-bootstrap-icons';
import { ExecuteDeviceCommand } from '../../../services/DeviceService';
import { FormControl, Button } from 'react-bootstrap';




export default function RemoteSSH({ Id, sshContent, clearInput }) {

  
  const runCommand = (cmd) => {

    if(cmd==="clear")
    {
      setsshContentOfData("");
      return;
    }
    var command = {};
    command.method = "remoteCommand";
    command.params = {};

    command.params.cmd = cmd;
    command.params.pin = "jadeMotema2021!TigerBrand!9";

    ExecuteDeviceCommand(0, Id, {
      "id": "1",
      "method": "remoteSSHCommand",
      "params": {
        "CMD": cmd,
        "value": 0
      }
    })
      .then(response => {

        const { data } = response;

        const { message } = data;

        if (!message) {
          //alert(JSON.stringify(data));
          return;
        }

        newLineReponse(message);

      }).catch(eror => {
        const { data } = eror;
        if (data)
          newLineReponse(`Error : (${JSON.stringify(data)})`);
        else
          newLineReponse(`Error : (${JSON.stringify(eror)})`);
      })
  }


  const connect = (cmd) => {
    var command = {};
    command.method = "remoteCommand";
    command.params = {};

    command.params.cmd = cmd;
    command.params.pin = "jadeMotema2021!TigerBrand!9";

    ExecuteDeviceCommand(0, Id, {
      "id": "1",
      "method": "remoteSSH",
      "params": {
        "CMD": "",
        "value": 0
      }
    })
      .then(response => {

        const { data } = response;

        const { message } = data;

        if (!message) {
          //alert(JSON.stringify(data));
          return;
        }

        newLineReponse(message);

      }).catch(eror => {
        const { data } = eror;
        if (data)
          newLineReponse(`Error : (${JSON.stringify(data)})`);
        else
          newLineReponse(`Error : (${JSON.stringify(eror)})`);
      })
  }

  const controlplusc = (cmd) => {
    var command = {};
    command.method = "remoteCommand";
    command.params = {};

    command.params.cmd = cmd;
    command.params.pin = "jadeMotema2021!TigerBrand!9";

    ExecuteDeviceCommand(0, Id, {
      "id": "1",
      "method": "remoteSSHCommand",
      "params": {
        "CMD": "\x03",
        "value": 0
      }
    })
      .then(response => {

        const { data } = response;

        const { message } = data;

        if (!message) {
          //alert(JSON.stringify(data));
          return;
        }

        newLineReponse(message);

      }).catch(eror => {
        const { data } = eror;
        if (data)
          newLineReponse(`Error : (${JSON.stringify(data)})`);
        else
          newLineReponse(`Error : (${JSON.stringify(eror)})`);
      })
  }
  // const [terminalLineData, setTerminalLineData] = useState([
  //   {type: LineType.Output, value: 'Welcome to Netshield Smart Controller Session'}    
  // ]);

  const [resultCommand, setresultCommand] = useState("");
  const [inputCommand, setinputCommand] = useState("");

  const newLine = (data) => {

    if (data.length < 2) {
      // setTerminalLineData([...terminalLineData,{type:LineType.Output, value:""}])
      return;

    }

    if (data === "clear") {
      //setTerminalLineData([])
      return;

    }

    //setTerminalLineData([...terminalLineData,{type:LineType.Input, value:data}]);
    runCommand(data);

  }

  const newLineReponse = (data) => {
    //setTerminalLineData([...terminalLineData,{type:LineType.Output, value:data}])

    setresultCommand(data);
  }

  const [sshContentOfData, setsshContentOfData] = useState(sshContent);

  const [linelenght, setlinelenght] = useState(0);

 

  useEffect(() => {


    //setsshContentOfData(sshContent)
    setsshContentOfData(old=>{
      setlinelenght(old.length);
      if(old.length > 2500)
      return "";
      else
      return (old+sshContent);
    })
     // xtermRef.current.terminal.writeln("Hello, World!");

  }, [sshContent]);


  return (
    <>
       
      <GeneridModal modalClosed={() => { }} title="Remote Session" size="xl" icon={<DisplayFill />} HandleModalAction={() => { }} buttonTitle={""} modalOpened={() => { }} >
        <>
       

        </>
        <div className="container">
       
      
         
         
          {/* <Terminal name='Remote Session' prompt="iPoP>" colorMode={ColorMode.Dark} lineData={terminalLineData} onInput={terminalInput => { newLine(terminalInput) }} /> */}


         
          <div style={{ margin: 5 }}>
            <Button variant='warning' style={{ margin: 1 }} onClick={e=> connect()}>Connect</Button>
          
          </div>

          <FormControl onChange={e=> setinputCommand(e.target.value)} style={{ overflow: "auto", height: 600, color: "white", backgroundColor: "black" }} as="textarea" value={sshContentOfData} aria-label="With textarea" />
         
          <div style={{ margin: 5 }}>

            <FormControl onKeyUp={e=>{
              if(e.key==="Enter")
              {
                runCommand(inputCommand) ;setinputCommand("");
              }
            }} onSubmit={(e) => console.log(e)} value={inputCommand} onChange={(e) => setinputCommand(e.target.value)} placeholder="Commands to execute" />
          
            <Button variant='warning' style={{ margin: 5 }} onClick={(e)=> controlplusc()}>Ctr + C</Button>
           
          </div>
        </div>

      </GeneridModal>


    </>
  );
}
