import { Button, Form, Modal } from "react-bootstrap";
import React, { useState } from "react";
import { Plus } from "react-bootstrap-icons";
import GeneridModal from "../Ulties/GeneridModal";
import GenericFormElement from "../Ulties/GenericFormElement";
import { createCustomer } from "../../services/CustomerService";

export default function NewCustomer() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [ErrorMessage, setErrorMessage] = useState({message:"", colour:"green"});

  const [userActivationLink, setuserActivationLink] = useState(null);

  const [customerDetails, setcustomerDetails] = useState({
    name: "",
    description: "",
    address: "",
    phone: "",
    email: "",
  });

  const setValue = (e) =>setcustomerDetails({ ...customerDetails, [e.target.name]: e.target.value });
  
  const HandlerModalClick = ()=> {      
    createCustomer(customerDetails)
    .then(response=>{
        const {data} = response;
        setErrorMessage({message:"Customer created successfully.", colour:"green"});
    })
    .catch(error=> {
        if(error.data.message)
        {
            setErrorMessage(error.data.message);
            setErrorMessage({message:error.data.message, colour:"red"});
        }
    })
  };

  return (
    <div>
      <GeneridModal modalClosed={()=> setErrorMessage({message:"", colour:"green"})} icon={<Plus />} buttonTitle={"Create"} title={"Create Customer"} HandleModalAction={HandlerModalClick}>
          <h3 style={{color:ErrorMessage.colour}}>{ErrorMessage && ErrorMessage.message}</h3>
        <Form>
          <GenericFormElement name="name" value={customerDetails.na} type="string" title="Name" handleChange={setValue}  />
          <GenericFormElement name="description" value={customerDetails.na} type="string" title="Description" handleChange={setValue}  />
          <GenericFormElement name="address" value={customerDetails.na} type="string" title="Address" handleChange={setValue}  />
          <GenericFormElement name="phone" value={customerDetails.na} type="string" title="Phone Number" handleChange={setValue}  />
          <GenericFormElement name="email" value={customerDetails.na} type="string" title="Email" handleChange={setValue}  />     
       
        </Form>
      </GeneridModal>
    </div>
  );
}
