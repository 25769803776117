import  React, {useEffect, useState}   from 'react';
import { Card, Col, Row, Tab, Tabs, Image, Form, Button, Modal } from 'react-bootstrap';
import GenericGauge,{GenericDisplay} from '../GenericGauge';
import ChartHistory,{ChartHistoryBig} from './ChartHistory';
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import moment from 'moment';
import { GetTelemetries, UpdateSettings } from '../../../services/DeviceService';
import DateTimeSelector from '../DateTimeSelector';
import SensorHistoryModal, {} from '../SensorHistoryModal';
import { AlarmsList } from '../AlarmsComponents';
import SettingsComponents, { AdvanceSettingComponents } from '../../SettingsComponents';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import SRU from '../SimplifiedView/SRU';
import GenericUPSDisplay from '../SimplifiedView/GenericUPSDisplay';
const TelemetriesUsed =
"Door,Temperature,Temperature2,Temperature3,Temperature4,Humidity,GEN_Fuel_Level,VBAT,SSI,UPS_BATTERY_LEVEL,UPS_LAOD_LEVEL,UPS_VOLTAGE_LEVEL,FrontDoor,BackDoor,MAINS_VOLTAGE,MAINS_CURRENT,MAINS_FREQUENCY,aircon,waterAircon,Fuel_Level";

const settingslabel = {
  latitude: "",
  longitude: "",
  
  "UPS Voltage High Limit": "",
  "UPS Voltage Low Limit": "",
  "UPS Battery High Limit": "",
  "UPS Battery Low Limit": "",
  "UPS Load Level High Limit": "",
  "UPS Load Level Low Limit": "",
  "Mains Voltage High Limit": "",
  "Mains Voltage Low Limit": "",
  "Mains Current High Limit": "",
  "Mains Current Low Limit": "",
 
};




export  function GenericUPS({telemetrie,settings, deviceid , Alarms , selectedtab}) {
    const [key, setKey] = useState(selectedtab);   
    const [histories,sethistories] = useState(null);
 

    const upateDeviceSettings = async (settings) =>{    
      try{
        const {data} = await UpdateSettings(deviceid,settings);
       
        NotificationManager.success('Device Settings Updated Successfully');
       }
       catch(error)
       {
       // NotificationManager.success('You are not allowed to updated device settings');
       }
    };
    const getTelemetries =async  (from , to)=>{
        const {data} = await GetTelemetries(deviceid,TelemetriesUsed,moment(from).format("x") , moment(to).format("x"));        
        Object.keys(data).map((a) => (data[a] = data[a].reverse()));
        sethistories(data);
        return data;
    }
   
  return (
    <div>
     <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
    >
      <Tab eventKey="home" title="i-PoP Status">
     <GenericUPSDisplay sensors= {telemetrie} deviceId={deviceid} />
      </Tab>
      <Tab eventKey="profile" title="i-PoP History">
      <div>
        <DateTimeSelector handlerDateSelection={getTelemetries} />     
          
              {histories && <Row>
                <Col style={{paddingTop:20}}><ChartHistory name="TEMPERATURE 1" syncidentification={"sync1"} data={histories.Temperature }  /></Col>              
                <Col style={{paddingTop:20}}><ChartHistory name="TEMPERATURE 2" syncidentification={"sync1"} data={histories.Temperature2 }  /></Col>     
                <Col style={{paddingTop:20}}><ChartHistory name="TEMPERATURE 3" syncidentification={"sync1"} data={histories.Temperature3 }  /></Col>              
                <Col style={{paddingTop:20}}><ChartHistory name="TEMPERATURE 4" syncidentification={"sync1"} data={histories.Temperature4 }  /></Col>     
               
                <Col style={{paddingTop:20}}><ChartHistory name="HUMIDITY" syncidentification={"sync1"} data={histories.Humidity }  /></Col>    
                <Col style={{paddingTop:20}}><ChartHistory name="MAINS VOLTAGE" syncidentification={"sync1"} data={histories.MAINS_VOLTAGE }  /></Col>    
                <Col style={{paddingTop:20}}><ChartHistory name="MAINS CURRENT" syncidentification={"sync1"} data={histories.MAINS_CURRENT }  /></Col>    
                <Col style={{paddingTop:20}}><ChartHistory name="Fuel Level" syncidentification={"sync1"} data={histories.GEN_Fuel_Level }  /></Col>  
              </Row>
                  }            
        
          
      </div>
      </Tab>
      <Tab eventKey="Alarms" title="i-PoP Alarms" >
      <AlarmsList  DeviceUniqueId = {deviceid} />
      </Tab>
      <Tab eventKey="contact" title="i-PoP Configuration" >
      <AdvanceSettingComponents
            deviceid={deviceid}
            handleSettingUpdates={upateDeviceSettings}
            attributes={settingslabel}
          />
      
      </Tab>
    </Tabs>
    </div>
  );
}

export default GenericUPS;