import httpsevice  from './HttpService';
import {GetUserDetails} from './AuthService';

export function GetUserDevices()
{
    var result=null;
   
    var getUserDettails = GetUserDetails();

     if( getUserDettails.scopes[0]==="CUSTOMER_USER")
     {
        result = httpsevice.get(`/api/customer/${getUserDettails.customerId}/devices?pageSize=1000&page=0`);
   
     }   
     
     if( getUserDettails.scopes[0]==="TENANT_ADMIN")
     {
        result = httpsevice.get(`/api/tenant/deviceInfos?pageSize=1000&page=0&sortProperty=createdTime&sortOrder=DESC&deviceProfileId=`);
        
  
     }
   
  
     return result;

}

export function GetCustomerDevices(customerid)
{
   return httpsevice.get(`/api/customer/${customerid}/devices?pageSize=1000&page=0`);
   
}

export function GetDeviceDetails(deviceid)
{    
    var getUserDettails = GetUserDetails();
    var result = httpsevice.get(`/api/device/${deviceid}`);
    return result;
}

export function GetDeviceAttributes(deviceid)
{    
    var getUserDettails = GetUserDetails();
    var result = httpsevice.get(`/api/plugins/telemetry/DEVICE/${deviceid}/values/attributes`);
    return result;
}

export function GetDeviceAttributesKey(deviceid,keys)
{    
    var getUserDettails = GetUserDetails();
    var result = httpsevice.get(`/api/plugins/telemetry/DEVICE/${deviceid}/values/attributes?keys=${keys}`);
    return result;
}

export function UpdateSettingsKey(deviceid,myKey, value)
{
    var myObject = {};
    myObject[myKey] = value;

    
    var result = httpsevice.post(`/api/plugins/telemetry/DEVICE/${deviceid}/SERVER_SCOPE`,myObject);
    return result;
}

export function UpdateSettingsKeyShared(deviceid,myKey, value)
{
    var myObject = {};
    myObject[myKey] = value;

    
    var result = httpsevice.post(`/api/plugins/telemetry/DEVICE/${deviceid}/SHARED_SCOPE`,myObject);
    return result;
}


export function GetDevice(deviceid)
{    
    var result = httpsevice.get(`/api/device/${deviceid}`);
    return result;
}

export function GetDeviceAttributesObject(deviceid)
{    
    
    var result = httpsevice.get(`/api/plugins/telemetry/DEVICE/${deviceid}/values/attributes`);    
    return result;
}

export function CreateDevice(deviceid)
{    
    var getUserDettails = GetUserDetails();
    var result = httpsevice.get(`/api/plugins/telemetry/DEVICE/${deviceid}/values/attributes`);
    return result;
}

export function GetTelemetries(deviceid, telemetries, from , to)
{
    var result = httpsevice.get(`api/plugins/telemetry/DEVICE/${deviceid}/values/timeseries?keys=${telemetries}&startTs=${from}&endTs=${to}&interval=120000&limit=10000&&agg=MAX`);
    return result;
}

export function LastGetTelemetries(deviceid)
{
    var result = httpsevice.get(`api/plugins/telemetry/DEVICE/${deviceid}/values/timeseries`);
    return result;
}

export function GetActiveAlarms(deviceid)
{
    var result = httpsevice.get(`api/alarm/DEVICE/${deviceid}?pageSize=10&page=0&sortProperty=createdTime&sortOrder=DESC&startTime=1605181500506&endTime=1805267900506&searchStatus=ACTIVE&fetchOriginator=true`);
    return result;
}

export function GetActiveAlarmsFromRange(deviceid,from , to)
{
    var result = httpsevice.get(`api/alarm/DEVICE/${deviceid}?pageSize=10000&page=0&sortProperty=createdTime&sortOrder=DESC&startTime=${from}&endTime=${to}&searchStatus=ANY&fetchOriginator=true`);
    return result;
}

export function GetAssetAlarm(assetid)
{
   return httpsevice.get(`api/alarm/ASSET/${assetid}?pageSize=10000&page=0&sortProperty=createdTime&sortOrder=DESC&1605181500506&endTime=1805267900506&searchStatus=ANY&fetchOriginator=true`);
  
  }


export function UpdateSettings(deviceid, setting)
{
    var result = httpsevice.post(`v1/api/plugins/telemetry/DEVICE/${deviceid}`,{settings:setting});
    return result;
}


export function CreateDeviceFull(devicename , devicemodel, description)
{
    var getUserDettails = GetUserDetails().customerId;
    var createdevice = httpsevice.post('api/device',{
        name:devicename,
        type:devicemodel,
        label:"",
        additionalInfo: {gateway: false, description: description},   
        customerId: {entityType: "CUSTOMER", id:getUserDettails }
    })
}

export function CreateDeviceServerAttributes(deviceid, settings)
{  
    var attributes = httpsevice.post(`api/plugins/telemetry/${deviceid}/SERVER_SCOPE`,settings);
    return attributes;
}

export function ExecuteDeviceCommand(type,deviceid, command)
{
    if(type ===0)
    {
        var attributes = httpsevice.post(`api/plugins/rpc/oneway/${deviceid}`,command);
        return attributes;
    }
    else{
        var attributes = httpsevice.post(`api/plugins/rpc/twoway/${deviceid}`,command);
        return attributes;
    }
}

export function CreateSettingsAndAttributtes(device)
{    
    var result = httpsevice.post(`api/plugins/telemetry/DEVICE/${device.id}/SHARED_SCOPE`,{settings:device.settings});
    return result;
}

export function NewCreateSettingsAndAttributtes(deviceid, settingsValues)
{    
    var result = httpsevice.post(`/v1/api/plugins/telemetry/DEVICE/${deviceid}`,{settings:settingsValues});
    return result;
}

export function NewCreateSettingsAndAttributtesV2(deviceid, settingsValues)
{  
    
    var result = httpsevice.post(`/api/plugins/telemetry/DEVICE/${deviceid}/SHARED_SCOPE`,{settings:settingsValues});
    return result;
}


export function ph1Settings(deviceid, settingsValues)
{    
    var result = httpsevice.post(`/api/plugins/telemetry/DEVICE/${deviceid}/SHARED_SCOPE`,{insettings:settingsValues});
    return result;
}

export function NewGetSettings(deviceid)
{    
    var result = httpsevice.get(`/v1/api/plugins/telemetry/DEVICE/${deviceid}/settings`);
    return result;
}

export function NewGetISettings(deviceid)
{    
    var result = httpsevice.get(`/api/plugins/telemetry/DEVICE/${deviceid}/values/attributes/SHARED_SCOPE?keys=insettings`);
    return result;
}


export function getDeviceCredentials(deviceid) {
    var result = httpsevice.get(`api/device/${deviceid}/credentials`);
    return result;
}



export function CreateCredentials(deviceid,password,id) {
    var result = httpsevice.post(`api/device/credentials`,{
        id: {id: id},
        createdTime: 0,       
        credentialsType: `ACCESS_TOKEN`,
        credentialsId:`${password}`,
        credentialsValue: null,
        deviceId: {
            entityType: "DEVICE",
            id: deviceid
  }
    });
    return result;
}

export function CreateNetshieldDevice (deviceDetails)
{    
    var createdevice = httpsevice.post(`api/device`,{
        name:deviceDetails.name,
        type:deviceDetails.devicemodel,
        label:"",
        additionalInfo: {gateway: false, description: deviceDetails.description}       
    });
    return createdevice;
}

export function ClaimDevice(devicename, code){
    return httpsevice.post(`/api/customer/device/${devicename}/claim`,{
        "secretKey":code
    });
}