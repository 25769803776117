import React, { useEffect, useState } from 'react'
import { Badge, Col, Container, Form, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import DataTableExtensions from "react-data-table-component-extensions";
import HttpService from '../../services/HttpService';
import Header from '../Header';
import moment from 'moment';
import GeneridModal from '../Ulties/GeneridModal';
import { Box, Camera, InfoCircle, List, Plus, PlusCircle, UpcScan } from 'react-bootstrap-icons';
import GenericFormElement, { GenericDateTimePickser } from '../Ulties/GenericFormElement';
import { MultiSelect } from 'react-multi-select-component';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import mqtt from "mqtt";
import QrReader from 'react-qr-reader';


var mqttGenericHelper = null;

const connectAndSubcstibe =(topic, callback)=>{
  mqttGenericHelper = mqtt.connect("wss://ws.mqtt.dev001.nsiotcloud.com/",{username:"webpp.bio.integrator"});
  mqttGenericHelper.subscribe(topic)
  mqttGenericHelper.on("connect",()=>{console.log("Scanner Helper Connected")})   ;
  mqttGenericHelper.on("disconnect",()=>{console.log("Scanner Helper Disconected to connected")})  ;
  mqttGenericHelper.on("close",()=>{console.log("Scanner Helper Connection to close by me !")})  ;
  mqttGenericHelper.on("message",(top,msg)=>{ 
     if(msg)      
      {
        callback(msg.toString())
      }   
      
  });
}

const Mqttdisconnect = ()=> {
  try {
    mqttGenericHelper.end();
  } catch (error) {    
  }

};

const logsList = [
    { name: "My time", selector: "time", sortable: true,  cell: (row) => (
        <>
          <div> {moment(row.time).format("YYYY-MM-DD HH:mm:ss")} </div>
        </>
      ) },
      { name: "User", selector: "data.userinfo", sortable: true },
    
    { name: "Message", selector: "data", sortable: true,cell: (row) => (
        <>
          <div style={{color:row.data.isOk ? "green":"red"}}> 
          {row.data.message}
          {JSON.stringify(row.data?.doorSatus)}
          </div>
        </>
      ) },
]
const visitorList = [
  { name: "Name", selector: "name", sortable: true },
  { name: "Surname", selector: "surname", sortable: true },
  { name: "Bio Id", selector: "bioId", sortable: true },
  { name: "Id Number", selector: "idNumber", sortable: true },
  
  {
    name: "Access From",
    selector: "accessFrom",
    sortable: true,
    cell: (row) => (
      <>
        <div> {moment(row.accessFrom).format("YYYY-MM-DD HH:mm:ss")} </div>
      </>
    ),
  },
  {
    name: "Access To",
    selector: "accessTo",
    sortable: true,
    cell: (row) => (
      <>
        <div> {moment(row.accessTo).format("YYYY-MM-DD HH:mm:ss")} </div>
      </>
    ),
  },
  { name: "Company", selector: "company", sortable: true },
  {
    name: "Cabinets",
    selector: "cabinets",
    cell: (row) => (
      <>
        <>{row.cabinets.length}</>
      </>
    ),
  },
  {
    name: "Last Activity",
    selector: "lastActivityTime",
    sortable: true,
    cell: (row) => (
      <>
        <div>
          {" "}
          {row.lastActivityTime && moment(row.lastActivityTime).format("YYYY-MM-DD HH:mm:ss")}{" "}
        </div>
      </>
    ),
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    cell: (row) => (
      <>
        <>
        {row.status === "Booked" && <Badge variant="warning">Booked</Badge>}{" "}
          {row.status === "Not Used" && (
            <Badge variant="warning">Registered</Badge>
          )}
          {row.status === "Entered" && <Badge variant="success">Entered</Badge>}{" "}
          {row.status === "Exit" && <Badge variant="danger">Exited</Badge>}
        </>
      </>
    ),
  },
  {
    name: "",
    selector: "",
    sortable: true,
    cell: (row) => (
      <>
        <div> <UpdatorVisitor {...row} /></div>
      </>
    ),
  },
];

const CovidQuestions = (props)=>{
    const [ischeck, setischeck] = useState(false);
    
    const UpdateCovidQeustions = (status)=>{
        if(props.update)        
        props.update({title:props.title, answer:status ? "Yes":"No",isCheck:props.isCheck})
    }

    const UpdateCovidQeustionsValue = (status)=>{
        if(props.update)        
        props.update({title:props.title, answer:status,isCheck:props.isCheck})
    }
    return (<>
    <Row>
      
        <Col> {props.title} </Col>
        <Col>
        {props.isCheck && 
        <>
        <Form.Check 
        name={`${props.title}-er`}
        inline
        type={"radio"}        
        label={"Yes"}           
        onChange={e=>{UpdateCovidQeustions(true)}}
      />
        <Form.Check 
         name={`${props.title}-er`}
        inline
        type={"radio"}        
        label={"No"}
        
        onChange={e=>{UpdateCovidQeustions(false)}}
      /></>
        }
      {!props.isCheck && <Form.Control 
        
        type={"number"}
        step={0.1}
        max={45}
        min={30}
        style={{width:80}}
        label=""           
        onChange={e=>{UpdateCovidQeustionsValue(e.currentTarget.value)}}
      />}
        </Col>
    </Row>
    </>)
}

const AddNewVisitors = (props)=>{
    const [visitorInfo, setvisitorInfo] = useState({
        "name":"",
        "surname":"",
        "idNumber":"",
        "company":"",
        "bioId":"",
        "accessFrom":0,
        "accessTo":0,        
        "rowId":"1",
        accessFrom:"",
        accessTo:"",
        reason:""
    })

    const [doors, setdoors] = useState([
        {label: "Cabinet 001", value: "Cabinet 001"},
        {label: "Cabinet 002", value: "Cabinet 002"},
        {label: "Cabinet 003", value: "Cabinet 003"},
        {label: "Cabinet 004", value: "Cabinet 004"},
        {label: "Cabinet 005", value: "Cabinet 005"},
        {label: "Cabinet 006", value: "Cabinet 006"},
        {label: "Cabinet 007", value: "Cabinet 007"}
      ]);
      const [selectedDoors, setselectedDoors] = useState([])
   

const [covidAnswers, setcovidAnswers] = useState({
    question_0: { title: "Temperature ?", answer: "0" },
      question_1: { title: "Dry cough ?", answer: "No",isCheck:true , type:"numeric" },
      question_2: { title: "Feever ?", answer: "No",isCheck:true },
      question_3: { title: "Shortness of breath", answer: "No",isCheck:true},
      question_4: { title: "Loss of taste (ageusia)?", answer: "No",isCheck:true },
    });

  const setValue = (e) =>setvisitorInfo({ ...visitorInfo, [e.target.name]: e.target.value });
  const setValueInfo = (e) =>{
      console.log(e)
  };

  const converConvidAnswers = ()=>{
     
      var talbel = Object.keys(covidAnswers).map(a=>{return {question:covidAnswers[a].title,answer:covidAnswers[a].answer}});
      return talbel;
  }

  const createNewVisitor =()=>{
      HttpService.post('/doorLockManager/visitors',{
          ...visitorInfo,
          cabinets:selectedDoors.map(a=>{ return {name:a.label}}),
          covidsQuestions:converConvidAnswers()
      }).then(respomse=>{
          const {data} = respomse;
          NotificationManager.success(`Visitor ${visitorInfo.name} ${visitorInfo.surname} created successfully!`);
          if(props.reload)
            props.reload();
      }).catch(errre=>{
          const {data} = errre;
         if(data?.message)
         NotificationManager.error(`Failed to create Visitor ${data.message}`);
              })
  }

    return (<>
         <GeneridModal modalClosed={()=> {Mqttdisconnect()}} title="New Visitors" size="xl" icon={<Plus />} buttonTitle={"Create"} HandleModalAction= {()=> {createNewVisitor()}} modalOpened={()=> {connectAndSubcstibe("/vsit/1/1",(a)=>  setvisitorInfo(old=> ({...old,bioId:a}))) }} >
         <div >
         
           <Row>
               <Col lg={5}>
                   <h4>Personal Information</h4>
               <GenericFormElement name="name" type="string" value={visitorInfo.name} title="Name"  handleChange={setValue}  />
               <GenericFormElement name="surname" type="string" value={visitorInfo.surname} title="Surname"  handleChange={setValue}  />
               <GenericFormElement name="idNumber" type="string" value={visitorInfo.idNumber} title="ID Number"  handleChange={setValue}  />
               <GenericFormElement name="company" type="string" value={visitorInfo.company} title="Company"  handleChange={setValue}  />
               <GenericFormElement as="textarea" name="reason" type="string" value={visitorInfo.reason} title="Reason For the Visit"  handleChange={setValue}  />
               <GenericFormElement name="bioId" type="string" value={visitorInfo.bioId} title="Bio Id"  handleChange={setValue}  />
              <GenericDateTimePickser name="accessFrom"  value={visitorInfo.accessFrom} title="From Time"  handleChange={(e)=>{
                  setvisitorInfo({ ...visitorInfo, ["accessFrom"]: e })
              }}/>
              <GenericDateTimePickser name="accessTo" value={visitorInfo.accessTo} title="To Time"  handleChange={(e)=>{
                  setvisitorInfo({ ...visitorInfo, ["accessTo"]: e })
              }}/>
               </Col>
               <Col lg={7}>
               <h4>Cabinets  Accesss</h4>
               <MultiSelect
                    options={doors}
                    value={selectedDoors}
                    onChange={setselectedDoors}
                    labelledBy="Select"
                />
               <h4>Covid Questions</h4>

               {covidAnswers && Object.keys(covidAnswers).map(a=> (<CovidQuestions {...covidAnswers[a]}   update={(e)=> setcovidAnswers({...covidAnswers,[a]:e})}  />))}
               
               </Col>
           </Row>
           
         
                 
         </div>
     </GeneridModal>
    </>)
}

const CameraLoader = (props)=>{


  const [dataFound, setdataFound] = useState(false);

  const [visitorInfo, setvisitorInfo] = useState({})

const [covidAnswers, setcovidAnswers] = useState({
  question_0: { title: "Temperature ?", answer: "0" },
    question_1: { title: "Dry cough ?", answer: "No",isCheck:true , type:"numeric" },
    question_2: { title: "Feever ?", answer: "No",isCheck:true },
    question_3: { title: "Shortness of breath", answer: "No",isCheck:true},
    question_4: { title: "Loss of taste (ageusia)?", answer: "No",isCheck:true },
  });

const setValue = (e) =>setvisitorInfo({ ...visitorInfo, [e.target.name]: e.target.value });

const initClear= () =>
{
  setvisitorInfo({});
  setisItBooking(false);
  setdataFound(false);
  setdataFound(true);
}

const converConvidAnswers = ()=>{
   
    var talbel = Object.keys(covidAnswers).map(a=>{return {question:covidAnswers[a].title,answer:covidAnswers[a].answer}});
    return talbel;
}
const [isItABooking, setisItBooking] = useState(false);

const getBookingInfo = (data)=>{
  
 
  var query = data || '618cfa229079f233a2aafd33'
  HttpService.get(`/doorLockManager/visitors/${query}`).then(a=>{
    const {data} =a;
   
    setvisitorInfo(data.message);
    
    setisItBooking(data.message?.status==="Booked")
  
    setdataFound(true)
  }).catch(err=>{
    NotificationManager.error(`Booking code not found , please make sure the booking code is valide.`);
  })
}

const udpateVisitorData = ()=>{
HttpService.post('/doorLockManager/visitors/times',{
  "accessFrom":visitorInfo.accessFrom,
 "accessTo":visitorInfo.accessTo,
 "id":visitorInfo._id,
 "bioId":visitorInfo.bioId,
 "covidsQuestions":converConvidAnswers(),
 reason:visitorInfo.reason
}).then(res=>{
  NotificationManager.success(`Visitor ${visitorInfo.name} ${visitorInfo.surname} Details updated successfully.`);

  if(props.updateUI)
  props.updateUI();

}).catch(era=>{
  const {data} = era;
       if(data?.message)
  NotificationManager.error(`Failed to udpated Visitor ${visitorInfo.name} ${visitorInfo.surname} Details. ${data?.message}`);
})


}


  return (<>
       <GeneridModal modalClosed={()=> {initClear(); Mqttdisconnect()}} title="Visitors Info" size="xl" icon={<UpcScan />} buttonTitle={dataFound ? "Update":""} HandleModalAction= {()=> {udpateVisitorData()}} modalOpened={()=> { setdataFound(false);connectAndSubcstibe("/vsit/1/1",(a)=>  setvisitorInfo(old=> ({...old,bioId:a})))}} >
       <div >
         {/* {JSON.stringify(covidAnswers)} */}
         <Row>
           {!dataFound && <div style={{textAlign:"center",padding:1,width: "100%"}}> 
           
           <QrReader
            delay={300}
             onError={(e)=> alert(e)}
              onScan={(e)=>{
                if(e)
                getBookingInfo(e)
              }}
              
            /></div>}
           
        
          
           {dataFound && <> <Col lg={5}>
                 <h4>Personal Information</h4>
             <GenericFormElement disabled={true} name="name" type="string" value={visitorInfo.name} title="Name"  handleChange={setValue}  />
             <GenericFormElement disabled={true} name="surname" type="string" value={visitorInfo.surname} title="Surname"  handleChange={setValue}  />
             <GenericFormElement disabled={true} name="idNumber" type="string" value={visitorInfo.idNumber} title="ID Number"  handleChange={setValue}  />
             <GenericFormElement as="textarea" name="reason" type="string" value={visitorInfo.reason} title="Reason For the Visit"  handleChange={setValue}  />
             <GenericFormElement disabled={true} name="company" type="string" value={visitorInfo.company} title="Company"  handleChange={setValue}  />
             <GenericFormElement  disabled={!isItABooking} name="bioId" type="string" value={visitorInfo.bioId} title="Bio Id"  handleChange={setValue}  />
            <GenericDateTimePickser name="accessFrom"  value={visitorInfo.accessFrom} title="From Time"  handleChange={(e)=>{
                setvisitorInfo({ ...visitorInfo, ["accessFrom"]: e })
            }}/>
            <GenericDateTimePickser name="accessTo" value={visitorInfo.accessTo} title="To Time"  handleChange={(e)=>{
                setvisitorInfo({ ...visitorInfo, ["accessTo"]: e })
            }}/>
             </Col>
             <Col lg={7}>
             <h4>Cabinets  Accesss</h4>
            {visitorInfo.cabinets && visitorInfo.cabinets.map(a=> (<><Box /> {a.name} <br/></>))}
             <h4>Covid Questions</h4>
              
             {isItABooking && covidAnswers && Object.keys(covidAnswers).map(a=> (<CovidQuestions {...covidAnswers[a]}   update={(e)=> setcovidAnswers({...covidAnswers,[a]:e})}  />))}

             {!isItABooking  && visitorInfo.covidsQuestions && visitorInfo.covidsQuestions.map(a=>(<Row>
                 <Col>
                 {a.question}
                 </Col>
                 <Col>
                 {a.answer}
                 </Col>
             </Row>))}
             
             </Col></>}
            
         </Row>
         
       
               
       </div>
   </GeneridModal>
  </>)
}

const UpdatorVisitor = (props)=>{
    const [visitorInfo, setvisitorInfo] = useState({
        ...props
      
    })

    const [doors, setdoors] = useState([
        {label: "Cabinet 001", value: "Cabinet 001"},
        {label: "Cabinet 002", value: "Cabinet 002"},
        {label: "Cabinet 003", value: "Cabinet 003"},
        {label: "Cabinet 004", value: "Cabinet 004"},
        {label: "Cabinet 005", value: "Cabinet 005"},
        {label: "Cabinet 006", value: "Cabinet 006"},
        {label: "Cabinet 007", value: "Cabinet 007"}
      ]);
      const [selectedDoors, setselectedDoors] = useState([])
   

const [covidAnswers, setcovidAnswers] = useState({
    question_0: { title: "Temperature ?", answer: "0" },
      question_1: { title: "Dry cough ?", answer: "No",isCheck:true , type:"numeric" },
      question_2: { title: "Feever ?", answer: "No",isCheck:true },
      question_3: { title: "Shortness of breath", answer: "No",isCheck:true},
      question_4: { title: "Loss of taste (ageusia)?", answer: "No",isCheck:true },
    });

  const setValue = (e) =>setvisitorInfo({ ...visitorInfo, [e.target.name]: e.target.value });
  const setValueInfo = (e) =>{
      console.log(e)
  };

  const converConvidAnswers = ()=>{
     
      var talbel = Object.keys(covidAnswers).map(a=>{return {question:covidAnswers[a].title,answer:covidAnswers[a].answer}});
      return talbel;
  }

  const isItABooking =  props.status==="Booked";

const udpateVisitorData = ()=>{
  HttpService.post('/doorLockManager/visitors/times',{
    "accessFrom":visitorInfo.accessFrom,
   "accessTo":visitorInfo.accessTo,
   "id":visitorInfo._id,
   "bioId":visitorInfo.bioId,
   "covidsQuestions":converConvidAnswers()
  }).then(res=>{
    NotificationManager.success(`Visitor ${visitorInfo.name} ${visitorInfo.surname} Details updated successfully.`);

    if(props.updateUI)
    props.updateUI();

  }).catch(era=>{
    const {data} = era;
         if(data?.message)
    NotificationManager.error(`Failed to udpated Visitor ${visitorInfo.name} ${visitorInfo.surname} Details. ${data?.message}`);
  })

 
}



    return (<>
         <GeneridModal modalClosed={()=> {Mqttdisconnect();}} title="Visitors Info" size="xl" icon={<InfoCircle />} buttonTitle={"Update"} HandleModalAction= {()=> {udpateVisitorData()}} modalOpened={()=> { connectAndSubcstibe("/vsit/1/1",(a)=>{
    setvisitorInfo(old=>({...old,bioId:a}))
  }) }} >
         <div >
           {/* {JSON.stringify(covidAnswers)} */}
           <Row>
               <Col lg={5}>
                   <h4>Personal Information</h4>
               <GenericFormElement disabled={true} name="name" type="string" value={visitorInfo.name} title="Name"  handleChange={setValue}  />
               <GenericFormElement disabled={true} name="surname" type="string" value={visitorInfo.surname} title="Surname"  handleChange={setValue}  />
               <GenericFormElement disabled={true} name="idNumber" type="string" value={visitorInfo.idNumber} title="ID Number"  handleChange={setValue}  />
               <GenericFormElement disabled={true} name="company" type="string" value={visitorInfo.company} title="Company"  handleChange={setValue}  />
               <GenericFormElement  disabled={!isItABooking} as="textarea" name="reason" type="string" value={visitorInfo.reason} title="Reason For the Visit"  handleChange={setValue}  />
          
               <GenericFormElement  disabled={!isItABooking} name="bioId" type="string" value={visitorInfo.bioId} title="Bio Id"  handleChange={setValue}  />
              <GenericDateTimePickser name="accessFrom"  value={visitorInfo.accessFrom} title="From Time"  handleChange={(e)=>{
                  setvisitorInfo({ ...visitorInfo, ["accessFrom"]: e })
              }}/>
              <GenericDateTimePickser name="accessTo" value={visitorInfo.accessTo} title="To Time"  handleChange={(e)=>{
                  setvisitorInfo({ ...visitorInfo, ["accessTo"]: e })
              }}/>
               </Col>
               <Col lg={7}>
               <h4>Cabinets  Accesss</h4>
              {props.cabinets && props.cabinets.map(a=> (<><Box /> {a.name} <br/></>))}
               <h4>Covid Questions</h4>
                
               {isItABooking && covidAnswers && Object.keys(covidAnswers).map(a=> (<CovidQuestions {...covidAnswers[a]}   update={(e)=> setcovidAnswers({...covidAnswers,[a]:e})}  />))}

               {!isItABooking  && props.covidsQuestions && props.covidsQuestions.map(a=>(<Row>
                   <Col>
                   {a.question}
                   </Col>
                   <Col>
                   {a.answer}
                   </Col>
               </Row>))}
               
               </Col>
           </Row>
           
         
                 
         </div>
     </GeneridModal>
    </>)
}

export default function VisitorsList() {

    const [visitors, setvisitors] = useState([]);

    const [messages, setmessages] = useState([])

    const reloadVisitors = ()=>{
        HttpService.get('/doorLockManager/visitors').then(res=>{
            const {data} = res;
            setvisitors([...data.message]);        
        }).catch(err=>{
            setvisitors([]);
        })
    }

    useEffect(() => {
      reloadVisitors();
        var refresher =  setInterval(() => {
          reloadVisitors();
        }, 5000);  
        var mqttClient = mqtt.connect("wss://ws.mqtt.dev001.nsiotcloud.com/",{username:"webpp.bio.integrator"});
        mqttClient.subscribe("/nsbiometric/feedback")
        mqttClient.on("connect",()=>{console.log("Connected to mqtt server")})   ;
        mqttClient.on("disconnect",()=>{console.log("Disconected to connected")})  ;
        mqttClient.on("close",()=>{console.log("Connection to close by me !")})  ;
        mqttClient.on("message",(top,msg)=>{   
            console.log('Message received',msg.toString())
            if(msg)      
            {
              reloadVisitors();
              setmessages(a=> [...a,{data:JSON.parse(msg.toString()), time:Date.now()}])
            }   
            
        })
        return () => {
            mqttClient.end();    
            clearInterval(refresher);
        }
    }, [])

    return (
        <>
             <Header  />
             <Row>
             <Container fluid >
                <div style={{margin:20}}>
                <h3> Visitors Mangement</h3>
                <AddNewVisitors reload={reloadVisitors}/>
                <CameraLoader />
                <DataTableExtensions columns={visitorList} data={visitors}>
                <DataTable
                  noHeader
                  defaultSortField="active"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
              
             
            
             <h3>Events Logs</h3>
             <DataTableExtensions columns={logsList} data={messages}>
                <DataTable
                  noHeader
                  defaultSortField="time"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            
              
                </div>
            
             
          </Container>
             </Row>
        </>
    )
}
