import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis,Tooltip } from 'recharts';
import moment from 'moment';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && label) {
    return (
      
      <div style={{ background: "#9ba4b4" }}>
        <p className="label">{`${moment(label).format("DD/MM/yyyy HH:mm")} : ${
          payload && payload[0]?.value
        }`}</p>
      </div>
    );
  }

  return null;
};

export default function ChartHistory ({data, syncidentification, name}) {
    return <div style={{width:380, height:350, padding:5,}}>
      <h5 className="mb-2  card-subtitle h6" style={{textAlign:"center", color:"#394867"}}>{name.toUpperCase()}</h5>
        <LineChart
         syncId={syncidentification}
        width={500}
        height={350}
        data={data}
        
        margin={{
          top: 0, right: 0, left: 10, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="ts"   tickFormatter={(timeStr) =>
                  moment(timeStr).format("DD/MM/yyyy HH:mm")
                } />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
       
        <Line type="monotone" dataKey="value" stroke="#14274e" activeDot={{ r: 8 }} />
       
      </LineChart>
    </div>;
}


export  function ChartHistoryBig ({data, syncidentification, name}) {
  return <div style={{width:1000, height:300, padding:2}}>
    <h5 className="mb-2 text-muted card-subtitle h6">{name}</h5>
      <LineChart
       syncId={syncidentification}
      width={1000}
      height={300}
      data={data}
      margin={{
        top: 5, right: 30, left: 20, bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="ts"   tickFormatter={(timeStr) =>
                moment(timeStr).format("DD/MM/yyyy HH:mm")
              } />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      <Legend />
      <Line type="monotone" dataKey="value" stroke="#14274e" activeDot={{ r: 8 }} />
     
    </LineChart>
  </div>;
}


