import React, { useEffect, useState } from "react";
//import Modal from 'react-modal';

import {
  Badge,
  Card,
  Col,
  Row,
  Image,
  Tabs,
  Tab,
  Tooltip,
  Alert,
  Button,
} from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import { useParams } from "react-router-dom";
import {
  CreateSettingsAndAttributtes,
  GetActiveAlarms,
  GetDevice,
  GetDeviceAttributes,
  NewGetSettings,
  UpdateSettings,
} from "../../../services/DeviceService";
import {
  GetDeviceFullInformation,
  getModel,
} from "../../../services/DeviceServicesHelper";
import PowerMonitorBatteryMonitor from "./PowerMonitorBatteryMonitor";
import GaugeChart from "react-gauge-chart";
import { RadialGauge } from "react-canvas-gauges";
import Header from "../../Header";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import SmartCabinet from "./SmartCabinet";
import PowerMonitor from "./PowerMonitor";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { AlarmsListComponents } from "../AlarmsComponents";
import moment from "moment";
import SensorHistoryModal from "../SensorHistoryModal";
import CommandCompoments, {
  SetDeviceConfigurationParameter,
} from "./CommandCompoments";
import DeviceCredentialsCompoments from "./DeviceCredentialsCompoments";
import SRU from "./SRU";
import { GetUserDetails } from "../../../services/AuthService";
import CheckIfAuthencicated from "../../CheckIfAuthencicated";
import SimpleTracker from "./SimpleTracker";
import { w3cwebsocket } from "websocket";
import ProvisionOption from "../Refactored/ProvisionOption";
import DisplayOnUserType from "../../Ulties/DisplayOnUserType";
import GenericUPS from "./GenericUPS";
import RemoteDiagnostic from "./RemoteDiagnostic";
import DeviceAdvanceSettings from "../Refactored/DeviceAdvanceSettings";
import ConfigurationManager from "./ConfigurationManager";
import DashboardEditor from "../Refactored/DashboardEditor";
import DeviceNotes from "../Refactored/DeviceNotes";
import RemoteSSH from "../Refactored/RemoteSSH";
import DuplicateSettings from "../Refactored/DuplicateSettings";
import DeviceTresholdsSettings from "../Refactored/DeviceTresholdsSettings";
import GenericDevice from "./GenericDevice";
import GenericMultipleCommands from "../Refactored/GenericMultipleCommands";
import DoorsControll from "./DoorsControll";
import RemoteSSHNew from "../Refactored/RemoteSSHNew";
import CSVSettingsSetup from "../Refactored/CSVSettingsSetup";

const envSettings = window;

const settings = [{ key: "active", type: "" }];

const DeviceInformation = ({
  device,
  attributes,
  sshContent,
  setsshContent,
}) => {
  var getusertype = GetUserDetails().scopes[0];

  return (
    <Card
      style={{
        borderWidth: 2,
        background: !attributes.active ? "rgba(255, 0, 0, 0.7)" : "white",
        borderColor: "#394867",
      }}
      className="text center"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Image src={`/images/${device.type}.png`} roundedCircle /> */}
      </div>
      <Card.Body>
        <Card.Title
          style={{ overflow: "hidden" }}
          title={device.name}
        ></Card.Title>
        <div>
          <h4>i-PoP INFO</h4>
          <Row style={{ paddingTop: 20 }}>
            <Col>
              <strong> Device Name</strong>
            </Col>
            <Col>{device.name}</Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Col>
              <strong> Device Description</strong>
            </Col>
            <Col>{attributes?.devicePersonalName || ""}</Col>
          </Row>
        </div>
        <div>
          <Row style={{ paddingTop: 20 }}>
            <Col>
              <strong>Status</strong>
            </Col>
            <Col>
              {attributes.active ? (
                <Badge variant="success">Active</Badge>
              ) : (
                <Badge variant="danger">Offline</Badge>
              )}
            </Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Col>
              <strong>Last Conenction</strong>
            </Col>
            <Col
              title={moment(attributes.lastActivityTime).format(
                "DD/MM/YYYY hh:mm:ss"
              )}
            >
              {moment(attributes.lastActivityTime).fromNow()}
            </Col>
          </Row>
          {
            <Row style={{ paddingTop: 20 }}>
              <Col>
                <strong>Commands</strong>
              </Col>
              <Col>
                {" "}
                {attributes.commands && (
                  <CommandCompoments
                    devicename={device.name}
                    deviceid={device.id.id}
                    rcpTimeout={attributes.rcpTimeout}
                    commandsAvailable={attributes.commands}
                  />
                )}
              </Col>
            </Row>
          }

          <Row style={{ paddingTop: 20 }}>
            <Col>
              <strong>Config</strong>
            </Col>
            <Col>
              {" "}
              {attributes.commands && <SetDeviceConfigurationParameter settings={[{title:"Temperature High", value:30 }]} />}
            </Col>
          </Row>

          <Row style={{ paddingTop: 20 }}>
            <Col>
              <strong>Notes</strong>
            </Col>
            <Col>
              {" "}
              <DeviceNotes Id={device.id.id} name={device.name} />
            </Col>
          </Row>

          {["PH2ENVMON"].find((a) => a === device.type) && (
            <>
              <DisplayOnUserType
                typeToCheck="TENANT_ADMIN"
                checkAlso="Technician"
              >
                <Row style={{ paddingTop: 20 }}>
                  <Col>
                    <strong>EMB Settings</strong>
                  </Col>
                  <Col> {<CSVSettingsSetup deviceId={device.id.id} />}</Col>
                </Row>

                <Row style={{ paddingTop: 20 }}>
                  <Col>
                    <strong>Tresholds & Settings</strong>
                  </Col>
                  <Col>
                    {" "}
                    {<DeviceTresholdsSettings deviceId={device.id.id} />}
                  </Col>
                </Row>
              </DisplayOnUserType>
            </>
          )}

          {/* <Row style={{ paddingTop: 20 }}>
            <Col>
              <strong>Tresholds & Settings</strong>
            </Col>
            <Col> {<  DeviceTresholdsSettings   deviceId={device.id.id}  />}</Col>
          </Row> */}

          <DisplayOnUserType typeToCheck="TENANT_ADMIN"></DisplayOnUserType>

          <Row style={{ paddingTop: 20 }}>
            <Col>
              <strong>Configuration & Settings</strong>
            </Col>
            <Col> {<DeviceTresholdsSettings deviceId={device.id.id} />}</Col>
          </Row>

          <DisplayOnUserType typeToCheck="TENANT_ADMIN">
            <Row style={{ paddingTop: 20 }}>
              <Col>
                <strong>Dashboard Editor</strong>
              </Col>
              <Col>
                {" "}
                <DashboardEditor deviceId={device.id.id} name={device.name} />
              </Col>
            </Row>
          </DisplayOnUserType>

          <DisplayOnUserType typeToCheck="TENANT_ADMIN">
            <Row style={{ paddingTop: 20 }}>
              <Col>
                <strong>Provision</strong>
              </Col>
              <Col>
                {" "}
                <ProvisionOption deviceId={device.id.id} name={device.name} />
              </Col>
            </Row>
          </DisplayOnUserType>

          <DisplayOnUserType typeToCheck="TENANT_ADMIN">
            <Row style={{ paddingTop: 20 }}>
              <Col>
                <strong>Duplicate Settings</strong>
              </Col>
              <Col>
                {" "}
                <DuplicateSettings deviceId={device.id.id} name={device.name} />
              </Col>
            </Row>

            <Row style={{ paddingTop: 20 }}>
              <Col>
                <strong>Duplicate Generic Settings</strong>
              </Col>
              <Col>
                {" "}
                <GenericMultipleCommands
                  deviceId={device.id.id}
                  name={device.name}
                />
              </Col>
            </Row>
          </DisplayOnUserType>

          <DisplayOnUserType typeToCheck="TENANT_ADMIN">
            <Row style={{ paddingTop: 20 }}>
              <Col>
                <strong>Device Credentials</strong>
              </Col>
              <Col>
                {" "}
                {<DeviceCredentialsCompoments deviceid={device.id.id} />}
              </Col>
            </Row>
          </DisplayOnUserType>

          <DisplayOnUserType typeToCheck="TENANT_ADMIN">
            <Row style={{ paddingTop: 20 }}>
              <Col>
                <strong>Advance Settings</strong>
              </Col>
              <Col> {<DeviceAdvanceSettings deviceId={device.id.id} />}</Col>
            </Row>
          </DisplayOnUserType>
          <DisplayOnUserType typeToCheck="TENANT_ADMIN">
            <Row style={{ paddingTop: 20 }}>
              <Col>
                <strong>Configuration Manager</strong>
              </Col>
              <Col> {<ConfigurationManager deviceId={device.id.id} />}</Col>
            </Row>
          </DisplayOnUserType>

          <DisplayOnUserType typeToCheck="TENANT_ADMIN">
            <Row style={{ paddingTop: 20 }}>
              <Col>
                <strong>Remote Session </strong>
              </Col>
              <Col>
                {" "}
                {
                  <RemoteSSH
                    Id={device.id.id}
                    sshContent={sshContent}
                    clearInput={setsshContent}
                  />
                }
              </Col>
            </Row>

            <Row style={{ paddingTop: 20 }}>
              <Col>
                <strong>SSH </strong>
              </Col>
              <Col>
                {" "}
                {
                  <RemoteSSHNew
                    Id={device.id.id}
                    sshContent={sshContent}
                    clearInput={setsshContent}
                  />
                }
              </Col>
            </Row>
          </DisplayOnUserType>

          {/*  */}
        </div>
        <Card.Text></Card.Text>
      </Card.Body>
    </Card>
  );
};

export default function Default() {
  let { id, tab } = useParams();
  const [showSensorTelemetries, setshowSensorTelemetries] = useState(false);
  const [device, setdevice] = useState();
  const [deviceAttributes, setdeviceAttributes] = useState();
  const [isloading, setisloading] = useState(true);
  const [isloadingaAttributes, setisloadingaAttributes] = useState(true);
  const [key, setKey] = useState("home");
  const [Alarms, setAlarms] = useState([]);
  const [sensorInfo, setsensorInfo] = useState("");

  const [sshContent, setsshContent] = useState("SSH");

  const [openISModal, setopenISModal] = useState(false);

  const getToken = () => localStorage.getItem("token");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-10%",
      transform: "translate(-50%, -50%)",
    },
  };

  const clickicon = (e) => {
    setshowSensorTelemetries(true);
    setsensorInfo(e);
  };

  const upateDeviceSettings = async (settings) => {
    try {
      const { data } = await UpdateSettings(id, settings);
      const shareupdate = {
        deviceid: id,
        devicesettings: settings,
      };

      NotificationManager.success("Device Settings Updated Successfully");
    } catch (error) {
      NotificationManager.success(
        "You are not allowed to updated device settings"
      );
    }
  };

  const GenerateRightDeviceModel = (
    devicetype,
    device,
    devid,
    settingsAttributes
  ) => {
    if (Object.entries(device.telemetries).length > 0) {
      if (devicetype === "SRU")
        return (
          <SRU
            telemetrie={device.telemetries}
            selecttab={tab}
            settings={device.attributes.settings}
            selectedtab={tab}
            deviceid={devid}
            Alarms={device.deviceAlarms}
            displayDashboard={device.attributes.deviceDashboard}
          />
        );
      else if (devicetype === "ENVIROMENT1")
        return (
          <SmartCabinet
            historiesListWithLabels={device.attributes.historyGraphs}
            settingLabel={settingsAttributes}
            telemetrie={device.telemetries}
            settings={device.attributes.settings}
            deviceid={devid}
            Alarms={device.deviceAlarms}
            selectedtab={tab}
            displayDashboard={device.attributes.deviceDashboard}
          />
        );
      else if (devicetype === "GENERIC-UPS")
        return (
          <GenericUPS
            settingLabel={settingsAttributes}
            telemetrie={device.telemetries}
            settings={device.attributes.settings}
            deviceid={devid}
            Alarms={device.deviceAlarms}
            selectedtab={tab}
          />
        );
      else if (devicetype === "PH1")
        return (
          <PowerMonitor
            selecttab={tab}
            telemetries={device.telemetries}
            settings={device.attributes.settings}
            deviceId={devid}
            selectedtab={tab}
            handleSettingsUpdate={upateDeviceSettings}
            handlonClickIcon={clickicon}
            displayDashboard={device.attributes}
          />
        );
      else if (devicetype === "PH1-BAT-MON")
        return (
          <PowerMonitorBatteryMonitor
            telemetries={device.telemetries}
            settings={device.attributes.settings}
            deviceId={devid}
            handleSettingsUpdate={upateDeviceSettings}
            handlonClickIcon={clickicon}
          />
        );
      else if (devicetype === "EV-07-TRACKER")
        return (
          <SimpleTracker
            settingLabel={settingsAttributes}
            telemetrie={device.telemetries}
            settings={device.attributes.settings}
            deviceid={devid}
            Alarms={device.deviceAlarms}
            selectedtab={tab}
            displayDashboard={device.attributes.deviceDashboard}
          />
        );
      else
        return (
          <GenericDevice
            all={device}
            historiesListWithLabels={device.attributes.historyGraphs}
            settingLabel={settingsAttributes}
            telemetrie={device.telemetries}
            settings={device.attributes.settings}
            deviceid={devid}
            Alarms={device.deviceAlarms}
            selectedtab={tab}
            displayDashboard={device.attributes.deviceDashboard}
          />
        );
    } else {
      return (
        <div>
          <Alert variant={"warning"}>
            The device is not yet initialized, please make sure it is configured
            correctly and try again.
          </Alert>
        </div>
      );
    }
  };

  const loadFullInfo = async () => {
    try {
      const devicein = await GetDeviceFullInformation(id);

      setdevice(devicein);
    } catch (error) {
      NotificationManager.error("Failed to load device info");
    }
  };

  const [settingsAttributes, setsettingsAttributes] = useState({});
  useEffect(() => {
    var line = "";

    var client = new w3cwebsocket(
      `${envSettings.WS_URL}api/ws/plugins/telemetry?token=${getToken()}`
    );

    const wesocketListner = () => {
      client.onmessage = function (event) {
        var received_msg = event.data;

        var obejectreceived = JSON.parse(received_msg);

        if (obejectreceived.data?.STDOUT) {
          line = obejectreceived.data?.STDOUT[0][1];
          //line = line + obejectreceived.data?.STDOUT[0][1];

          console.log(`I received data`, line);

          //var line = obejectreceived.data?.STDOUT[0][1];

          setsshContent(line);
        }

        loaddeviceinfo();
        if (obejectreceived.subscriptionId === 1) {
          return;
        }

        if (obejectreceived.subscriptionId === 2) {
          return;
        }
      };
      client.onclose = () => console.log("Connection closed");

      client.onopen = () => {
        console.log("Connected to web socker");
        var object = {
          tsSubCmds: [
            {
              entityType: "DEVICE",
              entityId: id,
              cmdId: "1",
            },
          ],
          historyCmds: [],
          attrSubCmds: [
            {
              entityType: "DEVICE",
              entityId: id,
              cmdId: 2,
              keys: "active,commands,inactivityAlarmTime,lastConnectTime,lastDisconnectTime",
            },
          ],
        };
        var data = JSON.stringify(object);
        client.send(data);
      };
    };
    wesocketListner();
    const loaddeviceinfo = async () => {
      try {
        const devicein = await GetDeviceFullInformation(id);

        setdevice(devicein);
        setisloading(false);
        return devicein;
      } catch (error) {
        NotificationManager.error("Failed to authenticate");
        //alert(error);
      }
    };
    loaddeviceinfo();

    return () => {
      client.close();
      // clearInterval(interval);
    };
  }, []);
  return (
    <>
      <Header />

      {/* <Modal
        isOpen={openISModal}
        onAfterOpen={()=>{}}
        onRequestClose={()=>{setopenISModal(false)}}
        style={customStyles}
        contentLabel="Lost Live Stream"
      >
        <div>
          <Button variant="success" style={{margin:10}}  >Reconnect</Button>
         
        </div>

      </Modal> */}
      <div style={{ padding: 10, height: 1200 }}>
        {isloading && <h1>Loading ....</h1>}
        {!isloading && (
          <Row>
            <Col md={2}>
              <DeviceInformation
                sshContent={sshContent}
                device={device.device}
                attributes={device.attributes}
                setsshContent={setsshContent}
              />
            </Col>
            <Col md={8}>
              {GenerateRightDeviceModel(
                device.device.type,
                device,
                id,
                device.attributes
              )}
            </Col>
            <Col>
              <SensorHistoryModal
                sensordetails={sensorInfo}
                deviceid={id}
                show={showSensorTelemetries}
                onHide={() => setshowSensorTelemetries(false)}
              />
              <AlarmsListComponents
                alarms={device.deviceAlarms}
                reloadAlarms={loadFullInfo}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}
