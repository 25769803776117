import React, { useState, useEffect } from "react";
import httpService, {
  setAuthorizationHeader,
} from "../../../services/HttpService";
import moment from "moment";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import ReactMapGL, { Marker as MarkerGl } from "react-map-gl";
import {
  ArrowDown,
  Bell,
  BellFill,
  GraphDown,
  HandThumbsUp,
  List,
  PieChartFill,
  Reception4,
  X,
  XCircleFill,
} from "react-bootstrap-icons";
import Header from "../../Header";
import { Link, NavLink } from "react-router-dom";
import CheckIfAuthencicated from "../../CheckIfAuthencicated";
import { generateColorOnSeveriry } from "../../../services/Utilities";
import { ResponsivePieCanvas } from '@nivo/pie'



var backgoundcolourStyle = "#14274e";
var countBackground = 1;
//backgoundcolourStyle="black";



const generateDeviceStatus = (deviceSatus)=>{

  var data = deviceSatus.map(a=> {
    return {
      id:a.name,
      label:a.name,
      value:a.alarms
    }
  })

  //console.log('New data ',data)

  return data;

}

const GraphicActiveAlarms = ({alarms , callbackData , title})=>{

 
  return(<>
  <div style={{width:'100%', textAlign:"center", height:400 , padding:20}}> 
  <h4 style={{textAlign:"center"}}>{title}</h4>
  <ResponsivePieCanvas
        onClick={(node,event)=> {console.log('Clicked on Me',node); callbackData(node)}}
        data={alarms}
        margin={{ top: 20, right: 60, bottom: 20, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={{ scheme: 'paired' }}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.6 ] ] }}
      
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={1}
        arcLabelsTextColor="#333333"
        

        />
      
  </div>

  </>)
}

// const GraphicActiveAlarmsLine = ({data , callbackData , title})=>{


//   return(<>
//   <div style={{width:'100%', textAlign:"center", height:400 , padding:20}}> 
 
//   <ResponsiveTimeRange
//         data={data}
//         from="2021-08-01"
//         to="2021-08-31"
//         emptyColor="#eeeeee"
//         colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
//         margin={{ top: 40, right: 40, bottom: 100, left: 40 }}
//         dayBorderWidth={2}
//         dayBorderColor="#ffffff"
//         legends={[
//             {
//                 anchor: 'bottom-right',
//                 direction: 'row',
//                 justify: false,
//                 itemCount: 4,
//                 itemWidth: 42,
//                 itemHeight: 36,
//                 itemsSpacing: 14,
//                 itemDirection: 'right-to-left',
//                 translateX: -85,
//                 translateY: -240,
//                 symbolSize: 20
//             }
//         ]}
//     />
      
//   </div>

//   </>)
// }


function AlarmsCount({ alarms }) {
  return (
    <div>
      <h6>{alarms > 0 && <Badge variant="danger">{alarms}</Badge>}</h6>
      <h6>{alarms < 1 && <Badge variant="success">{alarms}</Badge>}</h6>
    </div>
  );
}

const Display = ({ title, value, ICON }) => {
  return (
    <Card
      style={{
        margin: 10,
        borderWidth: 2,
        opacity: countBackground,
        backgroundColor: backgoundcolourStyle,
      }}
    >
      <Card.Body style={{ color: "white" }}>
        <Card.Title>{title}</Card.Title>

        <Card.Text>
          <Row>
            <Col>
              <h1>{value}</h1>
            </Col>
            <Col>
              {ICON}
              <h1></h1>
            </Col>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const DisplayMessage = ({ title, value, ICON }) => {
  return (
    <Card
      style={{
        margin: 10,
        borderWidth: 2,
        opacity: countBackground,
        backgroundColor: backgoundcolourStyle,
      }}
    >
      <Card.Body className="text-center" style={{ color: "white" }}>
        <Card.Title>{title}</Card.Title>

        <Card.Text>
          <Row>
            <Col>
              <h1>{ICON}</h1>
            </Col>
            
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const DisplayContent = ({ title, alarms, name, hide, time, id }) => {
  return (
    <Card
      className="text-center"
      style={{
        margin: 20,

        opacity: countBackground,
        backgroundColor: backgoundcolourStyle,
      }}
    >
      <Card.Header style={{ backgroundColor: "grey" }}>
        <Card.Title style={{ color: "white" }}>
          <XCircleFill onClick={hide} />{" "}
          <Link style={{ color: "white" }} to={`/GenericDevice/${id}/home`}>
            {" "}
            {name}
          </Link>{" "}
          - {title} ({alarms && alarms.length}){" "}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text style={{ color: "red" }}>
          <Row>
            <Col></Col>
            <Col>
              <h1></h1>
            </Col>
          </Row>
          {alarms &&
            alarms.map((a) => (
              <p style={{ fontWeight: "bold", color: "" }}>
                {" "}
                {moment(Number(a.created_time)).format(
                  "yyyy/MM/DD HH:mm:ss"
                )} : {a.type}
              </p>
            ))}
          {}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const AlarmDisplau = ({ alarmInfo }) => {

  return (
    <Card
      style={{
        margin: 5,
        borderWidth: 2,
        opacity: countBackground,
        backgroundColor: generateColorOnSeveriry(alarmInfo.severity),
      }}
    >
      <Card.Body className="text-center">
        <Bell />{" "}
        <Link
          style={{ color: "white", fontWeight: "bold" }}
          to={`/app/GenericDevice/${alarmInfo.device_id}/home`}
        >
          {" "}
          {  alarmInfo.devicepersonalname || alarmInfo.name} 
         
        </Link>
        <p style={{ fontWeight: "bold", color: "white" }}>{alarmInfo.type.toUpperCase()}
        <div  style={{ color: "white", fontWeight: "", fontStyle:"" }}> {moment(Number(alarmInfo.created_time)).toNow(true)}</div>
        </p>
        
       
      </Card.Body>
    </Card>
  );
};



export default function DevicesStatusNoMap() {
  const devicesCollums = [
    {
      name: "Status",
      selector: "active",
      sortable: true,
      cell: (row) => (
        <h6>
          {row.active && <Badge variant="success">Active</Badge>}{" "}
          {!row.active && <Badge variant="danger">Inactive</Badge>}
        </h6>
      ),
    },
    {
      name: "Device",
      selector: "name",
      sortable: true,
      cell: row=> <div>{row.name}</div>
    },
    {
      name: "Name",
      selector: "devicePersonalName",
      sortable: true,
      cell: row=> <div>{row.devicePersonalName || row.name}</div>
    },

    {
      name: "i-PoP Last Activity",
      selector: "lastActivityTime",
      sortable: true,
      cell: (row) => (
        <div>
          {moment(Number(row.lastActivityTime)).format("DD/MM/yyyy HH:mm")}{" "}
        </div>
      ),
    },
    {
      name: "i-PoP Alarms",
      selector: "alarms",
      sortable: true,
      cell: (row) => <AlarmsCount alarms={row.alarms} />,
    },
    {
      name: "",
      selector: "",

      cell: (row) => (
        <NavLink
          title="View device info"
          className="btn btn-sm btn-primary"
          style={{ marginRight: 10 }}
          to={`/app/GenericDevice/${row.id}/home`}
        >
          {" "}
          <List />
        </NavLink>
      ),
    },
  ];

  const [devices, setdevices] = useState([]);

  var intervaalid;

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: -25.7577,
    longitude: 28.4376,
    zoom: 10.3,
  });

  const getColour = (alarms, status) => {
    var colour = "green";
    if (alarms === 0) colour = "green";
    if (alarms > 0) colour = "red";
    if (status === false) colour = "orange";

    return colour;
  };

  const [alarms, setalarms] = useState(null);

  const [alarmStats, setalarmStats] = useState([])

  const [showAlarms, setshowAlarms] = useState(false);

  const [deviceSelected, setdeviceSelected] = useState("");

  const [activeDevices, setactiveDevices] = useState(0);

  const [alarmsCount, setalarmsCount] = useState(0);
  const [deviceSelectedId, setdeviceSelectedId] = useState("");
  const [Fullalarms, setFullalarms] = useState(null);

  const [deviceGeoSpacials, setdeviceGeoSpacials] = useState(null);

  const [clusters, setclusters] = useState(null);
  const [supercluster, setsupercluster] = useState(null);

  const [deviceAlarmTypes, setdeviceAlarmTypes] = useState();

  const [alarmTypeStrend, setalarmTypeStrend] = useState();

  const [showGraphs, setshowGraphs] = useState(false)


  const loadAlarmsTrends  =()=>{
    httpService.get('/repport/alarmstrendstoday')
    .then(response=>{
      const {data} = response;
      setalarmTypeStrend([...data.message.rows])
    }).catch(error=>{
      setactiveDevices([]);
    })
  }
 
  const getAlarmTypeDevices = (info)=>{

    httpService.post('/repport/byalarmType',{type:info})
    .then(response=>{
      const {data} = response;
      
      setdeviceAlarmTypes([...data.message])
    }).catch(error=> {setdeviceAlarmTypes([])})

  }
  const loadAllDevicesAlarms = () => {
    httpService
      .get("/repport/mydevices/alarms")
      .then((response) => {
        const { data } = response;
        // alert(JSON.stringify(data));
        if(data.length > 0)
        setFullalarms([...data]);
        else
        setFullalarms(null);
      })
      .catch((error) => {
        //alert(JSON.stringify(error));
      });
  };

  const getDeviceAlarms = (deviceId, devicename) => {
    httpService
      .get("/repport/deviceallarmsfull/" + deviceId)
      .then((response) => {
        setdeviceSelectedId(deviceId);
       // console.log("alarms - > ", response.data);
        setalarms([...response.data]);
        setdeviceSelected(devicename);
        setshowAlarms(true);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const loadAlarStats = ()=>{
    httpService.get("/repport/alarmsStats")
    .then(response=>{
      const {data} = response;
      setalarmStats([...data.message])
    }).catch(erre=>{
      setalarmStats([])
    })
  }

  const loadDevices = () => {
    httpService
      .get("/repport/deviceStatus")
      .then((response) => {
        // console.log("Devices - > ", response.data);
        setdevices([...response.data.devices]);
        setactiveDevices(response.data.inactive);
        setalarmsCount(response.data.alarms);

        var geSpacials = response.data.devices.map((a) => ({
          type: "Feature",
          properties: {
            cluster: false,
            crimeId: a.id,
            category: a.alarms,
          },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(a.settings.longitude),
              parseFloat(a.settings.latitude),
            ],
          },
        }));

        ///alert(JSON.stringify(geSpacials));
        setdeviceGeoSpacials([...geSpacials]);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  useEffect(() => {
    loadDevices();
    loadAllDevicesAlarms();
    loadAlarStats();
    // loadAlarmsTrends();
    intervaalid = setInterval(() => {
      loadDevices();
      loadAllDevicesAlarms();
      loadAlarStats();
      loadAlarmsTrends();
    }, 5000);
    return () => {
      clearInterval(intervaalid);
    };
  }, []);

  //  Cluster Setups

  // get Clusters

  //Map Display
  return (
    <>
    
    <Header activeHeaderSatus={true} activeDevice={devices.length} InactiveDevice={activeDevices} ActiveAlarms={alarmsCount} />

      <Row>
       
        <Col md={12} lg={2} sm={12}>
          <div
            style={{
              overflowY: "scroll",
              height: "100%",
              scrollbarColor: "red",
              scrollbarWidth: 1,
            }}
          >
            {Fullalarms &&
              Fullalarms.map((a) => <AlarmDisplau key={`${a.device_id}-${a.type}`} alarmInfo={a} />)}

              {!Fullalarms && <DisplayMessage
                title={"No Alamrs"}
                value={""}
                ICON={<Bell color="green" size={50} />}
              />}
          </div>
        </Col>
        
     

        <Col >
          <Row>
          <PieChartFill  onClick={(e)=> setshowGraphs(!showGraphs)} style={{marginTop:10}}  color={"#14274e"} size={30}></PieChartFill>
          {showGraphs && <>
            <Col md={12} lg={12} > <GraphicActiveAlarms title={"DEVICES"} alarms={generateDeviceStatus(devices)} callbackData={(e)=>{}}/> </Col>
            <Col md={12} lg={12}>  <GraphicActiveAlarms title={"ALARMS"} alarms={alarmStats} callbackData={(e)=>{getAlarmTypeDevices(e.label)}}/> </Col>
            {/* <Col md={12}>
            {alarmTypeStrend && <GraphicActiveAlarmsLine  data={alarmTypeStrend}/>}
            
            </Col> */}
          </>}
            
         
          </Row>
          <Row>
            <Col>
              {/* <Display
                title={"Active Device"}
                value={devices.length}
                ICON={<Reception4 color="green" size={50} />}
              /> */}
            </Col>
            <Col>
              {/* <Display
                title={"Inactive Device"}
                value={activeDevices}
                ICON={<Reception4 color="ORANGE" size={50} />}
              /> */}
            </Col>
            <Col>
              {/* <Display
                title={"Active Alarms"}
                value={alarmsCount}
                ICON={<Bell color="red" size={50} />}
              /> */}
              {showAlarms && (
                <DisplayContent
                  title="Alarms"
                  alarms={alarms}
                  name={deviceSelected}
                  id={deviceSelectedId}
                  hide={() => setshowAlarms(false)}
                />
              )}
            </Col>
          </Row>
          <Container fluid>
            {devices && (
              <DataTableExtensions columns={devicesCollums} data={devices}>
                <DataTable
                  noHeader
                  defaultSortField="active"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            )}
          </Container>
        </Col>    
        {showGraphs && 
             <Col md={2} sm={2}>
             <div
               style={{
                 overflowY: "scroll",
                 height: "100%",
                 scrollbarColor: "red",
                 scrollbarWidth: 1,
               }}
             >
               {deviceAlarmTypes &&
                 deviceAlarmTypes.map((a) => <AlarmDisplau key={`${a.device_id}-${a.type}`} alarmInfo={a} />)}
   
                 {!deviceAlarmTypes && <DisplayMessage
                   title={"No Alamrs"}
                   value={""}
                   ICON={<Bell color="green" size={50} />}
                 />}
                 
             </div>
           </Col>
        }
   
      </Row>

    
    </>
  );
}
