import React, { useState ,useEffect} from "react";
import { Button, Col, Form } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import moment from 'moment';
export default function DateTimeSelector({ handlerDateSelection }) {
 
  
  const [from, setfrom] = useState(new Date());
  const [to, setto] = useState(new Date());
  const [custumselected, setcustumselected] = useState(false);
  const handleSelectionOfFilter = (e) =>{
    if(e.target.value === "Custom")
    {
      setcustumselected(true);
     
    }
    else
    {
      if(e.target.value === "Last 15 Minutes")
      {
        var d = new Date();

       d.setMinutes(d.getMinutes() - 15);
        setto(new Date());
      //  setfrom( moment().subtract(15,'m'));
        setfrom( d);
      }

      if(e.target.value === "Last 30 Minutes")
      {
        var d = new Date();

       d.setMinutes(d.getMinutes() - 30);
        setto(new Date());
      //  setfrom( moment().subtract(15,'m'));
        setfrom( d);
      }

      if(e.target.value === "Last Hour")
      {
        var d = new Date();
        d.setHours(d.getHours() - 1);
       setfrom(d);
       setto(new Date());
      
      }

      if(e.target.value === "Last 24 Hours")
      {
        var d = new Date();
        d.setHours(d.getHours() - 24);
       setfrom(d);
       setto(new Date());
      
      }

      if(e.target.value === "Last 12 Hours")
      {
        var d = new Date();
        d.setHours(d.getHours() - 12);
       setfrom(d);
       setto(new Date());
      
      }

      if(e.target.value === "Last 6 Hours")
      {
        var d = new Date();
        d.setHours(d.getHours() - 6);
       setfrom(d);
       setto(new Date());
      
      }

      if(e.target.value === "Last 3 Hours")
      {
        var d = new Date();
        d.setHours(d.getHours() - 3);
       setfrom(d);
       setto(new Date());
      
      }
      
      setcustumselected(false);
    }
    
  }

useEffect(() => {
 
  var d = new Date();
  d.setMinutes(d.getMinutes() - 15);
   setto(new Date()); 
   setfrom( d);

}, [])

  return (
    <Form style={{ padding: 10 }}>
      <Form.Row>
        <Col md={"auto"}>
          <Form.Group controlId="exampleForm.ControlSelect2">
           
            <Form.Control as="select"  onChange={handleSelectionOfFilter} >
              <option>Last 15 Minutes</option>
              <option>Last 30 Minutes</option>
              <option>Last Hour</option>
              <option>Last 3 Hours</option>
              <option>Last 6 Hours</option>
              <option>Last 12 Hours</option>
              <option>Last 24 Hours</option>
              <option>Custom</option>
            </Form.Control>
          </Form.Group>
          
         
        </Col>
        {custumselected && <React.Fragment>
          <Col md={"auto"}>
          <DateTimePicker
            onChange={(e) => setfrom(e)}
            value={from}
            className="form-control"
          />


        </Col>
        <Col md={"auto"}>
          <DateTimePicker
            className="form-control"
            onChange={(e) => setto(e)}
            value={to}
          />
        </Col>
          </React.Fragment>}
        

        <Col md={"auto"}>
          <Button onClick={() => handlerDateSelection(from, to)}>Search</Button>
        </Col>
      </Form.Row>
    </Form>
  );
}

