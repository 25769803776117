import React, { useState,useEffect } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { NewGetSettings } from "../../../services/DeviceService";
import Header from "../../Header";
import SensorHistoryModal from "../SensorHistoryModal";
import { Displays, InformationBox } from "./Displays";


  
  const powerDisplay ={
    title:"Power",
    information:[
      {size:4, height:160, title:"Mains", rows:[
        { name:"Voltage", isRange:true,sensor:"MAINS_VOLTAGE",symbole:"V", max:"Mains Voltage High Limit",min:"Mains Voltage Low Limit"},
        { name:"Current", isRange:true,sensor:"MAINS_CURRENT",symbole:"A", max:"Mains Current High Limit",min:"Mains Current Low Limit"},
          ]},     
      {size:4, height:160, title:"UPS", rows:[
        { name:"Voltage", isRange:true,sensor:"UPS_VOLTAGE_LEVEL",symbole:"V"},
        { name:"Load", isRange:true,sensor:"UPS_LAOD_LEVEL",symbole:"%"},
        { name:"Battery", isRange:true,sensor:"UPS_BATTERY_LEVEL",symbole:"%"},
        { name:"Bypass", isRange:false,sensor:"UPSBypassStatus",symbole:"",fullValues:[{short:"1", full:"ON", colour:"danger"},{short:"0", full:"OFF",colour:"success"}]}
      ]}
    ]
  }
  

  const dashboardDisplay =[powerDisplay];
  
  

function GenericUPSDisplay(props) {
    const [sensors, setsensors] = useState({
 
       
    
        mains_active_energy:[{"value":"NA"}],
        mains_power_factor:[{"value":"NA"}],
        Total_Active_Energy:[{"value":"NA"}],
        mains_Actice_Power:[{"value":"NA"}],
        Import_Active_Energy:[{"value":"NA"}],
        MAINS_VOLTAGE:[{"value":"NA"}],
        MAINS_CURRENT:[{"value":"NA"}],
        MAINS_SURGE_COUNTER:[{"value":"NA"}],
        mains_Actice_Power:[{"value":"NA"}],
        mains_frequency:[{"value":"NA"}],
        
        UPS_VOLTAGE_LEVEL:[{"value":"NA"}],
        UPS_LAOD_LEVEL:[{"value":"NA"}],
        UPS_BATTERY_LEVEL:[{"value":"NA"}],
        UPSBypassStatus:[{"value":"NA"}],
        aircon1:[{"value":"NA"}],
        aircon2:[{"value":"NA"}],
    
      
    
    });
    
    const [sensorInfo, setsensorInfo] = useState({
      name:""
    });
    const [showSensorTelemetries, setshowSensorTelemetries] = useState(false);
    
    const [deviceSettings, setdeviceSettings] = useState({
    
      
    });
    
    const onSensorClicked =(sensorName,sensor )=>{
      setsensorInfo({...sensorInfo,name:sensorName,sensorName:sensor});
      setshowSensorTelemetries(true);
    }
    
    
    
    
    useEffect(() => {
      setsensors({...sensors,...props.sensors});
    
      NewGetSettings(props.deviceId)
      .then(response=> {
        const {data} = response;
        
        console.log('Device Settings',);
        
        setdeviceSettings({...data});     
      })
      .catch(error=> {})    
    
    }, [props.sensors])
    
      return (
        <div>
       <SensorHistoryModal sensordetails={sensorInfo}  deviceid={props.deviceId} show={showSensorTelemetries} onHide={()=> setshowSensorTelemetries(false)}  /> 
       {
            dashboardDisplay.map(dashboard=>
              <Row>
              <InformationBox height={null} title={dashboard.title}>
              <Row>
              {dashboard.information.map(informationBox=>
                 
              <InformationBox  size={informationBox.size}  height={informationBox.height} title={informationBox.title}>  
                 
              {informationBox.rows.map(rows=>   <Displays max={deviceSettings[rows.max]}  min={deviceSettings[rows.min]} HandleSensorClick={(e)=> onSensorClicked(e,rows.sensor)} name={rows.name} isRange={rows.isRange} value={sensors[rows.sensor][0].value} fullValues={rows.fullValues}  symbole={rows.symbole} />   )}  
                     
              </InformationBox>
              )}
                </Row>
            </InformationBox>
              
            </Row>
              )
          }
        
    
          
    </div>
      );
}

export default GenericUPSDisplay;