import React, { useEffect, useState } from "react";
import { CollectionPlay } from "react-bootstrap-icons";
import {MultiSelect} from "react-multi-select-component";
import { NotificationManager } from "react-notifications";
import { GetDeviceAttributesKey, UpdateSettingsKey, UpdateSettingsKeyShared } from "../../../services/DeviceService";
import HttpService from "../../../services/HttpService";
import GeneridModal from "../../Ulties/GeneridModal";

export default function DuplicateSettings({ deviceId, name, model }) {
  const [devicesList, setdevicesList] = useState([]);
  const [selectedDevices, setselectedDevices] = useState([]);

  const [deviceSettings, setdeviceSettings] = useState({});

 

  const cleatData  = ()=>{
    setdevicesList([])
    setselectedDevices([])
    setdeviceSettings([]);
  }

  const laodDevices = () => {

    GetDeviceAttributesKey(deviceId,"settings")
    .then(attributeGot=>{
      const {data} = attributeGot;     
      
      setdeviceSettings({...data[0].value});
     
    }).catch(attributeGotError=>{
      NotificationManager.error('Failed to get device settings')
    });
    
    HttpService.get("/repport/deviceStatus")
      .then((response) => {
        const { data } = response;

        var deviceListReady = data.devices.map((device) => {
          return { label: device.name, value: device.id };
        });

        setdevicesList([...deviceListReady]);
      })
      .catch((responseError) => {
        NotificationManager.error("Failed to laod devices", responseError);
      });
  };

  const selectedDevicesCommand = (id, label)=>{

    GetDeviceAttributesKey(id,"settings").then(attributeGot=>{
      const {data} = attributeGot;
      
      var deviceKeepSettings = {latitude:data[0].value.latitude,longitude:data[0].value.longitude};     

      var newSettings = {...deviceSettings,...deviceKeepSettings};

      UpdateSettingsKeyShared(id,"settings",newSettings).then(reponseUpdateSettings=>{
        const {data} = reponseUpdateSettings;

        NotificationManager.success(`${label} settings updated successfully.`);

      }).catch(reponseUpdateSettingsError=>{
        NotificationManager.error(`${label} failed to updated settings`)
      })

     
    }).catch(attributeGotError=>{
      NotificationManager.error('Failed to get settings');
    })
  }

  const proccessUpdateOfDevices = ()=>{

    selectedDevices.forEach(a=>{
      selectedDevicesCommand(a.value, a.label);
    })
  }

  return (
    <>
      <GeneridModal
        modalClosed={() => {cleatData()}}
        title="Duplicate Settings"
        size="lg"
        icon={<CollectionPlay />}
        buttonTitle={"Update Settings"}
        HandleModalAction={() => {proccessUpdateOfDevices()}}
        modalOpened={() => {
          laodDevices();
        }}
      >
        <div>
          
          <p>Devices to Copy Settings to ({selectedDevices.length})</p>
          <MultiSelect
            options={devicesList}
            value={selectedDevices}
            onChange={setselectedDevices}
            labelledBy="Select"
          />
          
        </div>
      </GeneridModal>
    </>
  );
}
