import react,{useState,useEffect} from 'react';
import { Col, Row } from "react-bootstrap";
import { NewGetSettings } from '../../../services/DeviceService';
import SensorHistoryModal from '../SensorHistoryModal';
import { Displays, InformationBox } from "./Displays";

const Display =[
  {
    title:"",
    information:[
      {size:3, height:170, title:"CONNECTIVITY", rows:[
        { name:"Signal Strenght", isRange:true,sensor:"GSM_SIG",symbole:"%"}, 
        { name:"Internal Battery", isRange:true,sensor:"INT_BAT_V",symbole:"V",max:"Internal Battery High Limit",min:"Internal Battery Low Limit"},       
      ]},
      {size:3, height:170, title:"DC POWER METER", rows:[
        { name:"Voltage", isRange:true,sensor:"DPM_V_1",symbole:"V", max:"Temperature High Limit",min:"Temperature Low Limit"},  
        { name:"Current", isRange:true,sensor:"CDPM_I_1",symbole:"A",max:"Humidity High Limit",min:"Humidity Low Limit"},       
        { name:"Power", isRange:true,sensor:"DPM_P_1",symbole:"W",max:"Humidity High Limit",min:"Humidity Low Limit"},  
        { name:"Energy", isRange:true,sensor:"DPM_NRG_1",symbole:"Wh",max:"Humidity High Limit",min:"Humidity Low Limit"},  
      ]},
      {size:3, height:170, title:"AC", rows:[
        { name:"Status", isRange:false,sensor:"STAT_AC",symbole:"",fullValues:[{short:"1", full:"ON", colour:"success"},{short:"0", full:"OFF",colour:"danger"}]},  
           
      ]}
    ]
  }
]


export function PH1BATTERYMON(props) {

    const [sensors, setsensors] = useState({
      GSM_SIG:[{"value":"NA"}],
       
        InternalBattery:[{"value":"NA"}],
        INT_BAT_V:[{"value":"NA"}],
        DPM_V_1:[{"value":"NA"}],
        CDPM_I_1:[{"value":"NA"}],
        DPM_P_1:[{"value":"NA"}],
        DPM_NRG_1:[{"value":"NA"}],
        STAT_AC:[{"value":"NA"}]
    });

    const [deviceSettings, setdeviceSettings] = useState({
  
    });

    useEffect(() => {
        setsensors({...sensors,...props.sensors})

        NewGetSettings(props.deviceId)
        .then(response=> {
          const {data} = response;
          
         // console.log('Device Settings',);
          
          setdeviceSettings({...data});     
        })
        .catch(error=> {})  

    }, [props.sensors])



    const [sensorInfo, setsensorInfo] = useState({
      name:""
    });
    const [showSensorTelemetries, setshowSensorTelemetries] = useState(false);

    const onSensorClicked =(sensorName,sensor )=>{
      setsensorInfo({...sensorInfo,name:sensorName,sensorName:sensor});
      setshowSensorTelemetries(true);
    }
    
  
    return (
      <div>
         <SensorHistoryModal sensordetails={sensorInfo}  deviceid={props.deviceId} show={showSensorTelemetries} onHide={()=> setshowSensorTelemetries(false)}  /> 
   {
        Display.map(dashboard=>
          <Row>
          <InformationBox height={null} title={dashboard.title}>
          <Row>
          {dashboard.information.map(informationBox=>
             
          <InformationBox  size={informationBox.size}  height={informationBox.height} title={informationBox.title}>  
             
          {informationBox.rows.map(rows=>   <Displays  max={deviceSettings[rows.max]}  min={deviceSettings[rows.min]} HandleSensorClick={(e)=> onSensorClicked(e,rows.sensor)} name={rows.name} isRange={rows.isRange} value={sensors[rows.sensor][0].value} fullValues={rows.fullValues}  symbole={rows.symbole} />   )}  
                 
          </InformationBox>
          )}
            </Row>
        </InformationBox>
          
        </Row>
          )
      }
    
     </div>
    );
  }