import React, { useState } from "react";
import { Col, Row, Button, FormControl, Form } from "react-bootstrap";
import { ArrowUpRight, ArrowUpRightSquareFill, BackspaceFill, Braces, CloudUpload, CodeSquare, Download, Exclamation, FolderFill, Gear, GearFill, Upload } from "react-bootstrap-icons";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { ExecuteDeviceCommand } from "../../../services/DeviceService";
import GeneridModal from "../../Ulties/GeneridModal";
import moment from 'moment';

var fileReader;

const downloadTxtFile = (fileName, data, extention) => {
  const element = document.createElement("a");
  const file = new Blob([(data)],
    { type: 'text/plain;charset=utf-8' });
  element.href = URL.createObjectURL(file);
  element.download = `${fileName} ${moment().format('_YYYY_MM_DD__HH_mm_ss')}.${extention}`;
  document.body.appendChild(element);
  element.click();
}


const commandTempalte = {
  method: "CheckServiceStatus",
  params: {
    CMD: "",
    value: "",
  },
};

const optionsAvailable = [
  { name: "restartApp", download: true, label:"Restart App", type:'command' },
  { name: "restartPDNS", download: true, label:"Restart PDNS App",type:'command' },
  { name: "getDeviceInternalSettings",uplaodMethode:"setDeviceInternalSettings", upload: true, download: true, label:"Download/Upload Config File.", filePrexi:"config" , extention:"json",type:'ud'},  
  { name: "getPNMDSSettingd",uplaodMethode:"setGetPNMDSSettingd", upload: true, download: true, label:"Download/Upload PNMDSSettings.", filePrexi:"config" , extention:"xml",type:'ud'},  
  { name: "getPNMDSSettingd",uplaodMethode:"setGetPNMDSSettingdNew", upload: true, download: true, label:"Download/Upload PNMDSSettings. Updated", filePrexi:"config" , extention:"xml",type:'ud'},  
  { name: "getDeviceInternalSettingsBackup", download: true, label:"Downlaod Device Configuration Backup",filePrexi:"configBackup", extention:"json" ,type:'ud'},  
   { name: "getXmlFile", download: true, label:"Download Webget File",filePrexi:"WebGet", extention:"xml" ,type:'ud'},  
   { name: "getXmlFileTemplate",uplaodMethode:"setXmlFileTemplate", upload: true, download: true, label:"Download/Upload Webget Template.", filePrexi:"WebGet" , extention:"xml",type:'ud'},  
   { name: "getLogs",uplaodMethode:"getLogs", download: true, label:"Get Log list", filePrexi:"loglist" , extention:"logs",type:'ud'},
   { name: "getALog",uplaodMethode:"getALog", label:"Get a log File", fileInput:true,filePrexi:"log" , extention:"logs",type:'ud'},
   
]
const ConfifurationRowDetails = (props) => {

  const handleFileRead = (e) => {
    const content = e.target.result;
    console.log(content);
    setfileContent(content);
  };
  const handleFileChosen = (e) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(e.target.files[0]);
  };

  const [fileContent, setfileContent] = useState("");
  const [fileName, setfileName] = useState("");

  const sendStatusCommandWithPayload = () => {
    var command = commandTempalte;
    command.method = props.uplaodMethode;  
    
    command.params = fileContent;

    if(props.fileInput)
    command.params = fileName;

    if(fileContent.length === 0 && props.upload)
    {
      NotificationManager.warning('Please make sure , you have selected a file and that the file is not empty!.');  
      return
    }  

    if(fileName.length === 0 && props.fileInput)
    {
      NotificationManager.warning('Please make sure , you have Enter the file name');  
      return
    }  

    if(fileName.length === 0 && props.fileInput)
    {
      NotificationManager.warning('Please make sure , you have Enter the file name');  
      return
    }  
  
    ExecuteDeviceCommand(1, props.deviceId, command).then(response => {
      const { data } = response;  props.setResult(JSON.stringify(data.message));
      setfileContent("");  
    }).catch(error => {
      if (error) {
        NotificationManager.error('Command Failed to execute!');      
      }
    })
  }

  const sendStatusCommand = () => {
    var command = commandTempalte;
    command.method = props.name;  

    if(props.type)
    {
      const r = window.confirm("Do you really want to execute this command?"); if(r == false){ 
        return;
      }
      
    }
    
  
    ExecuteDeviceCommand(1, props.deviceId, command).then(response => {
      const { data } = response;  props.setResult((data.message));    

      if(props.filePrexi)
      downloadTxtFile(props.filePrexi,data.message, props.extention);    
    }).catch(error => {
      if (error) {
        NotificationManager.error(`Command Failed to execute! ${error}`);      
      }
    })
  }

  return (
    <>

<Col>
<h5>{props.label}</h5>
</Col>
<Col md={2}>
  {!props.controllerDownlaod && <Button size="sm" style={{margin:5}} variant={"warning"} onClick={sendStatusCommand}>

    {props.type==='ud' && <Download/>}
  {props.type==='command' && <ArrowUpRightSquareFill/> }
    </Button>}

{props.controllerDownlaod && <Button size="sm" style={{margin:5}} variant={"danger"} onClick={sendStatusCommandWithPayload}>
 
 <Download/> 
  </Button>}

</Col>
<Col md={4}>
  {props.upload && <Form>
  <Form.File 
    id="custom-file"
    label={"Upload file"}
    custom
    onChange={handleFileChosen}
  />
</Form>}

 {props.fileInput && <Form.Control type="text" placeholder="" value={fileName} onChange={e=> setfileName(e.target.value)} />} 
</Col> 
<Col md={2}>
{props.upload &&  <Button size="sm" style={{margin:5}} variant={"danger"} onClick={sendStatusCommandWithPayload}><Upload/> </Button>}
{props.fileInput &&  <Button size="sm" style={{margin:5}} variant={"success"} onClick={sendStatusCommandWithPayload}><Download/> </Button>}

</Col>
</>
  )
}


export default function ConfigurationManager({ deviceId }) {
  const commandTempalte = {
    method: "CheckServiceStatus",
    params: {
      CMD: "",
      value: "",
    },
  };
  const [content, setcontent] = useState(``);

  const [executing, setexecuting] = useState(false);
  const [isReadOnly, setisReadOnly] = useState(false)
  const sendStatusCommand = () => {

    setisReadOnly(true);

    setexecuting(true);
    ExecuteDeviceCommand(1, deviceId, commandTempalte).then(response => {
      const { data } = response;
      // console.log('Command response',response);
      setcontent(data.message);
      setexecuting(false);

      NotificationManager.success('Command Executed Successfully!');
    }).catch(error => {
      if (error) {
        NotificationManager.error('Command Failed to execute!');
        setexecuting(false);
      }
    })


  }
  const getConfig = () => {

    setisReadOnly(false);
    var command = commandTempalte;
    command.method = "getDeviceInternalSettings";

    setexecuting(true);
    ExecuteDeviceCommand(1, deviceId, command).then(response => {
      const { data } = response;
      // console.log('Command response',response);
      downloadTxtFile('Config', data.message);
      setcontent(JSON.stringify(data.message));
      setexecuting(false);
      NotificationManager.success('Command Executed Successfully!');
    }).catch(error => {
      if (error) {
        NotificationManager.error(` ${JSON.stringify(error)} Command Failed to execute!`);
        setexecuting(false);
      }
    })
  }

  const getConfigBackup = () => {

    setisReadOnly(false);
    var command = commandTempalte;
    command.method = "readDeviceInternalSettingsBackup";

    setexecuting(true);
    ExecuteDeviceCommand(1, deviceId, command).then(response => {
      const { data } = response;
      // console.log('Command response',response);
      setcontent(JSON.stringify(data.message));
      downloadTxtFile('backUPConfig', data.message);
      setexecuting(false);
      NotificationManager.success('Command Executed Successfully!');
    }).catch(error => {
      if (error) {
        NotificationManager.error(` ${JSON.stringify(error)} Command Failed to execute!`);
        setexecuting(false);
      }
    })
  }

  const setConfig = () => {
    var command = commandTempalte;
    command.method = "setDeviceInternalSettings";
    command.params = content;

    setexecuting(true);
    ExecuteDeviceCommand(1, deviceId, command).then(response => {
      const { data } = response;
      // console.log('Command response',response);
      setcontent(JSON.stringify(data.message));
      setexecuting(false);
      NotificationManager.success('Command Executed Successfully!');
    }).catch(error => {
      if (error) {
        NotificationManager.error(` ${JSON.stringify(error)} Command Failed to execute!`);
        setexecuting(false);
      }
    })
  }

  const resetConfig = () => {
    var command = commandTempalte;
    command.method = "resetConfig";
    command.params = content;

    setexecuting(true);
    ExecuteDeviceCommand(1, deviceId, command).then(response => {
      const { data } = response;
      // console.log('Command response',response);
      setcontent(JSON.stringify(data.message));
      setexecuting(false);
      NotificationManager.success('Command Executed Successfully!');
    }).catch(error => {
      if (error) {
        NotificationManager.error(` ${JSON.stringify(error)} Command Failed to execute!`);
        setexecuting(false);
      }
    })
  }


  const handleClickOnCodeSquare = () => setcontent(`Feb 22 09:23:10 raspberrypi systemd[1]: Started netshield gateway.`)

  return (
    <>
      <GeneridModal
        title="Device Configuration Files"
        size="xl"
        icon={<Braces />}
        modalClosed={() => setcontent('')}
      >
       
          {optionsAvailable.map(a =>  (<Row><ConfifurationRowDetails {...a} setResult={e=> setcontent(e)} deviceId={deviceId} /></Row>))}      

      



        <Row>
          <FormControl style={{ overflow: "auto", height: 400, color: "white", backgroundColor: "black" }} as="textarea" value={content} onChange={e => setcontent(e.target.value)} readOnly={isReadOnly} aria-label="With textarea" />
        </Row>

      </GeneridModal>
    </>
  );
}
