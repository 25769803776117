import React, { useState,useEffect } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { NewGetSettings } from "../../../services/DeviceService";
import Header from "../../Header";
import SensorHistoryModal from "../SensorHistoryModal";
import { Displays, InformationBox } from "./Displays";
import _ from 'lodash';

const defaultdashboardDisplay =[
  {
    title:"Cabinet Environmental Status",
    information:[
      {size:4, height:180, title:"Temperatures", rows:[
        { name:"Temp 1", isRange:true,sensor:"Temperature",symbole:"C",max:"Temperature 1 High Limit",min:"Temperature 1 Low Limit"},
        { name:"Temp 2", isRange:true,sensor:"Temperature2",symbole:"C",max:"Temperature 2 High Limit",min:"Temperature 3 Low Limit"},
        { name:"Temp 3", isRange:true,sensor:"Temperature3",symbole:"C",max:"Temperature 3 High Limit",min:"Temperature 3 Low Limit"},
        { name:"Temp 4", isRange:true,sensor:"Temperature4",symbole:"C",max:"Temperature 4 High Limit",min:"Temperature 4 Low Limit"}
      ]},
      {size:4, height:180, title:"Humidity", rows:[
        { name:"Hum 1", isRange:true,sensor:"Humidity",symbole:"%",max:"Humidity 1 High Limit",min:"Humidity 1 Low Limit"},   
        { name:"Hum 2", isRange:true,sensor:"Humidity2",symbole:"%"},  
        { name:"Hum 3", isRange:true,sensor:"Humidity3",symbole:"%"},  
        { name:"Hum 4", isRange:true,sensor:"Humidity4",symbole:"%"},     
      ]},
      {size:4, height:180, title:"Cabinet Doors", rows:[
        { name:"Front", isRange:false,sensor:"FrontDoor",symbole:"",fullValues:[{short:"0", full:"CLOSED", colour:"success"},{short:"1", full:"OPENED",colour:"danger"}]}
         
      ]},
      {size:4, height:140, title:"Aircons", rows:[
        { name:"Aricon 1", isRange:false,sensor:"aircon1",symbole:"",fullValues:[{short:"1", full:"ON", colour:"warning"},{short:"0", full:"OFF",colour:"success"}]},
        { name:"Aricon 2", isRange:false,sensor:"aircon2",symbole:"",fullValues:[{short:"0", full:"OFF", colour:"success"},{short:"1", full:"ON",colour:"warning"}]},
       ]},
       {size:4, height:140, title:"Smoke / Fire", rows:[
        { name:"Smoke 1", isRange:false,sensor:"smoke",symbole:"",fullValues:[{short:"0", full:"OFF", colour:"success"},{short:"2.0", full:"ON",colour:"danger"}]},
        { name:"Smoke 2", isRange:false,sensor:"smoke2",symbole:"",fullValues:[{short:"0", full:"OFF", colour:"success"},{short:"2.0", full:"ON",colour:"danger"}]},
       ]},
       {size:4, height:140, title:"Liquid Detection", rows:[
        { name:"Diesel Leak", isRange:false,sensor:"Water1",symbole:"",fullValues:[{short:"0", full:"No", colour:"success"},{short:"1", full:"Yes",colour:"danger"}]}   
      ]}
      
    ]
  },
  {
    title:"Power",
    information:[
      {size:4, height:160, title:"Mains", rows:[
        { name:"Voltage", isRange:true,sensor:"MAINS_VOLTAGE",symbole:"V", max:"Mains Voltage High Limit",min:"Mains Voltage Low Limit"},
        { name:"Current", isRange:true,sensor:"MAINS_CURRENT",symbole:"A", max:"Mains Current High Limit",min:"Mains Current Low Limit"},
        { name:"Frequency", isRange:true,sensor:"mains_frequency",symbole:"Hz"},
        { name:"Active Power", isRange:true,sensor:"mains_Actice_Power",symbole:"W",max:"Active Power High Limit",min:"Active Power Low Limit"}     
      ]},
      {size:4, height:160, title:"Advance Mains", rows:[
        { name:"Import Active Energy", isRange:true,sensor:"Import_Active_Energy",symbole:"kwh"},
        { name:"Power Factor", isRange:true,sensor:"mains_power_factor",symbole:""},
        { name:"Active Energy", isRange:true,sensor:"Total_Active_Energy",symbole:"kh"}      
        
      ]},
      {size:4, height:160, title:"UPS", rows:[
        { name:"Voltage", isRange:true,sensor:"UPS_VOLTAGE_LEVEL",symbole:"V"},
        { name:"Load", isRange:true,sensor:"UPS_LAOD_LEVEL",symbole:"%"},
        { name:"Battery", isRange:true,sensor:"UPS_BATTERY_LEVEL",symbole:"%"},
        { name:"Bypass", isRange:false,sensor:"UPSBypassStatus",symbole:"",fullValues:[{short:"1", full:"ON", colour:"danger"},{short:"0", full:"OFF",colour:"success"}]}
      ]}
    ]
  },
  {
    title:"Advance Integration",
    information:[
      {size:6, height:180, title:"Generator", rows:[
        { name:"Engine On", isRange:false,sensor:"GEN_Engine_On",symbole:"",fullValues:[{short:"1", full:"ON", colour:"danger"},{short:"0", full:"OFF",colour:"success"}]},
        { name:"Fuel Cap Off", isRange:false,sensor:"GEN_Fuel_Cap_Off",symbole:"",fullValues:[{short:"1", full:"OPENED", colour:"danger"},{short:"0", full:"CLOSED",colour:"success"}]},
       
        { name:"Fuel Level", isRange:true,sensor:"GEN_Fuel_Level",symbole:"%" , max:"Fuel Level High Limit", min:"Fuel Level Low Limit"},
        { name:"Battery Level", isRange:true,sensor:"GEN_Battery",symbole:"%"},
        { name:"Door", isRange:false,sensor:"GEN_Door_Open",symbole:"",fullValues:[{short:"1", full:"OPENDED", colour:"danger"},{short:"0", full:"CLOSED",colour:"success"}]},
      
      ]},
      {size:6, height:180, title:"Fire Panel", rows:[
        { name:"Zone 1", isRange:false,sensor:"Panel_1_Zone_1",symbole:"",fullValues:[{short:"2.0", full:"ON", colour:"danger"},{short:"0", full:"OFF",colour:"success"}]},
        { name:"Zone 2", isRange:false,sensor:"Panel_1_Zone_2",symbole:"",fullValues:[{short:"2.0", full:"ON", colour:"danger"},{short:"0", full:"OFF",colour:"success"}]},
        { name:"Zone 3", isRange:false,sensor:"Panel_1_Zone_3",symbole:"",fullValues:[{short:"1", full:"ON", colour:"danger"},{short:"0", full:"OFF",colour:"success"}]},
           ]}
    ]
  }
];


export default function SRU(props) {

  const dashboardDisplay = props.displayDashboard || defaultdashboardDisplay;
  const [sensors, setsensors] = useState({});

const [sensorInfo, setsensorInfo] = useState({
  name:""
});
const [showSensorTelemetries, setshowSensorTelemetries] = useState(false);

const [deviceSettings, setdeviceSettings] = useState({

  
});

const onSensorClicked =(sensorName,sensor )=>{
  setsensorInfo({...sensorInfo,name:sensorName,sensorName:sensor});
  setshowSensorTelemetries(true);
}




useEffect(() => {
  setsensors({...sensors,...props.sensors});

  NewGetSettings(props.deviceId)
  .then(response=> {
    const {data} = response;
    
    console.log('Device Settings',);
    
    setdeviceSettings({...data});     
  })
  .catch(error=> {})    

}, [props.sensors])

  return (
    <div>
   <SensorHistoryModal sensordetails={sensorInfo}  deviceid={props.deviceId} show={showSensorTelemetries} onHide={()=> setshowSensorTelemetries(false)}  /> 
   {
        dashboardDisplay.map(dashboard=>
          <Row>
          <InformationBox height={null} title={dashboard.title}>
          <Row>
          {dashboard.information.map(informationBox=>
             
          <InformationBox  size={informationBox.size}  height={informationBox.height} title={informationBox.title}>  
             
             {informationBox.rows.map(rows=>   <Displays max={_.get(deviceSettings,`[${rows.max}]`)}   min={_.get(deviceSettings,`[${rows.min}]`)} HandleSensorClick={(e)=> onSensorClicked(e,rows.sensor)} name={rows.name} isRange={rows.isRange} value={_.get(sensors,`[${rows.sensor}][0].value`)}   fullValues={rows.fullValues}  symbole={rows.symbole} />   )}  
                   
                 
          </InformationBox>
          )}
            </Row>
        </InformationBox>
          
        </Row>
          )
      }
    

      
</div>
  );
}
