import react,{useState,useEffect} from 'react';
import { Col, Row } from "react-bootstrap";
import { NewGetSettings } from '../../../services/DeviceService';
import SensorHistoryModal from '../SensorHistoryModal';
import { Displays, InformationBox } from "./Displays";
import _ from 'lodash';
const defaultDisplay =[
  {
    title:"",
    information:[
      {size:3, height:170, title:"Connectivity", rows:[
        { name:"Signal Strenght", isRange:true,sensor:"SignalStrength",symbole:"%"}, 
        { name:"Internal Battery", isRange:true,sensor:"InternalBattery",symbole:"V",max:"Internal Battery High Limit",min:"Internal Battery Low Limit"},       
      ]},
      {size:3, height:170, title:"Enviromental", rows:[
        { name:"Temperature", isRange:true,sensor:"Temperature",symbole:"°", max:"Temperature High Limit",min:"Temperature Low Limit"},  
        { name:"Humidity", isRange:true,sensor:"Humidity",symbole:"%",max:"Humidity High Limit",min:"Humidity Low Limit"},       
      ]},
      {size:3, height:170, title:"Contacts", rows:[
        { name:"Door", isRange:false,sensor:"Door",symbole:"",fullValues:[{short:"1", full:"OPENED", colour:"warning"},{short:"0", full:"CLOSED",colour:"success"}]},  
        { name:"Water", isRange:false,sensor:"Flooding",symbole:"",fullValues:[{short:"1", full:"YES", colour:"danger"},{short:"0", full:"NO",colour:"success"}]},       
      ]},
      {size:3, height:170, title:"Power", rows:[
        { name:"AC", isRange:false,sensor:"PowerStatus",symbole:"",fullValues:[{short:"1", full:"ON", colour:"success"},{short:"0", full:"OFF",colour:"danger"}]},  
       ]}
    ]
  }
]


export function PH1(props) {

  const Display  = props.dashboard || defaultDisplay;

    const [sensors, setsensors] = useState({
      SignalStrength:[{"value":"NA"}],
        Temperature:[{"value":"NA"}],
        InternalBattery:[{"value":"NA"}],
        Humidity:[{"value":"NA"}],
        Door:[{"value":"NA"}],
        Flooding:[{"value":"NA"}],
        PowerStatus:[{"value":"NA"}]
    });

    const [deviceSettings, setdeviceSettings] = useState({
  
    });

    useEffect(() => {
        setsensors({...sensors,...props.sensors})

        NewGetSettings(props.deviceId)
        .then(response=> {
          const {data} = response;
          
         // console.log('Device Settings',);
          
          setdeviceSettings({...data});     
        })
        .catch(error=> {})  

    }, [props.sensors])


    const [sensorInfo, setsensorInfo] = useState({
      name:""
    });
    const [showSensorTelemetries, setshowSensorTelemetries] = useState(false);

    const onSensorClicked =(sensorName,sensor )=>{
      setsensorInfo({...sensorInfo,name:sensorName,sensorName:sensor});
      setshowSensorTelemetries(true);
    }
    
  
    return (
      <div>
         <SensorHistoryModal sensordetails={sensorInfo}  deviceid={props.deviceId} show={showSensorTelemetries} onHide={()=> setshowSensorTelemetries(false)}  /> 
   {
        Display && Display.map(dashboard=>
          <Row>
          <InformationBox height={null} title={dashboard.title}>
          <Row>
          {dashboard.information.map(informationBox=>
             
          <InformationBox  size={informationBox.size}  height={informationBox.height} title={informationBox.title}>  
             
             {informationBox.rows.map(rows=>   <Displays max={_.get(deviceSettings,`[${rows.max}]`)}   min={_.get(deviceSettings,`[${rows.min}]`)} HandleSensorClick={(e)=> onSensorClicked(e,rows.sensor)} name={rows.name} isRange={rows.isRange} value={_.get(sensors,`[${rows.sensor}][0].value`)}   fullValues={rows.fullValues}  symbole={rows.symbole} />   )}  
                  
          </InformationBox>
          )}
            </Row>
        </InformationBox>
          
        </Row>
          )
      }
    
     </div>
    );
  }