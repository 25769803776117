import React,{useState} from 'react';


import GeneridModal from '../../Ulties/GeneridModal';
import { DisplayFill} from 'react-bootstrap-icons';
import { ExecuteDeviceCommand } from '../../../services/DeviceService';
import { FormControl, Button } from 'react-bootstrap';




export default function RemoteSSH({Id}) {


  const runCommand = (cmd)=>{
    var command = {};
    command.method = "remoteCommand";
    command.params = {};

    command.params.cmd = cmd;
    command.params.pin = "jadeMotema2021!TigerBrand!9";
  
    ExecuteDeviceCommand(2,Id,command)
    .then(response=>{

      const {data} = response;

      const {message} = data;

      if(!message)
      {
        alert(JSON.stringify(data));
        return;
      }
      
      newLineReponse(message);

    }).catch(eror=>{
      const {data} = eror;
      if(data)
      newLineReponse(`Error : (${JSON.stringify(data)})`);
      else
      newLineReponse(`Error : (${JSON.stringify(eror)})`);
    })
  }


  // const [terminalLineData, setTerminalLineData] = useState([
  //   {type: LineType.Output, value: 'Welcome to Netshield Smart Controller Session'}    
  // ]);

  const [resultCommand, setresultCommand] = useState("");
  const [inputCommand, setinputCommand] = useState("");

  const newLine = (data)=>{

    if(data.length < 2)
    {
     // setTerminalLineData([...terminalLineData,{type:LineType.Output, value:""}])
      return;

    }

    if(data ==="clear" )
    {
      //setTerminalLineData([])
      return;

    }

    //setTerminalLineData([...terminalLineData,{type:LineType.Input, value:data}]);
    runCommand(data);

  }

  const newLineReponse = (data)=>{

 
    //setTerminalLineData([...terminalLineData,{type:LineType.Output, value:data}])

    setresultCommand(data);
  }


  return (
    <>
      <GeneridModal modalClosed={()=> {}} title="Remote Session" size="xl" icon={<DisplayFill />} HandleModalAction={()=>{}}   buttonTitle={""}  modalOpened={()=> {}} >
      <div className="container">
      {/* <Terminal name='Remote Session' prompt="iPoP>" colorMode={ ColorMode.Dark }  lineData={ terminalLineData } onInput={ terminalInput => {newLine(terminalInput)} }/> */}

      <div style={{margin:20}}>

      <FormControl onChange={(e)=> setinputCommand(e.target.value)} placeholder="Commands to execute" />
      </div>
      <div style={{margin:20}}>
      <Button onClick={()=> runCommand(inputCommand)}>Execute</Button>
      </div>
      
      <FormControl  style={{ overflow: "auto", height: 400, color: "white", backgroundColor: "black" }} as="textarea" value={resultCommand}   aria-label="With textarea" />
    </div>
         
     </GeneridModal>

    
    </>
  );
}
