import { w3cwebsocket } from "websocket";

const envSettings = window ;

var webSocketClient ;


function sunscribeToTelemetries(deviceid)
{
    var object = {
        tsSubCmds: [
          {
            entityType: "DEVICE",
            entityId: deviceid,
            cmdId: "1",
           
          },
        ],
        historyCmds: [],
        attrSubCmds: [
          {
            entityType: "DEVICE",
            entityId: deviceid,
            cmdId: 2,
            keys: "active,commands,inactivityAlarmTime,lastConnectTime,lastDisconnectTime,latitude,longitude",
          },
        ],
      };
      var data = JSON.stringify(object);
      webSocketClient.send(data);
}

export function generateColorOnSeveriry (severity)
{

  var backgoundcolourStyle = "#14274e";
  var yellow = "#bdb704"
  //console.log(severity);

  if(severity?.toUpperCase()==="CRITICAL")
    return "#bd0404";
  else if(severity?.toUpperCase()==="MAJOR")
  return "orange"
  else if(severity?.toUpperCase()==="MINOR")
  return "#bdb704"
  else
  return yellow
}


export function connectToWS(deviceId,loadTelemetries)
{
    var token = localStorage.getItem("token");
    webSocketClient=  new w3cwebsocket(
        `wss://app-iot-dev.nsiotcloud.com:8088/api/ws/plugins/telemetry?token=${token}`
      );

    webSocketClient.onmessage = (e)=>{loadTelemetries(e.data)};
    webSocketClient.onopen =()=>{
        console.log('Connect to ws');
        sunscribeToTelemetries(deviceId);
    }
    webSocketClient.onclose =()=> console.log('Disconnect from WS');  
  
}

export function discConnectToWS()
{
    webSocketClient.close();
}

