import React from 'react';
import { Redirect } from 'react-router-dom';
import { Logout as LoginOut } from "../services/AuthService";
export default function Logout() {
    const LogOutUser = () =>{
      LoginOut();
      return <Redirect to="/app/Login" />
    }
  return (
    <div style={{flexDirection:"row", display:"flex", alignContent:"center", alignItems:"center"}}>
      <h1>You Just Logout!</h1>
      <LogOutUser />
    </div>
  );
}
