import React from "react";
import {GetUserDetails, GetUserDetailsMS} from '../../services/AuthService'

export default function DisplayOnUserType(props) {
  var user= GetUserDetails();
  var userDetails  = GetUserDetailsMS();

  var mutch = false;
  const typeallowed = props.typeAllowed;
  const typeToCheck = props.typeToCheck;

  const checkType = function () {
    if(userDetails && props.checkAlso)
    {
      {
        if (userDetails.type === props.checkAlso) {
          mutch = true;
        }
      }
      
     
    }

    if(user && user.scopes)
    {
      user.scopes.map((a) => {
        if (a === typeToCheck) {
          mutch = true;
        }
      });
     
    }

    return mutch;

    
  };

  return <>{checkType() && props.children}</>;
}

export  function DisplayOnUserTypeV2(props) {
  var user= GetUserDetailsMS();

  //alert(JSON.stringify(user));

 const checkType = function () {
    if (user.type ===props.typeToCheck)
    return true;
    else
    return false;
  };

  return <>{checkType() && props.children}</>;
}
