import logo from './logo.svg';

import 'bootstrap/dist/css/bootstrap.css'
import './App.css';
import 'leaflet/dist/leaflet.css';
import Header from './components/Header'
import { Container as div } from 'react-bootstrap';
import Login from './components/Login';
import './'
import DeviceInfo from './components/DeviceInfo';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import DeviceMap from './components/DeviceMap';
import Devices from './components/Devices';
import SimplePowerMonitor from './components/SimplePowerMonitor';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import MyDevices from './components/Devices/MyDevices';
import Default from './components/Devices/GenericDevice/Default';
import Logout from './components/Logout';
import CustomerSettingsCompoments from './components/CustomerSettingsCompoments';
import 'mapbox-gl/dist/mapbox-gl.css';

import DeviceTableList from './components/Devices/GenericDevice/DeviceTableList';
import CustomerList from './components/Customers/CustomerList';
import ListUsers from './components/Users/ListUsers';
import ActivateAccount from './components/Users/ActivateAccount';
import DevicesStatus from './components/Devices/Refactored/DevicesStatus';
import { PH1 } from './components/Devices/SimplifiedView/PH1';
import Cabinet from './components/Devices/SimplifiedView/Cabinet';
import UserDevices from './components/Devices/Refactored/UserDevices';
import DevicesStatusNoMap from './components/Devices/Refactored/DevicesStatus copy';
import { ProtectedRoute } from './components/CheckIfAuthencicated';
import Registration from './components/Devices/Refactored/Registrations';
import VisitorsList from './components/Visitors/VisitorsList';
import Dashboardinit from './components/Dashboards/Dashboardinit';
// import ReactNotification from 'react-notifications-component';



<style type="text/css">
{`
.btn-flat {
  background-color: purple;
  color: white;
}

.btn-xxl {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}
`}
</style>

function App() {
  return (
    <>
    {/* <ReactNotification /> */}
      <NotificationContainer/>
     
     <Router>
   
      <Switch>    
      
      
      <Route exact path="/app/customers" component={CustomerList} />    
      {/* <Route exact path="/users/:id" component={ListUsers} />    
          */}
     
          
          <ProtectedRoute exact path="/app/devices/:id/:customer" component={DeviceTableList} />
          <ProtectedRoute exact path="/app/registration" component={Registration} />
          <Route exact path="/app/ActivateAccount/:activateToken" component={ActivateAccount} />  
        
            
          <Route exact path="/app/login" component={Login} />  
          <Route exact path="/app/logout" component={Logout} />   
        
        
          <ProtectedRoute exact path="/app/GenericDevice/:id/:tab" component={Default} />         
          
          <ProtectedRoute exact path="/app/system/visitors" component={VisitorsList} />
          <ProtectedRoute exact path="/app/system/noMapstatus" component={DevicesStatusNoMap} />
          <ProtectedRoute exact path="/app/system/status"  component={DevicesStatus} />
          <ProtectedRoute exact path="/app/dashboard/device/:id" component={Dashboardinit} />
          
          
          <Redirect from="/" to="/app/system/status" />
         
          </Switch>
      
     </Router>
     
    </>
  );
}


export default App;
