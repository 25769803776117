import React, { useEffect, useState } from "react";
import { FormControl,Button } from "react-bootstrap";

import { CollectionPlay } from "react-bootstrap-icons";
import {MultiSelect} from "react-multi-select-component";
import { NotificationManager } from "react-notifications";
import { GetDeviceAttributesKey, UpdateSettingsKey, UpdateSettingsKeyShared } from "../../../services/DeviceService";
import HttpService from "../../../services/HttpService";
import GeneridModal from "../../Ulties/GeneridModal";

export default function GenericMultipleCommands({ deviceId, name, model }) {
  const [devicesList, setdevicesList] = useState([]);
  const [selectedDevices, setselectedDevices] = useState([]);

  const [deviceSettings, setdeviceSettings] = useState({});

 

  const cleatData  = ()=>{
    setdevicesList([])
    setselectedDevices([])
    setdeviceSettings([]);
  }

  const updateDevicesTresholds = ()=>{
    HttpService.post(`/devicemultipe/settingstresholds`,{
      
    })
  }

  const laodDevices = () => {

    GetDeviceAttributesKey(deviceId,"settings")
    .then(attributeGot=>{
      const {data} = attributeGot;     
      
      setdeviceSettings({...data[0].value});
     
    }).catch(attributeGotError=>{
      NotificationManager.error('Failed to get device settings')
    });
    
    HttpService.get("/repport/deviceStatus")
      .then((response) => {
        const { data } = response;

        var deviceListReady = data.devices.map((device) => {
          return { label: device.name, value: device.id };
        });

        setdevicesList([...deviceListReady]);
      })
      .catch((responseError) => {
        NotificationManager.error("Failed to laod devices", responseError);
      });
  };


  return (
    <>
      <GeneridModal
        modalClosed={() => {cleatData()}}
        title=""
        size="lg"
        icon={<CollectionPlay />}
        buttonTitle={"Update Settings"}
        HandleModalAction={() => {}}
        modalOpened={() => {
          laodDevices();
        }}
      >
        <div>
          
          <p>Devices to Copy Settings to ({selectedDevices.length})</p>
         
          <div style={{padding:10}}>
          <Button  >Copy Tresholds</Button>  
          </div>
          <MultiSelect
            options={devicesList}
            value={selectedDevices}
            onChange={setselectedDevices}
            labelledBy="Select"
          />
          <div style={{padding:10}}>
          <FormControl  style={{ overflow: "auto", height: 400, color: "white", backgroundColor: "black" }} as="textarea"   aria-label="With textarea" />
            </div>
         
          
        </div>
      </GeneridModal>
    </>
  );
}
