import React, { useState } from "react";
import { Badge, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { GetUserDetails, GetUserDetailsMS } from "../services/AuthService";
import CustomerProfile from "./Devices/CustomerProfile";
import DisplayOnUserType from "./Ulties/DisplayOnUserType";
import WifiPairingGenerator from "./Utilities/WifiPairingGenerator";

export default function Header({
  color,
  activeHeaderSatus,
  activeDevice,
  InactiveDevice,
  ActiveAlarms,
}) {
  const [userDetails, setuserDetails] = useState(GetUserDetails());
  const [userDetailsMS, setuserDetailsM] = useState(GetUserDetailsMS());

  return (
    <>
      <Navbar
        style={{ backgroundColor: color || "#14274e" }}
        variant="dark"
        expand="lg"
      >
        <Navbar.Brand href="#home">
          <image path="" />
        </Navbar.Brand>
        <div style={{ color: "white", fontWeight: "bold" }}>
          {" "}
          Netshield - Cloud based - Infrastructure Management System{" "}
          { userDetailsMS && userDetailsMS.customerName && `- ${userDetailsMS && userDetailsMS.customerName}`}
        </div>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav className="mr-auto">
            <NavLink className="nav-link" to="/app/system/status">
              i-PoP Map
            </NavLink>
            <NavLink className="nav-link" to="/app/system/noMapstatus">
              i-PoP Status
            </NavLink>

            <NavLink to="/app/devices/all/All" className="nav-link">
              i-PoP Gateways
            </NavLink>

           
            <DisplayOnUserType typeToCheck="TENANT_ADMIN">
              <NavLink to="/app/customers" className="nav-link">
                Customers
              </NavLink>
            </DisplayOnUserType>
            {/* <DisplayOnUserType typeToCheck="TENANT_ADMIN">
              <NavLink to="/app/registration" className="nav-link">
                Registration
              </NavLink>
            </DisplayOnUserType> */}


           
            <DisplayOnUserType typeToCheck="TENANT_ADMIN">

             

              <NavDropdown title=" Utilities" id="basic-nav-dropdown">
              <WifiPairingGenerator />
              </NavDropdown>

            </DisplayOnUserType>
            

            {/* <NavLink to="/settings" className="nav-link">            
            Settings
          </NavLink> */}
            {/* <Nav.Link href="#pricing">Settings</Nav.Link> */}
          </Nav>
          <Nav>
          {activeHeaderSatus && (
              <>
                <NavLink to="#" className="nav-link">
                   Available i-PoPs{" "}
                  <Badge variant="info">
                    {Number(activeDevice)}
                  </Badge>
                </NavLink>
                <NavLink to="#" className="nav-link">
                  Active i-PoPs{" "}
                  <Badge variant="success">
                    {Number(activeDevice - InactiveDevice)}
                  </Badge>
                </NavLink>
                <NavLink to="#" className="nav-link">
                  Inactive i-PoPs{" "}
                  <Badge variant="warning">{Number(InactiveDevice)}</Badge>
                </NavLink>
                <NavLink to="#" className="nav-link">
                  Active i-PoP Alarms{" "}
                  <Badge variant="danger">{Number(ActiveAlarms)}</Badge>
                </NavLink>
              </>
            )}
          </Nav>
          <Navbar.Text>
            <CustomerProfile />
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
