
import React,{useState,useEffect} from 'react';
import { Form, Tabs,Button, Tab  } from 'react-bootstrap';
import { Info } from 'react-bootstrap-icons';
import { NotificationManager } from 'react-notifications';
import { getCustomer,getGenericAttributes,updateCustomerInfo, updateGenericAttributes } from '../../services/CustomerService';
import GenericFormElement, { FromWithUpdateButton } from '../Ulties/GenericFormElement';
import GeneridModal from '../Ulties/GeneridModal';


export function EdicustomerOnly(props)
{
  const [settings, setsettings] = useState({})
  const [ErrorMessage, setErrorMessage] = useState({message:"", colour:"green"})
  const [customerDetails, setcustomerDetails] = useState({
      name: "",
      description: "",
      address: "",
      phone: "",
      email: "",
    })

    

    const setValue = (e)=> setcustomerDetails({...customerDetails,[e.target.name]:e.target.value});
    const setValueCommSystem = (e)=> setsettings({...settings,[e.target.name]:e.target.value});


    useEffect(() => {
      loadCustomer();
    }, [])

    const loadCustomer = async ()=>{
        const {data} = await getCustomer(props.customerId);
        setcustomerDetails({...data})
      
        var attribute = await getGenericAttributes(props.customerId);

        var settingsA = {
            "telegramid":"",
            "Notification Emails":""
        };

        attribute.data.map(a=> {
          settingsA[a.key] = a.value
        })

        setsettings({...settingsA});
        
    }
    const updateCommunicationSettings = (name)=>{
      updateGenericAttributes(props.customerId,{
          [name]:settings[name]
      }).then(resp=>{
          NotificationManager.success(`Setting ${name} updated successfully!`);
      }).catch(error=>{
          NotificationManager.warning(`Setting ${name} failed to updated!`);
      } )
    }
    const update = ()=>{
      updateCustomerInfo(props.customerId,customerDetails)
      .then(resp=>{
          console.log(resp);
          NotificationManager.success("Customer Updated");
          
      }).catch(error=>{
          NotificationManager.warning("Failed to update customer.");
      });
    }
    return (
      <>
               <h3 style={{color:ErrorMessage.colour}}>{ErrorMessage && ErrorMessage.message}</h3>
            <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-exampleEd">
              <Tab eventKey="home" title="Personal Info">
              <Form>
            <GenericFormElement name="name" value={customerDetails.name} type="string" title="Name" handleChange={setValue}  />
            <GenericFormElement name="address2" value={customerDetails.address2} type="string" title="Description" handleChange={setValue}  />
            <GenericFormElement name="address" value={customerDetails.address} type="string" title="Address" handleChange={setValue}  />
            <GenericFormElement name="phone" value={customerDetails.phone} type="string" title="Phone Number" handleChange={setValue}  />
            <GenericFormElement name="email" value={customerDetails.email} type="string" title="Email" handleChange={setValue}  />     
             <Button onClick={update}>Update Information</Button>
           </Form>
              </Tab>
              <Tab eventKey="profile" title="Communications">
              <Form style={{margin:10}}>
                  {Object.keys(settings).map(a=>(
                       <FromWithUpdateButton name={a} value={settings[a]}  type="string" title={a} handleChange={setValueCommSystem} handleButtonClick={()=> updateCommunicationSettings(a)}  />
                  ))}        
             
         
           </Form>
              </Tab>
              
              </Tabs>
         
       </>)

}

export default function EditCustomer(props) {  

    const [settings, setsettings] = useState({})
    const [ErrorMessage, setErrorMessage] = useState({message:"", colour:"green"})
    const [customerDetails, setcustomerDetails] = useState({
        name: "",
        description: "",
        address: "",
        phone: "",
        email: "",
      })

      

      const setValue = (e)=> setcustomerDetails({...customerDetails,[e.target.name]:e.target.value});
      const setValueCommSystem = (e)=> setsettings({...settings,[e.target.name]:e.target.value});

      const loadCustomer = async ()=>{
          const {data} = await getCustomer(props.customerId);
          setcustomerDetails({...data})
        
          var attribute = await getGenericAttributes(props.customerId);

          var settingsA = {
              "telegramid":"",
              "Notification Emails":""
          };

          attribute.data.map(a=> {
            settingsA[a.key] = a.value
          })

          setsettings({...settingsA});
          
      }
      const updateCommunicationSettings = (name)=>{
        updateGenericAttributes(props.customerId,{
            [name]:settings[name]
        }).then(resp=>{
            NotificationManager.success(`Setting ${name} updated successfully!`);
        }).catch(error=>{
            NotificationManager.warning(`Setting ${name} failed to updated!`);
        } )
      }
      const update = ()=>{
        updateCustomerInfo(props.customerId,customerDetails)
        .then(resp=>{
            console.log(resp);
            NotificationManager.success("Customer Updated");
            
        }).catch(error=>{
            NotificationManager.warning("Failed to update customer.");
        });
      }

  return (
    <>
      <GeneridModal modalOpened={loadCustomer} modalClosed={()=> setErrorMessage({message:"", colour:"green"})} icon={<Info />} title={"Edit Customer"} HandleModalAction={update}>
          <h3 style={{color:ErrorMessage.colour}}>{ErrorMessage && ErrorMessage.message}</h3>
          <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-exampleEd">
            <Tab eventKey="home" title="Personal Info">
            <Form>
          <GenericFormElement name="name" value={customerDetails.name} type="string" title="Name" handleChange={setValue}  />
          <GenericFormElement name="address2" value={customerDetails.address2} type="string" title="Description" handleChange={setValue}  />
          <GenericFormElement name="address" value={customerDetails.address} type="string" title="Address" handleChange={setValue}  />
          <GenericFormElement name="phone" value={customerDetails.phone} type="string" title="Phone Number" handleChange={setValue}  />
          <GenericFormElement name="email" value={customerDetails.email} type="string" title="Email" handleChange={setValue}  />     
       
         </Form>
            </Tab>
            <Tab eventKey="profile" title="Notifications">
            <Form style={{margin:10}}>
                {Object.keys(settings).map(a=>(
                     <FromWithUpdateButton name={a} value={settings[a]}  type="string" title={a} handleChange={setValueCommSystem} handleButtonClick={()=> updateCommunicationSettings(a)}  />
                ))}
        
           
       
         </Form>
            </Tab>
            
            </Tabs>
       
      </GeneridModal>
    </>
  );
}
