import React,{useState} from 'react';
import { useEffect } from 'react';
import { FormControl } from 'react-bootstrap';
import { ArrowUpRight, Dash, MenuApp } from 'react-bootstrap-icons';
import { NotificationManager } from 'react-notifications';
import { GetDeviceAttributesKey, UpdateSettingsKey } from '../../../services/DeviceService';

import GeneridModal from '../../Ulties/GeneridModal';


export default function DashboardEditor({deviceId,name}) {

  const [dashboard, setdashboard] = useState();
  const [dashboardText, setdashboardText] = useState("");

  const loadDash = ()=>{
    GetDeviceAttributesKey(deviceId,'deviceDashboard').then(res=>{
      const {data} =res;
     
      setdashboard([...data[0].value]);

      setdashboardText(JSON.stringify(data[0].value));
      
    }).catch(err=> console.log('failed loading ,,,'));
  }


  const updateDashboard = ()=>{

   
    try {
      var dashValues  = JSON.parse(dashboardText);
      UpdateSettingsKey(deviceId,'deviceDashboard',dashValues).then(response =>{
        NotificationManager.success('Dashboard updated , please refresh','Dashoard',1000)
      }).catch(err=>{
        NotificationManager.error('Failed Dashboard updated ','Dashoard',1000)
      })
    } catch (error) {
      NotificationManager.error(`Failed to parse settings please check JSON ${error} `,'Dashoard',3000)
    }

    
  }



  return (
    <>
      <GeneridModal modalClosed={()=> {}} title="Dashboard Settings" size="xl" icon={<MenuApp />} buttonTitle={"Update"} HandleModalAction= {()=> {updateDashboard()}} modalOpened={()=> {loadDash()}} >
          <div>         
          <FormControl style={{ overflow: "auto", height: 400, color: "white", backgroundColor: "black" }} as="textarea" value={dashboardText}  onChange={e=> {setdashboardText(e.target.value)}} aria-label="With textarea" />
         </div>
     
     </GeneridModal>

    
    </>
  );
}
