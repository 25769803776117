
import React,{useEffect, useState} from 'react';
import { ArrowUpRight, Gear, GearFill, List } from 'react-bootstrap-icons';
import { NotificationManager } from 'react-notifications';
import HttpService from '../../../services/HttpService';
import { FromWithUpdateButton } from '../../Ulties/GenericFormElement';

import GeneridModal from '../../Ulties/GeneridModal';


export default function DeviceTresholdsSettings({deviceId,name}) {


const [settingsValues, setsettingsValues] = useState(
  {  
  LimitTimeOut:{value:60, title:"Limit Timout (s)",isString:false , scope:"SERVER_SCOPE"},
  TemperatureHighLimit:{value:10, title:"Top Temperature High",isString:false , scope:"SERVER_SCOPE"},
  TemperatureLowLimit:{value:10, title:"Top Temperature Low",isString:false , scope:"SERVER_SCOPE"},
  TemperatureBottomHighLimit:{value:10, title:"Bottom Temperature High",isString:false , scope:"SERVER_SCOPE"},
  TemperatureBottomLowLimit:{value:10, title:"Bottom Temperature Low",isString:false , scope:"SERVER_SCOPE"},
  HumidityHighLimit:{value:10, title:"Humidity High",isString:false , scope:"SERVER_SCOPE"},
  HumidityLowLimit:{value:10, title:"Humidity Low",isString:false , scope:"SERVER_SCOPE"},
  Humidity1HighLimit:{value:10, title:"Humidity High",isString:false , scope:"SERVER_SCOPE"},
  Humidity1LowLimit:{value:10, title:"Humidity Low",isString:false , scope:"SERVER_SCOPE"},
  UpsEnabled:{value:"yes", title:"Activated (yes/no) ?",isString:true , scope:"SERVER_SCOPE"},
  UpsVoltgeLowLimit:{value:180, title:"UPS Low Voltge Limit",isString:false , scope:"SERVER_SCOPE"},
  UpsBatteryLowLevel:{value:180, title:"UPS Low Battery Limit",isString:false , scope:"SERVER_SCOPE"},
  UpsHighLoadLevelLimit:{value:180, title:"UPS High Load Level Limit",isString:false , scope:"SERVER_SCOPE"},
  MainsVoltageHighLimit:{value:255, title:"Mains Voltage High Limit",isString:false , scope:"SERVER_SCOPE"},
  MainsVoltageLowLimit:{value:180, title:"Mains Voltage Low Limit",isString:false , scope:"SERVER_SCOPE"},
  MainsCurrentHighLimit:{value:28, title:"Mains Current High Limit",isString:false , scope:"SERVER_SCOPE"},
  MainsCurrentLowLimit:{value:1.5, title:"Mains Current Low Limit",isString:false , scope:"SERVER_SCOPE"},
  MainsActivePowerHighLimit:{value:28, title:"Mains Current High Limit",isString:false , scope:"SERVER_SCOPE"},
  MainsActivePowerLowLimit:{value:1.5, title:"Mains Current Low Limit",isString:false , scope:"SERVER_SCOPE"},
  
});

const [settingsValuesDemo, setsettingsValuesDemo] = useState(
  [
    
 
  ]
  
);

const loadAttributes = (mapValue)=>{

  var keysvalues = '';
  mapValue.map(rows => {

    Object.keys(rows.rows).map(values=>  {
      keysvalues = keysvalues + ',' + values;
    })
    
  });
  

  

  HttpService.get(`/api/plugins/telemetry/DEVICE/${deviceId}/values/attributes?keys=devicePersonalName,${keysvalues.substr(1)}`)
  .then(res=>{
    const {data} = res;  

    var mySettings = {};
    data.map(a=> {

      var content = a.value;

      if(settingsValues[a.key]?.type==="json")
      {
        try {
          content = JSON.stringify(content);
        } catch (error) {          
          NotificationManager.error(`Failed to parse ${a.key}`);
        }
      }

      mySettings[a.key] = { ...settingsValues[a.key], value:content }
      setdevicePersonalName(mySettings["devicePersonalName"]?.value || "name")
    })
    
    setsettingsValues(set=>({
      ...set,
      ...mySettings
    }));

  }).catch(err=>{
    NotificationManager.error('Failed to load device advance settings');
  })
}
const updateSetting = (key, value,scope, type)=>{

  var valuetoSend = value;

  if(type)
  {
    if(type==="json")
    {
      try {
        valuetoSend = JSON.parse(value);
      } catch (error) {
        NotificationManager.success(`Failed to parse the JSON data`);
      }

    }
  }


    HttpService.post(`/api/plugins/telemetry/DEVICE/${deviceId}/${scope}`,{
      [key]:valuetoSend
    }).then(a=>{
      NotificationManager.success(`The value ${key} was successfully udpated`)
    }).catch(err=>{
      NotificationManager.error(`failed to update the value of ${key}`)
    })
}
const createValue = (value,isString) =>{

  if(isString)return value;
  else
  return Number(value)
}

const handleValueChange = (a,e)=>{
  setsettingsValues({...settingsValues,[e.target.name]:{...settingsValues[a],['value']:createValue(e.target.value,settingsValues[a].isString)}})
}

const loadSettingsDefinition = ()=>{
  HttpService.get(`/api/plugins/telemetry/DEVICE/${deviceId}/values/attributes?keys=settingsTresholdsDefinition`).then(response=>{

    const {data} = response;

    console.log('got settinfs',data[0].value);
    if(data[0])
    {
      setsettingsValuesDemo([...data[0].value]);

      loadAttributes([...data[0].value]); 
    }

  }).catch(err=>{

  })
}

const loadDeviceSettingsLocation = ()=>{
  HttpService.get(`/api/plugins/telemetry/DEVICE/${deviceId}/values/attributes/SERVER_SCOPE?keys=settings,Notification Emails`).then(re=>{
    const {data} =re;
    console.log(`Device location ${data[0]?.value?.latitude},${data[0]?.value?.longitude}`,)

    setlocationSettings({latitude:data[0]?.value?.latitude, longitude:data[0]?.value?.longitude});

    data?.map(attributes=>{
      if(attributes?.key==="Notification Emails")
      setemailsSettings(attributes?.value);
    return attributes?.value
    })

  }).catch(rew=>{
    
  })
}

const updateLocation =(label,paylaod,title)=>{
  HttpService.post(`/api/plugins/telemetry/DEVICE/${deviceId}/SERVER_SCOPE`,{
    [label]:paylaod
  }).then(a=>{
    NotificationManager.success(`The value ${title} was successfully udpated`)
  }).catch(err=>{
    NotificationManager.error(`failed to update the value of ${title}`)
  })
}

const [locationSettings, setlocationSettings] = useState({latitude:0, longitude:0});

const [devicePersonalName, setdevicePersonalName] = useState("");

const [emailsSettings, setemailsSettings] = useState();

useEffect(() => {
 // loadSettingsDefinition();
 
}, [])

  return (
    <>
      <GeneridModal modalClosed={()=> {}} title="Device Tresholds Settings" size="lg" icon={<GearFill />} buttonTitle={""} HandleModalAction= {()=> {}} modalOpened={()=> {loadSettingsDefinition();loadDeviceSettingsLocation();}} >
         <div style={{overflow:"scroll", height:500 , overflowX:"hidden", padding:10}}>
          
          <h5>Device Information</h5>

          <FromWithUpdateButton name={"label"} value={devicePersonalName}  type={"string"} title={"Label"} handleChange={(e)=>{setdevicePersonalName(e.target.value)}} handleButtonClick={()=> {
                    updateSetting("devicePersonalName",devicePersonalName,"SERVER_SCOPE","string")
                     }}  />

          <FromWithUpdateButton name={"latitude"} value={locationSettings.latitude}  type={"string"} title={"Latitude"} handleChange={(e)=>{setlocationSettings(old=>({...old,latitude:e.target.value}))}} handleButtonClick={()=> {
                    updateLocation('settings',locationSettings,'Device Location') 
                     }}  />

          <FromWithUpdateButton name={"longitude"} value={locationSettings.longitude}  type={"string"} title={"Longitude"} handleChange={(e)=>{setlocationSettings(old=>({...old,longitude:e.target.value}))}} handleButtonClick={()=> {
                     updateLocation('settings',locationSettings,'Device Location') 
                    }}  />


<FromWithUpdateButton name={"Emails"} value={emailsSettings}  type={"string"} title={"Emails (,)"} handleChange={(e)=>{setemailsSettings(e.target.value)}} handleButtonClick={()=> {
                     updateLocation('Notification Emails',emailsSettings,'Emails') 
                    }}  />

                    
           { settingsValuesDemo && settingsValuesDemo.map(main=> <>
           <h5>{main.title}</h5>
           {main.rows && Object.keys(main.rows).map(a=>(
                    
                    <>  <FromWithUpdateButton name={a} value={settingsValues[a].value} readonly={settingsValues[a].readonly}   type={settingsValues[a]?.type || "string"}title={main.rows[a].title} handleChange={(e)=>handleValueChange(a,e)} handleButtonClick={()=> {
                      updateSetting(a,settingsValues[a].value,settingsValues[a].scope,settingsValues[a].type)
                     }}  /></>
                  ))}   
           </>)}
         
                 
         </div>
     </GeneridModal>
    </>
  );
}
