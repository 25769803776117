import React, { useState,useEffect } from "react";
import { Button, Card, Container, Form, Row, Table } from "react-bootstrap";
import MqttSettingsGeneratorComponent from "./Devices/GenericDevice/MqttSettingsGeneratorComponent";
import GenericFormElement from "./Ulties/GenericFormElement";
import {NewCreateSettingsAndAttributtes,NewCreateSettingsAndAttributtesV2,NewGetSettings, NewGetISettings, ph1Settings} from '../services/DeviceService';
import { NotificationManager } from "react-notifications";
import moment from "moment";
const settingsAllowed = ["settings"];

export default function SettingsComponents({
  attributes,
  HandlerUpdateSettings,
}) {
  const [settings, setsettings] = useState({...attributes});
  const gettype = (value) => {
    if (isNaN(value)) 
    return "text";
    else return "number";
  };

 const handleSettingsUpdate = (e)=>{
    setsettings({...settings,["Mqtt Connection Setting"]:e});
  }
  return (
    <div style={{ padding: 10 }}>
      <Container>
        <div style={{ padding: 10 }}>
          <Card style={{ borderWidth: 2, borderColor: "#394867" , overflow:"scroll", height:800 }}>
            <Card.Body>
              <Card.Title className="mb-2 text-muted">
                Device Settings
              </Card.Title>

              {!attributes && <h4>NO SETTINGS FOUND</h4>}
              {attributes && (
                  <div>
                       <Table responsive="sm">
                  <thead>
                  
                  </thead>
                  <tbody>
                    {Object.keys(settings).map((a) => (
                      <tr key={a}>
                        <td>{a}</td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={gettype(settings[a])}
                              name={a}
                              onChange={(e) =>
                                setsettings({
                                  ...settings,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              value={settings[a]}
                              placeholder={a}
                            />
                          </Form.Group>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Row>
                <Button  style={{marginRight:10}} onClick={() => HandlerUpdateSettings(settings)}>
                Update
              </Button>
              {/* <MqttSettingsGeneratorComponent newValueGenerated={handleSettingsUpdate} /> */}
                </Row>
             
                  </div>
               
                
              )}
             
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
}


export function AdvanceSettingComponents ({deviceid,attributes}){

const [settingsAttributes, setsettingsAttributes] = useState({...attributes});
  const updateDeviceSetttings = ()=>{

    NewCreateSettingsAndAttributtesV2(deviceid,settingsAttributes)
    .then(response=>  { NotificationManager.success("Settings Successfully Updated! , page will be refresh in 5 seconds");

    // setTimeout(() => {
    //   window.location.reload();
    // }, 5000);
  })
    .catch(error=> NotificationManager.warning("Failed to Updated! settings"))
  }

  const handleValueChange = (e)=> setsettingsAttributes({...settingsAttributes,[e.target.name]:e.target.value});

  useEffect(() => {
   
    NewGetSettings(deviceid)
    .then(response=> {
      const {data} = response;
      setsettingsAttributes({...settingsAttributes,...data});     
    })
    .catch(error=> {})    

  }, [])

  return(
    <div style={{ padding: 10 }}>
      <Container>
        <div style={{ padding: 10 }}>
          <Card style={{ borderWidth: 2, borderColor: "#394867" , overflow:"scroll", height:800 }}>
            <Card.Body>
              <Card.Title className="mb-2 text-muted">
                Device Settings
              </Card.Title>

           {Object.keys(settingsAttributes).map(a=>      <GenericFormElement value={settingsAttributes[a]} type={"number"}  name={a} title={a} handleChange={handleValueChange} />)}
           <Button  style={{width:'20%'}} onClick={updateDeviceSetttings}>Update</Button>
                </Card.Body>
              
          </Card>
        
        </div>
       
      </Container>
    </div>
  )
}

export function PH1Configuration ({deviceid,attributes}){


  const [iSettings, setiSettings] = useState("");

  const [settingsAttributes, setsettingsAttributes] = useState({...attributes});
    const updateDeviceSetttings = ()=>{
  
      var csvSettings ="";

      Object.keys(settingsAttributes).map(a=>{
        csvSettings=`${csvSettings},${settingsAttributes[a].value}`;
      })
     
      csvSettings = csvSettings.substr(1);
      setiSettings(csvSettings);

      ph1Settings(deviceid,csvSettings)
      .then(response=> { NotificationManager.success("Settings Successfully Updated!"); window.location.reload()})
      .catch(error=> NotificationManager.warning("Failed to Updated! settings"))
    }
  
    const handleValueChange = (e)=> setsettingsAttributes({...settingsAttributes,[e.target.name]:{
      ... settingsAttributes[e.target.name],
      value:e.target.value
    }});
  
    useEffect(() => {
     
      NewGetISettings(deviceid)
      .then(response=> {
        const {data} = response;
       // setsettingsAttributes({...settingsAttributes,...data});  
       
       console.log('Logging isettings',{data});


       if(data[0])
       {

       
    

         var myvalues = data[0].value.split(',');

         var myValuesCount = myvalues.length;

         var myAttributes = attributes;

         var matchingCounter = 0;

         Object.keys(attributes).map(v=>{
          myAttributes[v].value =myvalues[matchingCounter];
          if(myValuesCount ===matchingCounter)          
            return;
          
          matchingCounter++;
         })

         setsettingsAttributes({...settingsAttributes,...myAttributes});
         
         //setiSettings({...myAttributes});
       }

     //  setiSettings(data[0].value);
      })
      .catch(error=> {})    
  
    }, [])
  
    return(
      <div style={{ padding: 10 }}>
       
      
        <Container>
          <div style={{ padding: 10 }}>
            <Card style={{ borderWidth: 2, borderColor: "#394867" , overflow:"scroll", height:800 }}>
              <Card.Body>
                <Card.Title className="mb-2 text-muted">
                  Device Settings
                </Card.Title>
  
             {Object.keys(settingsAttributes).map(a=>  settingsAttributes[a].label &&    <GenericFormElement value={settingsAttributes[a].value} type={settingsAttributes[a].type || "number"}  name={a} title={settingsAttributes[a].label} handleChange={handleValueChange} />)}
             <Button  style={{width:'20%'}} onClick={updateDeviceSetttings}>Update</Button>
                  </Card.Body>
                
            </Card>
          
          </div>
         
        </Container>
      </div>
    )
  }