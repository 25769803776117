import React, { useState,useEffect } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { NewGetSettings } from "../../../services/DeviceService";
import Header from "../../Header";
import SensorHistoryModal from "../SensorHistoryModal";
import { Displays, InformationBox } from "./Displays";
import _ from 'lodash';

const defaultdashboardDisplay =[
  {
    title:"Cabinet Environmental Status",
    information:[
      {size:3, height:160, title:"Temperatures", rows:[
        { name:"Top", isRange:true,sensor:"Temperature",symbole:"C",max:"Temperature 1 High Limit",min:"Temperature 1 Low Limit"},
        { name:"Bottom", isRange:true,sensor:"Temperature2",symbole:"C",max:"Temperature 2 High Limit",min:"Temperature 2 Low Limit"},
        { name:"Middle ", isRange:true,sensor:"Temperature3",symbole:"C",max:"Temperature 3 High Limit",min:"Temperature 3, Low Limit"}
       
      ]},
      {size:3, height:160, title:"Humidity", rows:[
        { name:"Relative", isRange:true,sensor:"Humidity",symbole:"%",max:"Humidity 1 High Limit",min:"Humidity 1 Low Limit"},     
      ]},
      {size:3, height:160, title:"Cabinet Doors", rows:[
        { name:"Front", isRange:false,sensor:"FrontDoor",symbole:"",fullValues:[{short:"0", full:"CLOSED", colour:"success"},{short:"1", full:"OPENED",colour:"danger"}]},
        { name:"Back", isRange:false,sensor:"BackDoor",symbole:"",fullValues:[{short:"0", full:"CLOSED", colour:"success"},{short:"1", full:"OPENED",colour:"danger"}]},   
        { name:"Left Opened", isRange:false,sensor:"Door_Left_Open",symbole:"",fullValues:[{short:"0", full:"NO", colour:"success"},{short:"1", full:"YES",colour:"danger"}]},     
        
      ]},
      {size:3, height:160, title:"Aircons", rows:[
        { name:"Internal", isRange:false,sensor:"aircon",symbole:"",fullValues:[{short:"0", full:"OFF", colour:"info"},{short:"1", full:"ON",colour:"warning"}]},
        { name:"NACU-1", isRange:false,sensor:"aircon1",symbole:"",fullValues:[{short:"0", full:"CLOSED", colour:"success"},{short:"1", full:"OPENED",colour:"warning"},{short:"2.0", full:"N/C",colour:"info"}]},   
        { name:"NACU-2", isRange:false,sensor:"aircon2",symbole:"",fullValues:[{short:"0", full:"CLOSED", colour:"success"},{short:"1", full:"OPENED",colour:"warning"},{short:"2.0", full:"N/C",colour:"info"}]},   
            
       ]},
       {size:3, height:140, title:"Smoke / Fire", rows:[
        { name:"Smoke 1", isRange:false,sensor:"smoke",symbole:"",fullValues:[{short:"0", full:"OFF", colour:"success"},{short:"1", full:"ON",colour:"danger"},{short:"2.0", full:"N/C",colour:"info"}]},
        { name:"Smoke 2", isRange:false,sensor:"smoke2",symbole:"",fullValues:[{short:"0", full:"OFF", colour:"success"},{short:"1", full:"ON",colour:"danger"},{short:"2.0", full:"N/C",colour:"info"}]},
       ]},
       {size:3, height:140, title:"Water", rows:[
        { name:"Aircon", isRange:false,sensor:"waterAircon",symbole:"",fullValues:[{short:"0", full:"NO", colour:"success"},{short:"1", full:"Yes",colour:"danger"}]},
        { name:"Cabinet", isRange:false,sensor:"waterCabinet",symbole:"",fullValues:[{short:"0", full:"NO", colour:"success"},{short:"1", full:"Yes",colour:"danger"}]},
     
      ]},
      {size:3, height:140, title:"PDU'S", rows:[
        { name:"LAN", isRange:false,sensor:"lanpdu",symbole:"",fullValues:[{short:"1", full:"ON", colour:"success"},{short:"0", full:"OFF",colour:"danger"}]},
        { name:"WAN", isRange:false,sensor:"wanpdu",symbole:"",fullValues:[{short:"1", full:"ON", colour:"success"},{short:"0", full:"OFF",colour:"danger"}]},
     
      ]},
      {size:3, height:140, title:"WAN Interfaces", rows:[
        { name:"X.21", isRange:false,sensor:"X21",symbole:"",fullValues:[{short:"1", full:"ON", colour:"success"},{short:"0", full:"OFF",colour:"danger"}]},
      
      ]},
    ]
  },
  {
    title:"Power",
    information:[
      {size:4, height:180, title:"Mains", rows:[
        { name:"Voltage", isRange:true,sensor:"MAINS_VOLTAGE",symbole:"V", max:"Mains Voltage High Limit",min:"Mains Voltage Low Limit"},
        { name:"Current", isRange:true,sensor:"MAINS_CURRENT",symbole:"A", max:"Mains Current High Limit",min:"Mains Current Low Limit"},
        { name:"Frequency", isRange:true,sensor:"mains_frequency",symbole:"Hz"},
       
        
      ]},
      {size:4, height:180, title:"Advance Mains", rows:[
        { name:"Total Active Energy", isRange:true,sensor:"Total_Active_Energy",symbole:"Kwh"},
        { name:"Power Factor", isRange:true,sensor:"mains_power_factor",symbole:""},
        { name:"Active Power", isRange:true,sensor:"mains_Actice_Power",symbole:"W"}      
        
      ]},
      {size:4, height:180, title:"UPS", rows:[
        { name:"Voltage", isRange:true,sensor:"UPS_VOLTAGE_LEVEL",symbole:"V", max:"UPS Voltage High Limit",min:"UPS Voltage Low Limit"},
        { name:"Load", isRange:true,sensor:"UPS_LAOD_LEVEL",symbole:"%",max:"UPS Load Level High Limit",min:"UPS Load Level Low Limit"},
        { name:"Battery", isRange:true,sensor:"UPS_BATTERY_LEVEL",symbole:"%", max:"UPS Battery High Limit",min:"UPS Battery Low Limit"},
        { name:"Bypass", isRange:false,sensor:"UPSBypassStatus",symbole:"",fullValues:[{short:"1", full:"ON", colour:"danger"},{short:"0", full:"OFF",colour:"success"}]}
      ]}
    ]
  }
];


export default function GenericTelemetries(props) {

const dashboardDisplay =  props.displayDashboard || defaultdashboardDisplay;
 
  const [sensors, setsensors] = useState({});

const [sensorInfo, setsensorInfo] = useState({
  name:""
});
const [showSensorTelemetries, setshowSensorTelemetries] = useState(false);

const [deviceSettings, setdeviceSettings] = useState({"Temperature 1 High Limit":"30","Temperature 1 Low Limit":"10","Temperature 2 High Limit":"30","Temperature 2 Low Limit":"-10","Temperature 3 High Limit":"30","Temperature 3 Low Limit":"-10","Temperature 4 High Limit":"40","Temperature 4 Low Limit":"-10","Humidity 1 High Limit":"60","Humidity 1 Low Limit":"10","UPS Voltage High Limit":"280","UPS Voltage Low Limit":"100","UPS Battery High Limit":"90","UPS Battery Low Limit":"20","UPS Load Level High Limit":"90","UPS Load Level Low Limit":"20","Mains Voltage High Limit":"260","Mains Voltage Low Limit":"200","Mains Current High Limit":"2","Mains Current Low Limit":"0","Temperature1High":"32","Temperature1Low":"10","Temperature2High":"31","Temperature2Low":"10","Temperature3High":200,"Temperature3Low":200,"Humidity1High":"60","Humidity1Low":"20","MainsHigh":"270","MainsLow":"201","MainsCurentHigh":"7","MainsCurrentLow":"-1"});

const [deviceLabelGenericSettings, setdeviceLabelGenericSettings] = useState({...props.deviceSettingsLables})

const onSensorClicked =(sensorName,sensor )=>{
  setsensorInfo({...sensorInfo,name:sensorName,sensorName:sensor});
  setshowSensorTelemetries(true);
}

useEffect(() => {
  setsensors({...sensors,...props.sensors})
  
  NewGetSettings(props.deviceId)
  .then(response=> {
    const {data} = response;   
    setdeviceSettings({...deviceSettings,...data});     
  })
  .catch(error=> {})  


}, [props.sensors])

  return (
    <div>
   <SensorHistoryModal sensordetails={sensorInfo}  deviceid={props.deviceId} show={showSensorTelemetries} onHide={()=> setshowSensorTelemetries(false)}  /> 
   {
        dashboardDisplay.map(dashboard=>
          <Row>
          <InformationBox height={null} title={dashboard.title}>
          <Row>
          {dashboard.information.map(informationBox=>
             
          <InformationBox  size={informationBox.size}  height={informationBox.height} title={informationBox.title}>  
             
          {informationBox.rows.map(rows=>   <Displays max={_.get(deviceLabelGenericSettings,`[${rows.max}]`)}   min={_.get(deviceLabelGenericSettings,`[${rows.min}]`)} HandleSensorClick={(e)=> onSensorClicked(e,rows.sensor)} name={rows.name} isRange={rows.isRange} value={_.get(sensors,`[${rows.sensor}][0].value`)}   fullValues={rows.fullValues}  symbole={rows.symbole} />   )}  
                 
          </InformationBox>
          )}
            </Row>
        </InformationBox>
          
        </Row>
          )
      }
    

      
</div>
  );
}
