import React from "react";
import { Alert, Button, Col, Form, Modal } from "react-bootstrap";
import {
  App,
  ArrowBarDown,
  ArrowRight,
  ArrowUp,
  CloudArrowDown,
  CloudArrowDownFill,
  Command,
} from "react-bootstrap-icons";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { ExecuteDeviceCommand } from "../../../services/DeviceService";
import 'antd/dist/reset.css';
import { Input } from "antd";
function CommandCompoments({ devicename,rcpTimeout, deviceid, commandsAvailable,model }) {
  console.log("COMMANDS ....", commandsAvailable);
  const ExecuteCommand = async () => {
    if (
      commandSelected === "Select a command" ||
      commandSelected === "0" ||
      commandValue === "Select Action" ||
      commandValue === "-1"
    ) {
      NotificationManager.warning("Please select the commands and it value");
      return;
    }

    setcommandExecuting(true);
    const commandTempalte = {
      id: "1",
      method: "SETOID",
      params: {
        CMD: commandSelected,
        value: Number(commandValue),
      },
    };
    try {
      var { data } = await ExecuteDeviceCommand(0, deviceid, commandTempalte);
      NotificationManager.success("Command Executed Successfully!");
      setcommandExecuting(false);
      console.log(data);
    } catch (error) {
      NotificationManager.error("Command Failed to execute!");
      setcommandExecuting(false);
      //alert(error)
    }
  };
  const ValidateAllCommandsParameter = () => {
    if (
      commandSelected === "Select a command" ||
      commandSelected === "0" ||
      commandValue === "Select Action" ||
      commandValue === "-1"
    ) {
      //  alert('Some input are wrong')
      setcommandExecuting(true);
      return true;
    } else {
      setcommandExecuting(false);
      return false;
    }
  };

  const selectComanndHandler = (e) => {
    {
      var findCommand = commands.find((a) => a.name === e.target.value);
      if (findCommand) {
        setcommandActionsValue(findCommand.commands.map(re=>({...re,payloadType:findCommand?.payloadType })));
      }

      setcommandExecuting(false);
      setcommandSelected(e.target.value);
    }
  };

  const SelectCommandOption = (e) => {
    {
      setcommandExecuting(false);
      setcommandValue(e.target.value);
    }
  };

  const executeSelectedCommand = async (cmd, parameter, type, payload) => {
    setcommandExecuting(true);

    console.log(`Execute cmd `, payload);

    const commandTempalte = {
      id: "1",
      method: "SETOID",
      params: {
        CMD: cmd,
        value: type != "string" ? Number(parameter):parameter,
      },     
      persistent: rcpTimeout !=0 ?  true:false,
      retries:1,
      timeout:rcpTimeout
    };
    try {
      if(payload)
      {
        var { data } = await ExecuteDeviceCommand(0, deviceid, payload);
      }
     else
     {
      var { data } = await ExecuteDeviceCommand(0, deviceid, commandTempalte);
     }
  
      NotificationManager.success("Command sent to device !");
      setcommandExecuting(false);
      
    } catch (error) {
      NotificationManager.error(`Failed to send commd to device (${error})!`);
      setcommandExecuting(false);
    }
  };

  const [commandExecuting, setcommandExecuting] = React.useState(true);
  const [commandResult, setcommandResult] = React.useState(null);
  const [commands, setcommands] = React.useState(commandsAvailable);
  const [commandSelected, setcommandSelected] = React.useState("0");
  const [modalShow, setModalShow] = React.useState(false);
  const [commandValue, setcommandValue] = React.useState("0");
  const [commandActionsValue, setcommandActionsValue] = React.useState([]);

  return (
    <div>
      <Button
        variant="btn btn-sm btn-primary"
        onClick={() => setModalShow(true)}
      >
        <Command />
      </Button>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setcommandActionsValue([]);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            
            Send commands to {devicename}.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Commands ({commands?.length}) </h4>

          <Form>
            <Form.Row>
              <Col md={12}>
                <Form.Group controlId="exampleForm.ControlSelect11">
                  <Form.Label>Available Commands</Form.Label>
                  <Form.Control as="select" onChange={selectComanndHandler}>
                    <option value={"-1"}>Select a command</option>
                    {commands.map((a) => (
                      <option>{a.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={12}>
                
                <div style={{ textAlign: "center" }}>
                  {commandActionsValue.map((a) => (
                    <div>
                      <Button
                      disabled={commandExecuting}
                        size="lg"
                        onClick={() =>
                          executeSelectedCommand(commandSelected, a.val,a?.payloadType, a?.payload)
                        }
                        style={{ margin: 5, width: 150 }}
                      >
                        {a.name}
                      </Button>{" "}
                    </div>
                  ))}
                </div>
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalShow(false);
              setcommandActionsValue([]);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export function SetDeviceConfigurationParameter(props)
{
  const [modalShow, setModalShow] = React.useState(false);
  return (<div>
    <Button
      variant="btn btn-sm btn-primary"
      onClick={() => setModalShow(true)}
    >
      <Command />
    </Button>
    <Modal
      show={modalShow}
      onHide={() => {
        setModalShow(false);
       
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          
       Set Device configuration parameter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       {
        props?.settings?.map(setting=><div>
           {setting?.title} <Input defaultValue={setting?.value} style={{width:"30%"}} placeholder="" /> <Button style={{width:"10%"}}>Save</Button>

        </div>)
       }
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setModalShow(false);
           
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>)
}

export default CommandCompoments;
