import React,{useState,useEffect} from 'react';
import { Badge, Button, Container, Nav } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { GetCustomerDevices, GetUserDevices } from '../../../services/DeviceService';
import { GetDeviceFullInformation,GetDeviceFullInformationWithObjext } from '../../../services/DeviceServicesHelper';
import Header from '../../Header';
import moment from 'moment';
import { Link, NavLink, useParams } from 'react-router-dom';
import { ArrowRight, Bell, ClockHistory, List, ListCheck, ViewList } from 'react-bootstrap-icons';
import 'react-data-table-component-extensions/dist/index.css';
import DataTableExtensions from 'react-data-table-component-extensions';
import { useHistory } from "react-router-dom";
import AssigneDevice from '../Refactored/AssigneDevice';
import DisplayOnUserType, { DisplayOnUserTypeV2 } from '../../Ulties/DisplayOnUserType';
import AddNewDevice from '../Refactored/AddNewDevice';
import CreateNewDevice from '../Refactored/CreateNewDevice';


export function SimpleDeviceList({customerId})
{
  
  const AlarmsColums = [
    {
      name: 'i-PoP Status',
      selector: 'attributes.active',
      sortable: true,
      cell: row=> <div> {row.attributes.active === true ? (
        <Badge variant="success">Online</Badge>
      ) : (
        <Badge variant="danger">Offline</Badge>
      )}</div>
    },
    {
      name: 'i-PoP Last Activity',
      selector: 'attributes.lastActivityTime',
      sortable: true,
      cell: row=> <div>{moment(row.attributes.lastActivityTime).format("DD/MM/yyyy HH:mm")} </div>
    },
    {
    name: 'i-PoP Name',
    selector: 'device.name',
    sortable: true
  }, 

  {
    name: 'i-PoP Model',
    selector: 'device.type',
    sortable: true
  },
  {
    name: 'i-PoP Alarms',
    selector: 'deviceAlarms',
    sortable: true,
    cell: row=> <div> {row.deviceAlarms.length >  0 ? (
      <Badge variant="danger">{row.deviceAlarms.length}</Badge>
    ) : (
      <Badge variant="success">0</Badge>
    )}</div>
  },
  {
    name: '',
    selector: 'attributes.lastActivityTime',
    sortable: true,
    cell: row=>
      <div >
          <NavLink title="View device info" className="btn btn-primary" style={{marginRight:10}}  to={`/GenericDevice/${row.device.id.id}/home`}> <List /></NavLink>      
       
        <NavLink title="View device info" className="btn btn-primary" style={{marginRight:10}}  to={`/GenericDevice/${row.device.id.id}/home`}> <List /></NavLink>      
        <NavLink title="View device history" className="btn btn-info" style={{marginRight:10}}  to={`/GenericDevice/${row.device.id.id}/profile`}> <ClockHistory /></NavLink>
        <NavLink title="View device alarms" className="btn btn-danger" style={{marginRight:10}}  to={`/GenericDevice/${row.device.id.id}/Alarms`}> <Bell /></NavLink>
        <AssigneDevice key={row.device.id.id} customer={row.device.customerTitle} deviceid={row.device.id.id} success={getMydevices} />
    </div>
  },
  ]
  


  var { id,customer } = useParams();

  const [loading, setloading] = useState();
  const [fulldevicesingo, setfulldevicesingo] = useState();
  const [devices, setdevices] = useState();

  const getMydevices = async () => {
      const { data } = await GetCustomerDevices(customerId);
      var mydevicesinfos = [];
      setdevices([...data.data]);
      setloading(false);
      console.log(data.data);
  
      [...data.data].map((a)=>(
        GetDeviceFullInformationWithObjext(a).then(resp=>{
          mydevicesinfos.push(resp);
          setfulldevicesingo([...mydevicesinfos]);
         // console.log("here is the fukk data",resp );
        }).catch((error)=>{
          console.log("There is an error");
        })
      ));
      
    return data.data;  
   
    };
  useEffect(() => {
   
    getMydevices();
    return () => {};
  }, [id]);

  const alarms = [{name:"DEVICE 1"}]
  return (
    <div >
     
<Container fluid>
 <div style={{width:'100%'}}>
   
    {/* {
      fulldevicesingo && <DataTable
      title="Devices"
      columns={AlarmsColums}
      data={fulldevicesingo}
      pagination
    />
    }   */}

    {
     fulldevicesingo && <DataTableExtensions
     columns={AlarmsColums}
     data={fulldevicesingo}
    >
      
      <DataTable
        noHeader
        defaultSortField="attributes.active"
        defaultSortAsc={false}
        pagination
        highlightOnHover
      />
    </DataTableExtensions>
    }
    </div>
    </Container>
    </div>
    
   
  );
}


export default function DeviceTableList() {
 
  const AlarmsColums = [
    {
      name: 'i-PoP Status',
      selector: 'attributes.active',
      sortable: true,
      cell: row=> <div> {row.attributes.active === true ? (
        <Badge variant="success">Online</Badge>
      ) : (
        <Badge variant="danger">Offline</Badge>
      )}</div>
    },
    {
      name: 'i-PoP Last Activity',
      selector: 'attributes.lastActivityTime',
      sortable: true,
      cell: row=> <div>{moment(row.attributes.lastActivityTime).format("DD/MM/yyyy HH:mm")} </div>
    },
    {
    name: 'i-PoP Name',
    selector: 'device.name',
    sortable: true
    
  },
  {
    name: 'i-PoP Customer',
    selector: 'device.customerTitle',
    sortable: true,
    cell: row => <div><Link to={`/devices/${row.device.customerId.id}/${row.device.customerTitle}`} >{row.device.customerTitle}</Link></div>
  },
  

  {
    name: 'i-PoP Model',
    selector: 'device.type',
    sortable: true
  },
  {
    name: 'i-PoP Alarms',
    selector: 'deviceAlarms',
    sortable: true,
    cell: row=> <div> {row.deviceAlarms.length >  0 ? (
      <Badge variant="danger">{row.deviceAlarms.length}</Badge>
    ) : (
      <Badge variant="success">0</Badge>
    )}</div>
  },
  {
    name: '',
    selector: 'attributes.lastActivityTime',
    sortable: true,
    cell: row=>
      <div >
         
        <NavLink title="View device info" className="btn btn-sm btn-primary" style={{marginRight:10}}  to={`/app/GenericDevice/${row.device.id.id}/home`}> <List /></NavLink>      
        <NavLink title="View device history" className="btn btn-sm  btn-info" style={{marginRight:10}}  to={`/app/GenericDevice/${row.device.id.id}/profile`}> <ClockHistory /></NavLink>
        <NavLink title="View device alarms" className="btn btn-sm  btn-danger" style={{marginRight:10}}  to={`/app/GenericDevice/${row.device.id.id}/Alarms`}> <Bell /></NavLink>
        <DisplayOnUserType typeToCheck="TENANT_ADMIN">
        <AssigneDevice key={row.device.id.id} customer={row.device.customerTitle} deviceid={row.device.id.id} success={getMydevices} />
      
           </DisplayOnUserType>
      
    </div>
  },
  ]
  


  var { id,customer } = useParams();

  const [loading, setloading] = useState();
  const [fulldevicesingo, setfulldevicesingo] = useState([]);
  const [devices, setdevices] = useState();
  const getMydevices = async () => {

    if(id==="all")
    {
      const { data } = await GetUserDevices();
      var mydevicesinfos = [];
      setdevices([...data.data]);
      setloading(false);

      console.log(data.data);     
  
      [...data.data].map((a)=>(
        GetDeviceFullInformationWithObjext(a).then(resp=>{
          mydevicesinfos.push(resp);
          setfulldevicesingo([...mydevicesinfos]);
         // console.log("here is the fukk data",resp );
        }).catch((error)=>{
          console.log("There is an error");
        })
      ));
      
    return data.data;
    }
    else{

      const { data } = await GetCustomerDevices(id);
      var mydevicesinfos = [];
      setdevices([...data.data]);
      setloading(false);
      console.log(data.data);
  
      [...data.data].map((a)=>(
        GetDeviceFullInformationWithObjext(a).then(resp=>{
          mydevicesinfos.push(resp);
          setfulldevicesingo([...mydevicesinfos]);
         // console.log("here is the fukk data",resp );
        }).catch((error)=>{
          console.log("There is an error");
        })
      ));
      
    return data.data;

    }
   
    

  };
  useEffect(() => {
   
    getMydevices();
    return () => {};
  }, [id]);

  const alarms = [{name:"DEVICE 1"}]
  return (
    <div >
      <Header  />
<Container fluid>
 <div style={{width:'100%'}}>
   <h3> {customer || "" } {" "}  i-PoP Gateways</h3>
   <DisplayOnUserTypeV2 typeToCheck={"Technician"}  >
   <AddNewDevice />
   </DisplayOnUserTypeV2>
  
   <DisplayOnUserTypeV2 typeToCheck={"Admin"}  >
   <CreateNewDevice />
   </DisplayOnUserTypeV2>
  
    {/* {
      fulldevicesingo && <DataTable
      title="Devices"
      columns={AlarmsColums}
      data={fulldevicesingo}
      pagination
    />
    }   */}

    {
     fulldevicesingo && <DataTableExtensions
     columns={AlarmsColums}
     data={fulldevicesingo}
    >
      <DataTable
        noHeader
        defaultSortField="attributes.active"
        defaultSortAsc={false}
        pagination
        highlightOnHover
      />
    </DataTableExtensions>
    }
    </div>
    </Container>
    </div>
    
   
  );
}
