import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { w3cwebsocket } from 'websocket';
import Header from '../Header';
import { Button,Row,Col, Tooltip } from "react-bootstrap";
import moment from 'moment';
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from 'recharts';
import { findAllByLabelText } from '@testing-library/react';
var client;
var forcedCloseConnection = false;


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && label) {
      return (
        
        <div style={{ background: "#9ba4b4" }}>
          <p >{`${moment(label).format("DD/MM/yyyy HH:mm")} : ${
             payload[0]?.value
          }`}</p>
        </div>
      );
    }
  
    return null;
  };


const Mychart= (props)=>{

    return (<>
    <LineChart
    
       syncId={props.id}
      width={1000}
      height={300}
      data={props.data}
      margin={{
        top: 5, right: 30, left: 20, bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="ts"   tickFormatter={(timeStr) =>
                moment(timeStr).format("DD/MM/yyyy HH:mm")
              } />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      <Legend />
      <Line type="monotone" dataKey="value" isAnimationActive={false} stroke="#14274e" activeDot={{ r: 8 }} />
     
    </LineChart>
    </>)
}

const Mychart2= (props)=>{

    return (<>
    <LineChart
    
       syncId={props.id}
      width={1000}
      height={300}
      data={props.data}
      margin={{
        top: 5, right: 30, left: 20, bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="ts"   tickFormatter={(timeStr) =>
                moment(timeStr).format("DD/MM/yyyy HH:mm")
              } />
      <YAxis type="category" dataKey={"name"} />
      <Tooltip content={<CustomTooltip />} />
      <Legend />
      <Line type="category" dataKey="name" isAnimationActive={false} stroke="#14274e" activeDot={{ r: 8 }} />
     
    </LineChart>
    </>)
}

export default function Dashboardinit(props) {
    let { id,tab } = useParams();
    const getToken = () => localStorage.getItem("token");

    const [wsconnected, setwsconnected] = useState(false);

    const [deviceInformation, setdeviceInformation] = useState();
    const [deviceAlarms, setdeviceAlarms] = useState();

    const [latestTelemetriesHistory, setlatestTelemetriesHistory] = useState();

    const [latestTelemetries, setlatestTelemetries] = useState();

    const [telemetriesOld, settelemetriesOld] = useState([]);

    const [allHistoricalTelemetries, setallHistoricalTelemetries] = useState({
        "HUM_1":[{ts:0,value:0},{ts:0,value:0}],
        "1W_TEMP_1":[{ts:0,value:0},{ts:0,value:0}],      
        "STAT_AC2":[{ts:0,name:"ON"},{ts:1,value:"OFF"}]
    })    
    const [deviceAtrributes, setdeviceAtrributes] = useState();

    const [deviceSetupInformation, setdeviceSetupInformation] = useState({
        id:id,
        telemetries:"",
        attributes:"",
        telemetriesKeys:["1W_TEMP_1","HUM_1","STAT_AC2"]
    })

    function webSocketConnect()
    {
        client = new w3cwebsocket(
            `${window.WS_URL}api/ws/plugins/telemetry?token=${getToken()}`
          );
        
          client.onmessage= function (event) 
          {
           
            var paylaod = JSON.parse(event.data);

            if(paylaod.cmdId===1)
            {
                setdeviceInformation({...paylaod.data})
            }

            if(paylaod.cmdId===2)
            {
                if(paylaod.data)
                setdeviceAlarms({...paylaod.data})
            }

            if(paylaod.cmdId===3)
            {
                if(paylaod.update)
              {
                setlatestTelemetriesHistory({...paylaod.update[0]});
                
                var valuetoUpload = paylaod.update[0].timeseries["1W_TEMP_1"].map(tele=>({ts:Number(tele.ts), value:Number(tele.value)}));
                
                var tepgenericValue = paylaod.update[0];

                var currentTelemetries= allHistoricalTelemetries;                

                var allhisotircData = Object.getOwnPropertyNames(paylaod.update[0].timeseries).map(alltel=>{               
                                 
                    if(alltel==="HUM_1")
                    {
                        var td =paylaod.update[0].timeseries[alltel] .map(tele=>({ts:Number(tele.ts), value:Number(tele.value)}));   
                        setallHistoricalTelemetries(myOldes=>({...myOldes,[alltel]:[...myOldes[alltel],...td]})) 
                    }
                   
                    if(alltel==="1W_TEMP_1")
                    {
                        var td =paylaod.update[0].timeseries[alltel] .map(tele=>({ts:Number(tele.ts), value:Number(tele.value)}));   
                        setallHistoricalTelemetries(myOldes=>({...myOldes,[alltel]:[...myOldes[alltel],...td]})) 
                    }                    

                    if(alltel==="STAT_AC2")
                    {
                        var td =paylaod.update[0].timeseries[alltel] .map(tele=>({ts:Number(tele.ts), name:(tele.value)}));   
                        setallHistoricalTelemetries(myOldes=>({...myOldes,[alltel]:[...myOldes[alltel],...td]})) 
                    }
                   

                    

                    return ({[alltel]:td})

                    
                })

              
                
                if(valuetoUpload.length===1)
                {
                    settelemetriesOld(oldthis=>[...oldthis,...valuetoUpload])
                }

                if(valuetoUpload.length>1)
                {
                    settelemetriesOld(oldthis=>[...oldthis,...valuetoUpload])
                }
              
              }
            }

            if(paylaod.subscriptionId===4)
            {
                if(paylaod)
              {
                setlatestTelemetries({...paylaod.data})
              }
            }
            if(paylaod.subscriptionId===5)
            {
              
            //    / setdeviceAtrributes({...paylaod.data});
                setdeviceAtrributes(old=>({...old,...paylaod.data}));
            }
           
          }

          client.onclose=function (event)
          {
              console.log("Connection closed, will reconnect in 10 seconds");
              forcedCloseConnection=false;

              if(forcedCloseConnection)
              {
                setTimeout(() => {
                 
                    webSocketConnect();
                  }, 10000);
              }
              
          }

          client.onopen =()=>{
              console.log(`Connection opened to websocket`);
              setwsconnected(true);
              forcedCloseConnection=false;
          }

         
    }

    function WebSocketClose(force)
    {
        
        forcedCloseConnection=force;
        client.close();
        setwsconnected(false);
    }

    function quertBasicValues(){
        var cmd ={
            "attrSubCmds": [],
            "tsSubCmds": [],
            "historyCmds": [],
            "entityDataCmds": [
                {
                    "query": {
                        "entityFilter": {
                            "type": "singleEntity",
                            "singleEntity": {
                                "entityType": "DEVICE",
                                "id": id
                            }
                        },
                        "pageLink": {
                            "pageSize": 1,
                            "page": 0,
                            "sortOrder": {
                                "key": {
                                    "type": "ENTITY_FIELD",
                                    "key": "createdTime"
                                },
                                "direction": "DESC"
                            }
                        },
                        "entityFields": [
                            {
                                "type": "ENTITY_FIELD",
                                "key": "name"
                            },
                            {
                                "type": "ENTITY_FIELD",
                                "key": "label"
                            },
                            {
                                "type": "ENTITY_FIELD",
                                "key": "additionalInfo"
                            }
                        ],
                        "latestValues": [
                            {
                                "type": "TIME_SERIES",
                                "key": "1W_TEMP_1"
                            },
                            {
                                "type": "TIME_SERIES",
                                "key": "HUM_1"
                            }
                        ]
                    },
                    "cmdId": 1
                }
            ],
            "entityDataUnsubscribeCmds": [],
            "alarmDataCmds": [],
            "alarmDataUnsubscribeCmds": [],
            "entityCountCmds": [],
            "entityCountUnsubscribeCmds": []
        }
        client.send(JSON.stringify(cmd));
    }
    function getbasicInformation(){

        var cmd ={
            "attrSubCmds": [],
            "tsSubCmds": [],
            "historyCmds": [],
            "entityDataCmds": [
                {
                    "query": {
                        "entityFilter": {
                            "type": "singleEntity",
                            "singleEntity": {
                                "entityType": "DEVICE",
                                "id": id
                            }
                        },
                        "pageLink": {
                            "pageSize": 1,
                            "page": 0,
                            "sortOrder": {
                                "key": {
                                    "type": "ENTITY_FIELD",
                                    "key": "createdTime"
                                },
                                "direction": "DESC"
                            }
                        },
                        "entityFields": [
                            {
                                "type": "ENTITY_FIELD",
                                "key": "name"
                            },
                            {
                                "type": "ENTITY_FIELD",
                                "key": "label"
                            },
                            {
                                "type": "ENTITY_FIELD",
                                "key": "additionalInfo"
                            }
                        ],
                        "latestValues": [
                            {
                                "type": "TIME_SERIES",
                                "key": "1W_TEMP_1"
                            },
                            {
                                "type": "TIME_SERIES",
                                "key": "HUM_1"
                            }
                        ]
                    },
                    "cmdId": 3
                }
            ],
            "entityDataUnsubscribeCmds": [],
            "alarmDataCmds": [],
            "alarmDataUnsubscribeCmds": [],
            "entityCountCmds": [],
            "entityCountUnsubscribeCmds": []
        }
        client.send(JSON.stringify(cmd));
    }

    function subScribeAlarms()
    {
        var cmd={
            "attrSubCmds": [],
            "tsSubCmds": [],
            "historyCmds": [],
            "entityDataCmds": [],
            "entityDataUnsubscribeCmds": [],
            "alarmDataCmds": [
                {
                    "query": {
                        "entityFilter": {
                            "type": "singleEntity",
                            "singleEntity": {
                                "id": id,
                                "entityType": "DEVICE"
                            }
                        },
                        "pageLink": {
                            "page": 0,
                            "pageSize": 10,
                            "textSearch": null,
                            "searchPropagatedAlarms": true,
                            "statusList": [
                                "ACTIVE"
                            ],
                            "severityList": [],
                            "typeList": [],
                            "sortOrder": {
                                "key": {
                                    "key": "createdTime",
                                    "type": "ALARM_FIELD"
                                },
                                "direction": "DESC"
                            },
                            "timeWindow": 2592000000
                        },
                        "alarmFields": [
                            {
                                "type": "ALARM_FIELD",
                                "key": "createdTime"
                            },
                            {
                                "type": "ALARM_FIELD",
                                "key": "type"
                            },
                            {
                                "type": "ALARM_FIELD",
                                "key": "severity"
                            },
                            {
                                "type": "ALARM_FIELD",
                                "key": "status"
                            }
                        ],
                        "entityFields": [],
                        "latestValues": []
                    },
                    "cmdId": 2
                }
            ],
            "alarmDataUnsubscribeCmds": [],
            "entityCountCmds": [],
            "entityCountUnsubscribeCmds": []
        }

        client.send(JSON.stringify(cmd));
    }

    function subscribeToLatestValuesHistory()
    {
        var cmd ={
            "attrSubCmds": [],
            "tsSubCmds": [],
            "historyCmds": [],
            "entityDataCmds": [
                {
                    "cmdId": 3,
                    "tsCmd": {
                        "keys": deviceSetupInformation.telemetriesKeys,
                        "startTs": 1653040320000,
                        "timeWindow": 1920000,
                        "interval": 120000,
                        "limit": 10,
                        "agg": "AVG"
                    }
                }
            ],
            "entityDataUnsubscribeCmds": [],
            "alarmDataCmds": [],
            "alarmDataUnsubscribeCmds": [],
            "entityCountCmds": [],
            "entityCountUnsubscribeCmds": []
        }
        
        client.send(JSON.stringify(cmd));
    }

    function subscribeTelemetriesUbdate()
    {

        var cmd ={
            "attrSubCmds": [
                {
                    entityType: "DEVICE",
                    entityId: id,
                    cmdId: 5,
                    keys:'insettings,lastActivityTime,lastConnectTime'
                  },
            ],
            "tsSubCmds": [
                {
                    entityType: "DEVICE",
                    entityId: id,
                    cmdId: 4,
                    keys:deviceSetupInformation.telemetries
                  },
            ],
            "historyCmds": [],
            "entityDataCmds": [ ],
            "entityDataUnsubscribeCmds": [],
            "alarmDataCmds": [],
            "alarmDataUnsubscribeCmds": [],
            "entityCountCmds": [],
            "entityCountUnsubscribeCmds": []
        }

        client.send(JSON.stringify(cmd));
       
    }


    function generateGraph()
    {


        return (<>
         {allHistoricalTelemetries && Object.getOwnPropertyNames(allHistoricalTelemetries).map(myLines=>(<>

         {deviceSetupInformation.telemetriesKeys.find(tk=> tk===myLines) && <>
         
            <h4>{myLines}</h4>
                <Mychart data={allHistoricalTelemetries[myLines]} id={1} />
         </>}
            
            </>))}</>
            
            )

       
    }

    useEffect(() => {  

        webSocketConnect();
    
      return () => {
        WebSocketClose(false);
      }
    }, [])
    

  return (
  <>
    <Header />
    <Row>
    <Col>
    <div>
        Connection status : {wsconnected ? "Connected":"Disonnected"}
       
        <div style={{margin:5}}> <Button onClick={()=> WebSocketClose(false)} variant='danger' type="submit">Disconnect</Button></div>
        <div style={{margin:5}}> <Button onClick={()=> webSocketConnect()} variant='success' type="submit">Connect</Button></div>
        <div style={{margin:5}}> <Button onClick={()=> quertBasicValues()} variant='success' type="submit">Query Basic Values</Button></div>
        <div style={{margin:5}}> <Button onClick={()=> subscribeTelemetriesUbdate()} variant='success' type="submit">Telemetries Changing</Button></div>
        <div style={{margin:5}}> <Button onClick={()=> subScribeAlarms()} variant='success' type="submit">Subscribe to allarms</Button></div>
        <div style={{margin:5}}> <Button onClick={()=> getbasicInformation()} variant='success' type="submit">Get Basic Information</Button></div>
        <div style={{margin:5}}> <Button onClick={()=> subscribeToLatestValuesHistory()} variant='success' type="submit">Last 30 Min</Button></div>
    </div>
    </Col>
    <Col>
    <h2>Device information</h2>
    {deviceInformation && JSON.stringify(deviceInformation)}
    </Col>
    <Col>
    <h2>Device Alarms</h2>
    
    {deviceAlarms?.data.map(alarm=>(<>
    <h5>{alarm.type}</h5>
    <h6>{moment(alarm.createdTime).format(`YYYY-MM-DD HH:mm:ss`)}</h6>
    </>))}
    </Col>

    <Col>
    <h2>Device Latest Time serries</h2>
    {/* {latestTelemetriesHistory && JSON.stringify(latestTelemetriesHistory)} */}
    {/* {telemetriesOld &&  JSON.stringify(telemetriesOld)} */}
    

    {generateGraph()}
   
   <h4>
       Power Status
   </h4>
  
   <Mychart2 data={allHistoricalTelemetries["STAT_AC2"]} id={1} />
    </Col>

    <Col>
    <h2>Device Latest Telemetries</h2>
    {latestTelemetries && JSON.stringify(latestTelemetries)}
    </Col>
    
    <Col>
    <h2>Device Attrobutes</h2>
    {deviceAtrributes && JSON.stringify(deviceAtrributes)}
    </Col>

    
    </Row>

  </>
  )
}
