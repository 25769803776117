import React, { useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { Login as login, Authenticate } from "../services/AuthService";

import { setAuthorizationHeader } from "../services/HttpService";
import { useHistory } from "react-router-dom";
import GenericMap from "./GenericMap";
import { NotificationManager } from "react-notifications";
// import ReactNotification from 'react-notifications-component';
// // import { store } from 'react-notifications-component';

export default function Login() {
  const history = useHistory();
  const [authenticating, setauthenticating] = useState(false)
  const [errors, seterrors] = useState(null);
  const [usercredentials, setusercredentials] = useState({
    username: "",
    password: "",
  });
  const handleInputChanger = (e) => {
    setusercredentials({ ...usercredentials, [e.target.name]: e.target.value });
    console.log(e.target.value);
  };
  const submitForm = async (e) => {

    setauthenticating(true);

    e.preventDefault();
    try {
      var result = await Authenticate(
        usercredentials.username,
        usercredentials.password
      );
      const data = result.data;
      setAuthorizationHeader(data.token, data.tokenmc);
      console.log("Login Result", data);
      setauthenticating(false);
      history.push("/");
     NotificationManager.success('Login Successfull!');
    } catch (error) {
     
     
      setauthenticating(false);

     if(error.data)
     {
      // store.addNotification({
      //   title: "Authentication Failed !",
      //   message: "Authentication Failed , please try again",
      //   type: "danger",
      //   insert: "center",
      //   container: "top-full",        
      //   dismiss: {
      //     duration: 5000,
      //     onScreen: true
      //   }
      // });
      NotificationManager.error(`Authentication Failed , please try again`);
     }
    //   NotificationManager.error(`Authentication Failed , please try again`);
    //  // seterrors(`'Error when requesting Login' , ${error}`);
    }
  };
  return (
    <div style={{paddingTop:100}}>
 <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: 50,
          borderWidth:2,
          borderColor:"#394867" 
        }}
      >
        <Card style={{ borderWidth:2,
          borderColor:"#394867" , padding:60 }}>
     <h4>Netshield - Cloud based - Infrastructure Management System</h4>
        <h1>Login</h1>
        <h4>{errors}</h4>
        <Form onSubmit={submitForm}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              onChange={handleInputChanger}
              value={usercredentials.username}
              name="username"
              placeholder="Enter email"
            />
           
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              onChange={handleInputChanger}
              type="password"
              value={usercredentials.password}
              name="password"
              placeholder="Password"
            />
          </Form.Group>

          <Button disabled={authenticating} className="New_primary" type="submit">Login</Button>
         
        </Form>
        <div style={{textAlign:"center"}}>
        <a href='https://play.google.com/store/apps/details?id=com.netshieldsa.mobile.devicemanager&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img style={{width:136, height:60 }} alt='Get it on Google Play' src='/images/android.png'/></a>
        <a href="https://apps.apple.com/za/app/my-ipop/id1563261197?itsct=apps_box_badge&amp;itscg=30200" ><img src="/images/ios.svg" alt="Download on the App Store" /></a>
        </div>
    

             </Card>
   
      </div>
    </Container>
    </div>
   
  );
}
